<template>
  <div>
 <div v-if="host.indexOf('mir') > -1|| host.indexOf('dev-mir') >-1 ">
   <MiracomHome></MiracomHome>
 </div>
 <div v-else>
   <MainHome></MainHome>
 </div>

  </div>
</template>

<script>
import MainHome from "./MainHome.vue";
import MiracomHome from "./MiracomHome.vue";
export default {
  components: {
    MainHome,
    MiracomHome,
  },
  data(){
    return{
      host: null
    }
  },
  created(){
    this.host = window.location.hostname
  }
};
</script>
<style lang="less" scoped>
</style>
