<template>
  <div class="view">
    <AdminPanel title="Step 목록">
      <div slot="header">
        <!-- <el-input
          v-model="keyword"
          prefix-icon="el-icon-search"
          placeholder="Keywords"
        >
        </el-input> -->
      </div>
      <el-table
        v-loading="loading"
        element-loading-text="loading"
        ref="table"
        row-key="id"
        :data="problemList"
        style="width: 100%"
      >
        <el-table-column
          v-if="!$store.state.isMobile"
          width="100"
          prop="id"
          label="ID"
        >
        </el-table-column>
        <el-table-column min-width="150" prop="title" label="제목">
          <template slot-scope="{ row }">
            <span v-show="!row.isEditing">{{ row.title }}</span>
            <el-input
              v-show="row.isEditing"
              v-model="row.title"
              @keyup.enter.native="handleInlineEdit(row)"
            >
            </el-input>
          </template>
        </el-table-column>

        <el-table-column min-width="150" prop="content" label="내용">
          <template slot-scope="{ row }">
            <span v-show="!row.isEditing">{{ row.content }}</span>
            <el-input v-show="row.isEditing" v-model="row.content"> </el-input>
          </template>
        </el-table-column>

        <el-table-column prop="order" label="순서">
          <template slot-scope="{ row }">
            <span v-show="!row.isEditing">{{ row.order }}</span>
            <el-input v-show="row.isEditing" v-model="row.order"> </el-input>
          </template>
        </el-table-column>

        <el-table-column width="100" prop="visible" label="보이기 설정">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.visible"
              active-text=""
              inactive-text=""
              @change="updateProblem(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>

        <el-table-column label="옵션" width="250">
          <div slot-scope="scope">
            <icon-btn
              name="수정"
              icon="edit"
              @click.native="goEdit(scope.row.id)"
            ></icon-btn>
            <icon-btn
              icon="s-order"
              name="step_level로 이동"
              @click.native="goLevel(scope.row)"
            ></icon-btn>
            <icon-btn
              icon="link"
              name="해당 페이지로 이동"
              @click.native="goHompage(scope.row)"
            ></icon-btn>
            <icon-btn
              icon="delete"
              name="step 삭제"
              @click.native="deleteProblemStep(scope.row.id)"
            ></icon-btn>
          </div>
        </el-table-column>
      </el-table>
      <div class="panel-options">
        <el-button
          type="primary"
          size="small"
          @click="goCreateProblem"
          icon="el-icon-plus"
          >생성
        </el-button>
        <el-pagination
          class="page"
          layout="prev, pager, next"
          @current-change="currentChange"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :total="total"
        >
        </el-pagination>
      </div>
    </AdminPanel>
    <el-dialog
      title="문제를 업데이트 하시겠습니까?"
      width="20%"
      :visible.sync="InlineEditDialogVisible"
      @close-on-click-modal="false"
    >
      <div>
        <p>DisplayID: {{ currentRow._id }}</p>
        <p>Title: {{ currentRow.title }}</p>
      </div>
      <span slot="footer">
        <cancel
          @click.native="
            InlineEditDialogVisible = false;
            getProblemStepList(currentPage);
          "
        ></cancel>
        <save @click.native="updateProblem(currentRow)"></save>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api.js";
import mixins from "./sortMixin";
import AdminPanel from "@admin/components/AdminPanel.vue";

export default {
  name: "StepProblemList",
  mixins: [mixins],
  components: {
    AdminPanel,
  },
  data() {
    return {
      pageSize: 10,
      total: 0,
      problemList: [],
      problemStep: "",
      keyword: "",
      loading: false,
      currentPage: this.$route.query.page ? +this.$route.query.page : 1,
      routeName: "",
      contestId: "",
      currentProblemID: "",
      currentRow: {},
      InlineEditDialogVisible: false,
      makePublicDialogVisible: false,
    };
  },
  mounted() {
    this.getProblemStepList(this.currentPage);
  },
  methods: {
    goEdit(problemStepId) {
      this.$router.push({ path: `/admin/problem-steps/${problemStepId}/edit` });
    },
    goCreateProblem() {
      this.$router.push({ path: "/admin/problem-steps/create" });
    },
    currentChange(page) {
      this.currentPage = page;
      this.getProblemStepList(page);
      this.$router.push({
        query: { page: this.currentPage },
      });
    },
    getProblemStepList(page = 1) {
      this.loading = true;
      let params = {
        limit: this.pageSize,
        offset: (page - 1) * this.pageSize,
        keyword: this.keyword,
      };
      api.getProblemStepList().then((res) => {
        this.loading = false;
        this.problemList = res.data.data.data;
      });
    },
    deleteProblemStep(id) {
      this.$confirm(
        "삭제 하시겠습니까? 관련 제출물도 삭제됩니다.",
        "문제 삭제",
        {
          type: "warning",
        }
      ).then(
        () => {
          api
            .deleteProblemStep(id)
            .then(() => [this.getProblemStepList(this.currentPage - 1)]);
        },
        () => {}
      );
    },
    updateProblem(row) {
      let data = Object.assign({}, row);
      data = Object.assign({ problem_step_id: row.id }, data);

      api.editProblemStep(data);
    },
    goLevel(row) {
      let id = row.id;
      this.$router.push({
        path: `/admin/problem-steps/${id}`,
        params: { id },
        query: { page: this.currentPage },
      });
    },
    goHompage(row) {
      window.open(`${window.location.origin}/problem-step/${row.id}/level`);
    },
  },
};
</script>

<style scoped lang="less"></style>
