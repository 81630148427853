import api from "@oj/api";

const mixins = {
    async mounted() {
    if (this.$route.params["board_id"]) {
      this.index = this.$route.params["board_id"];
      await this.getBoard(this.$route.params["board_id"]);

      this.title = this.board.board.title;
      this.content = this.board.board.content;
      // console.log(this.board.board.flag);
      if(this.board.board.flag ===3){
        this.checked = true;
      }

      if (
        !this.isAdminRole &&
        this.board.board.created_by.id !== this.user.profile.user.id
      ) {
        this.$router.push({
          path: `/${this.routeBoard}`,
          query: { page: this.$route.query.page }
        });
      }
    }
    // 수정글 작성시 해당 로그인 권한과 맞춰서 권한이 맞지 않을경우 수정이 불가능하게 한다
  },
  methods: {
    async write() {
      if(!this.isExistsTitle(this.title)) return;
      if(!this.isTitleTooLong(this.title)) return;
      if(!this.isExistsContent(this.content)) return;
      if(!this.isContentTooLong(this.content)) return;
      
      // if (this.isValidTitleAndContent(this.title, this.content) === false) return;

      await api.postBoard(
          this.title,
          this.content,
          this.user.profile.user.id,
          this.checked === true ? "secret" : "normal",
          this.company
        );
        this.$router.push({
          path: `/${this.routeBoard}`,
          query: { page: this.$route.query.page }
        });
    },
    update() {
      if(!this.isExistsTitle(this.title)) return;
      if(!this.isTitleTooLong(this.title)) return;
      if(!this.isExistsContent(this.content)) return;
      if(!this.isContentTooLong(this.content)) return;
      
      // if (this.isValidTitleAndContent(this.title, this.content) === false) return;
      
      this.$Modal.confirm({
        content: "게시글 수정을 하시겠습니까?",
        onOk: async () => {
          if (
            this.isAdminRole ||
            this.board.board.created_by.id === this.user.profile.user.id
          ) {
            const test = await api.putBoard(
              this.title,
              this.content,
              this.$route.params["board_id"],
              this.checked === true ? "secret" : "normal",
            );
            
            this.$router.push({
              path: `/${this.routeBoard}/${this.$route.params["board_id"]}`,
              query: { page: this.$route.query.page }
            });
          }
        },
        onCancel: () => {}
      });
    },
    // isValidTitleAndContent(title, content){
    //   return title.length > 0 && title.length < 100 && content.length > 0 && content.length < 1000000
    // },
    isExistsTitle(title){
      if (!title.length) {
        this.$error("제목을 입력해주세요");
        return;
      }
      return true;
    },
    isTitleTooLong(title){
      if (title.length >= 100) {
        this.$error("제목은 100자 이내로 입력해주세요");
        return;
      }
      return true;
    },
    isExistsContent(content){
      if (!content.length) {
        this.$error("내용을 입력해주세요");
        return;
      }
      return true;
    },
    isContentTooLong(content){
      if (content.length > 5000000) {
        this.$error("내용은 5M 이상 업로드 할 수 없습니다.");
        return;
      }
      return true;
    },
  },
};


export default mixins;