<template>
  <div class="view">
    <AdminPanel title="권한">
      <div slot="header"></div>
      <el-table v-if="problemList" :data="problemList" style="width: 100%">
        <el-table-column width="100" prop="logo_name" label="로고">
          <div slot-scope="scope">
            <img v-if="scope.row.logo_name" width="50" :src="`/public/upload/${scope.row.logo_name}`"/>
          </div>
        </el-table-column>
        <el-table-column v-if="!$store.state.isMobile" width="60" prop="id" label="ID"> </el-table-column>
        
        <el-table-column min-width="100" prop="name" label="비밀 코드 이름">
        </el-table-column>
        <el-table-column min-width="100" prop="group" label="그룹 이름">
          
        </el-table-column>
        <el-table-column min-width="100" prop="router_name" label="주소">
          
        </el-table-column>
        <!-- <el-table-column width="130" prop="verify_email" label="이메일 검증">
          
        </el-table-column> -->
        

        <el-table-column min-width="100" prop="is_contest_visible" label="집합 교육">
          <div slot-scope="scope">
            {{scope.row.is_contest_visible ? "활성화" : "비활성화"}}
          </div>
        </el-table-column>

        <el-table-column min-width="100" prop="is_qna_visible" label="질문 게시판">
          <div slot-scope="scope">
            {{scope.row.is_qna_visible ? "활성화" : "비활성화"}}
          </div>
        </el-table-column>

        <el-table-column min-width="100" prop="is_notice_visible" label="공지 게시판">
          <div slot-scope="scope">
            {{scope.row.is_notice_visible ? "활성화" : "비활성화"}}
          </div>
        </el-table-column>
        <el-table-column min-width="150"  prop="name" label="가지고 있는 권한 목록">
          <div slot-scope="scope">
            <!-- {{scope.row}} -->
            <!-- {{scope.row.secret_code}} -->
            {{ sortArray(scope.row.secret_code) }}
            <el-tag
              style="margin-left:5px; margin-top:5px;"
              v-for="code in scope.row.secret_code"
              :key="code.id"
            >
              {{ code.problem_step.title }}
              <!-- {{code.auth.problem_step.title}} -->
            </el-tag>
          </div>
        </el-table-column>
        <el-table-column label="옵션" min-width="150">
          <div slot-scope="scope">
            <icon-btn
              name="수정"
              icon="edit"
              @click.native="goEdit(scope.row.id)"
            ></icon-btn>
            <icon-btn
              icon="delete"
              name="그룹 삭제"
              @click.native="deleteProblem(scope.row.id)"
            ></icon-btn>
          </div>
        </el-table-column>
      </el-table>
    </AdminPanel>
  </div>
</template>

<script>
import api from "../../../api";
import ojAPI from "../../../../oj/api";
import AdminPanel from "@admin/components/AdminPanel.vue";
export default {
  name: "auth_list",
  components: {
    AdminPanel
  },
  data() {
    return {
      sortData: [],
      pageSize: 10,
      total: 0,
      problemList: [],
      keyword: "",
      loading: false
    };
  },
  async mounted() {
    const result = await this.getList();
    // console.log(result);
    this.problemList = result.data.data;
    this.problemList = this.problemList.map(li => {
      li.secret_code = li.secret_code.sort(function(a, b) {
        // console.log(a.problem_step.order);
        // console.log(b.problem_step.order);
        return a['problem_step'].order - b['problem_step'].order
      });
      // console.log(li.secret_code);

      return li;
    });
  },
  methods: {
    sortArray(secret_code) {
      // console.log(secret_code);
      // this.sortData = secret_code.sort(li => li.problem_step.order);
      // console.log(test.sort(li => li.problem_step.order))
      // return test
    },
    goCreate() {
      this.$router.push("/admin/secret_code_auth/create");
    },
    getList() {
      return api.getSecretCodeAuth();
    },
    goEdit(id) {
      this.$router.push(`/admin/secret_code_auth/edit/${id}`);
    },
    async deleteProblem(id) {
      this.$confirm("정말로 삭제하시겠습니까?", "경고", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        type: "warning",
      }).then(() => {
        api.deleteSecretCodeAuth(id).then(() => {
            this.getList().then((response) => {
                this.problemList = response.data.data;
            });
          })
      });
    }
  }
};
</script>

<style scoped lang="less"></style>
