<template>
  <el-container>
    <el-header>
      <div class="detail_title">공지사항 작성</div>
      <el-input v-model="title" placeholder="제목을 입력해주세요" />

      <div class="secret-checkbox-wrapper">
        <el-checkbox v-model="checked" class="secret-checkbox"
        >비밀글로 작성하기</el-checkbox
      >

      <el-checkbox v-if="isAdminRole" v-model="isNotice" class="secret-checkbox"
        >공지사항</el-checkbox
      >
      </div>
    </el-header>
    <el-main style="min-height: 60vh">
      <!-- <Simditor v-model="content" placeholder=""></Simditor> -->
      <!-- <ToastEditor :data="content" @setContent="setContent"  /> -->
      <VueCkEditor :data="content" @setContent="setContent"></VueCkEditor>
      <!-- <ckeditor :editor="editor" v-model="content" :config="editorConfig"></ckeditor> -->

      <div style="margin-top: 50px"></div>
      <el-button v-if="index" @click="update">수정</el-button>
      <el-button v-else @click="write">작성</el-button>
    </el-main>
  </el-container>
</template>

<script>
import Simditor from "@/pages/admin/components/Simditor";
import mixins from "./createMixins";
import { mapActions, mapGetters, mapState } from "vuex";
// import toastEditorMixins from "@/pages/admin/components/toastEditorMixins"
import VueCkEditor from "@/pages/admin/components/VueCkEditor.vue";
// import ckEditorMixin from "@/pages/admin/components/ckEditorMixin.js"
export default {
  mixins: [mixins],
  name: "create",
  components: {
    Simditor,
    VueCkEditor,
  },
  created() {

  },
  data() {
    const index = this.$route.params.contentId;
    return {
      data: "",
      index: "",
      title: "",
      content: "",
      route: "",
      checked: "",
      company: "",
      routeBoard: "",
      isNotice:false
    };
  },
  computed: {
    ...mapState(["board", "user", "notice"]),
    ...mapGetters(["isAdminRole"]),
  },
  methods: {
    ...mapActions(["getBoard", "getNotice"]),
    setContent(e) {
      this.content = e;
    },
  },
};
</script>

<style lang="less" scoped>
ol, ul{
  list-style-position: inside; 
}
.create-container {
  padding: 0 15% !important;
}

@media (max-width: 618px) {
  .create-container {
    padding: 0 !important;
  }
  .create-container .el-main{
    padding:10px;
  }
}

.el-container {
  background: white;
  margin: 0px auto;
  padding: 0px 0px;
  .el-header {
    background: white;
    padding: 20px 40px;
    height: 100% !important;
  }
  .el-main {
    width: 100%;
    padding:10px;
  }

  .detail_title {
    font-size: 21px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .secret-checkbox {
    display: block;
    text-align: right;
    margin-top: 10px;
  }
  .btns {
    margin-top: 20px;
    display: flex;
    .el-button {
      margin-left: auto;
    }
  }
  .secret-checkbox-wrapper{
    padding:5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
