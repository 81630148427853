<template>
  <div class="flex-container">
    <div id="main">
      <Panel shadow>
        <div slot="title">
          {{ title }}
          <div class="marginTop fontSize">
            오답일 때, <img src="../../../../assets/fail.png" /> 버튼을 누르면 잘못된 부분을 파악할 수 있습니다.
          </div>
          <div class="fontSize">
            정답일 때, <img src="../../../../assets/success.png" />  버튼을 누르면 상세내역을 확인할 수 있습니다.
          </div>
        </div>
        <div slot="extra">
          <ul class="filter">
            
              <li>
                <Dropdown @on-click="handleResultChange">
                  <span
                    >채점결과
                    <Icon type="arrow-down-b"></Icon>
                  </span>
                  <Dropdown-menu slot="list">
                    <Dropdown-item name="">{{ $t("m.All") }}</Dropdown-item>
                    <Dropdown-item
                      v-for="status in Object.keys(JUDGE_STATUS)"
                      :key="status"
                      :name="status"
                    >
                      {{
                        $t("m." + JUDGE_STATUS[status].name.replace(/ /g, "_"))
                      }}
                    </Dropdown-item>
                  </Dropdown-menu>
                </Dropdown>
              </li>
              <li
              v-if = "isAdminRole || isManager">
                <i-switch
                  size="large"
                  v-model="formFilter.myself"
                  @on-change="handleQueryChange"
                >
                  <span slot="close">{{ $t("m.Mine") }}</span>
                  <span slot="open">{{ $t("m.All") }}</span>
                </i-switch>
              </li>
            
            
              <li v-if = "isAdminRole || isManager">
                <Input
                  v-model="formFilter.username"
                  placeholder="제출자 / E-mail 검색"
                  @on-enter="handleQueryChange"
                />
              </li>
              <li v-if="$store.state.isMobile">

              </li>
              <li v-else>
                <!-- <el-date-picker
                  v-if="!$route.params.contestID"
                  v-model="betweenDate"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator=""
                  start-placeholder="시작일"
                  end-placeholder="종료일"
                  :picker-options="pickerOptions"
                >
                </el-date-picker> -->
              </li>

              <li v-if = "isAdminRole || isManager">
                <Button type="info" icon="refresh" @click="getSubmissions">
                  검색하기
                </Button>
              </li>
            
          </ul>
        </div>

        <Table
          stripe
          :disabled-hover="true"
          :columns="columns"
          :data="submissions"
          :loading="loadingTable"
        ></Table>

        
        <Pagination
        
          :total="total"
          :page-size="limit"
          @on-change="changeRoute"
          :current.sync="page"
        ></Pagination>

      </Panel>

      <Modal
        
        v-model="modal"
        width="80%"
        :title="isAnswer ? '강사님 코드' : '코드 정보'"
        footer-hide
      >
        <Button
          v-clipboard:copy="pureCode"
          v-clipboard:success="onCopy"
          v-clipboard:error="onCopyError"
          v-if="isAnswer"
        >
          <Icon type="clipboard"></Icon>
        </Button>
        <div
          v-if="flag"
          style="
            white-space: pre-wrap;
            font-family: 'Nanum Gothic Coding', monospace;
          "
          v-text="resultCode"
        >
          {{ resultCode }}
        </div>
        <BoardHighlight
          v-if="!flag"
          :code="resultCode"
          @callParentFunction="parentFunction"
        ></BoardHighlight>
      </Modal>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import api from "@oj/api";
import { JUDGE_STATUS, USER_TYPE } from "@/utils/constants";
import utils from "@/utils/utils";
import Pagination from "@/pages/oj/components/Pagination";
import BoardHighlight from "@/pages/oj/components/BoardHighlight";

export default {
  name: "submissionList",
  components: {
    Pagination,
    BoardHighlight,
  },
  props: ["code"],
  created() {
  
  },
  data() {
    return {
      modal: false,
      flag: true,
      isAnswer: "",
      resultCode: "",
      resultArr: [],
      pureCode: "",
      isbtn: 0,
      formFilter: {
        myself: false,
        result: "",
        username: "",
      },
      columns: [],
      loadingTable: false,
      submissions: [],
      total: 30,
      limit: 10,
      page: 1,
      contestID: "",
      problemID: "",
      routeName: "",
      JUDGE_STATUS: "",
      rejudge_column: false,
      pickerOptions: {
        shortcuts: [
          {
            text: "지난 1주",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "지난 1달",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "지난 3달",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      betweenDate: [],
    };
  },
  mounted() {
    
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    const end = new Date();
    this.betweenDate = [start, end];
    this.init();
    this.JUDGE_STATUS = Object.assign({}, JUDGE_STATUS);
    // 제출 상태 및 두 가지 제거
    delete this.JUDGE_STATUS["9"];
    delete this.JUDGE_STATUS["2"];
  },
  methods: {
  
    instance(type, content) {
      const title = "문제 정보";
      switch (type) {
        case "info":
          this.$Modal.info({
            title: title,
            content: content,
          });
          break;
        case "success":
          this.$Modal.success({
            title: title,
            content: content,
            width: "600px",
            render: (h, params) => {
              return h("div", {
                domProps: {
                  innerHTML: content,
                },
                style: {
                  "font-size": "15px",
                },
              });
            },
            okText: "확인",
            closable: true,
            scrollable: true,
          });
          break;
        case "warning":
          this.$Modal.warning({
            title: title,
            content: content,
          });
          break;
        case "error":
          this.$Modal.error({
            title: title,
            content: content,
            render: (h, params) => {
              return h("pre", {
                domProps: {
                  innerHTML: content,
                },
                style: {
                  "font-size": "15px",
                },
              });
            },
            okText: "확인",
          });
          break;
      }
    },
    init() {
      if (this.$store.state.isMobile) {
      this.columns = [
        {
          title: "채점일",
          align: "center",
          style: {
            "font-size": "11px",
          },

          minWidth: "100px",
          render: (h, params) => {
            return h(
              "div",
              {
                style: {
                  "font-size": "11px",
                },
              },
              [
                h("div", moment(params.row.create_time).format("YYYY/MM/DD")),
                h("div", moment(params.row.create_time).format("HH:mm:ss")),
              ]
            );
          },
        }, 
      {
          title: "문제이름",
          align: "center",
          style: {
            "font-size": "11px",
          },
          minWidth: 150,
          render: (h, params) => {
            return h(
              "span",
              {
                style: {
                  color: "#57a3f3",
                  cursor: "pointer",
                },
                on: {
                  click: () => {
                    if (this.contestID) {
                      this.$router.push({
                        name: "enterprise-contest-problem-details",
                        params: {
                          problemID: params.row.problem,
                          contestID: this.contestID,
                        },
                      });
                    } else {
                      this.$router.push({
                        name: "problem-details",
                        params: { problemID: params.row.problem },
                      });
                    }
                  },
                },
              },
              params.row.problem
            );
          },
        },
        {
          title: this.$i18n.t("m.NavStatus"),
          align: "center",
          minWidth: 130,
          className: "iview-table-judge-status",
          render: (h, params) => {
            return h(
              "Button",
              {
                props: {
                  type: JUDGE_STATUS[params.row.result].type,
                  size: "mini",
                },
                style: {
                  padding: "0 4px",
                  "font-size": "11px",
                },
                on: {
                  click: () => {
                    if (this.$route.params.contestID) {
                        this.$router.push({
                          path: `/enterprise/contest/${this.$route.params.board_name}/${this.$route.params.contestID}/status/${params.row.id}`,
                          query: {
                            problem: params.row.problem,
                            contest: this.$route.params.contestID,
                          },
                        });
                      } else {
                        this.$router.push({
                          path: `/status/${params.row.id}`,
                          query: {
                            problem: params.row.problem,
                            contest: this.$route.params.contestID,
                          },
                        });
                      }
                  },
                },
              },
              this.$i18n.t(
                "m." + JUDGE_STATUS[params.row.result].name.replace(/ /g, "_")
              )
            );
          },
        },
        // {
        //   title: this.$i18n.t("m.Language"),
        //   align: "center",
        //   key: "language",
        //   style: {
        //     'font-size':'11px'
        //   },
        //   minWidth: 50,
        // },
        {
          title: "제출자 ID",
          align: "center",
          minWidth: "100px",
          style: {
            "font-size": "11px",
          },
          render: (h, params) => {
            return h(
              "a",
              {
                style: {
                  display: "inline-block",
                  "max-width": "150px",
                },
                on: {
                  click: () => {
                    this.$router.push({
                      name: "user-home",
                      query: { username: params.row.username },
                    });
                  },
                },
              },
              params.row.username
            );
          },
        },
      ];
    } else {
      this.columns = [
        {
          title: "채점일",
          align: "center",

          minWidth: "100px",
          render: (h, params) => {
            return h("div", [
              h("div", moment(params.row.create_time).format("YYYY/MM/DD")),
              h("div", moment(params.row.create_time).format("HH:mm:ss")),
            ]);
          },
        },
        {
          title: "문제이름",
          align: "center",
          minWidth: 150,
          render: (h, params) => {
            return h(
              "span",
              {
                style: {
                  color: "#57a3f3",
                  cursor: "pointer",
                },
                on: {
                  click: () => {
                    if (this.contestID) {
                      this.$router.push({
                        name: "enterprise-contest-problem-details",
                        params: {
                          problemID: params.row.problem,
                          contestID: this.contestID,
                        },
                      });
                    } else {
                      this.$router.push({
                        name: "problem-details",
                        params: { problemID: params.row.problem },
                      });
                    }
                  },
                },
              },
              params.row.problem
            );
          },
        },
        {
          title: this.$i18n.t("m.NavStatus"),
          align: "center",
          minWidth: 130,
          className: "iview-table-judge-status",
          render: (h, params) => {
            return h(
              "Button",
              {
                props: {
                  type: JUDGE_STATUS[params.row.result].type,
                },
                style: {
                  "font-size": "12px",
                  padding: "0 8px",
                },
                on: {
                  click:  () => {
                    if (this.$route.params.contestID) {
                        this.$router.push({
                          path: `/enterprise/contest/${this.$route.params.board_name}/${this.$route.params.contestID}/status/${params.row.id}`,
                          query: {
                            problem: params.row.problem,
                            contest: this.$route.params.contestID,
                          },
                        });
                      } else {
                        this.$router.push({
                          path: `/status/${params.row.id}`,
                          query: {
                            problem: params.row.problem,
                            contest: this.$route.params.contestID,
                          },
                        });
                      }
                  },
                },
              },
              this.$i18n.t(
                "m." + JUDGE_STATUS[params.row.result].name.replace(/ /g, "_")
              )
            );
          },
        },
        
        {
          title: this.$i18n.t("m.Time"),
          align: "center",
          minWidth: 50,
          render: (h, params) => {
            return h(
              "span",
              utils.submissionTimeFormat(params.row.statistic_info.time_cost)
            );
          },
        },
        {
          title: this.$i18n.t("m.Memory"),
          align: "center",
          minWidth: 50,
          render: (h, params) => {
            return h(
              "span",
              utils.submissionMemoryFormat(
                params.row.statistic_info.memory_cost
              )
            );
          },
        },
        {
          title: this.$i18n.t("m.Language"),
          align: "center",
          key: "language",
          minWidth: 50,
        },
        {
          title: "제출자 ID",
          align: "center",
          minWidth: "100px",
          render: (h, params) => {
            return h(
              "a",
              {
                style: {
                  display: "inline-block",
                  "max-width": "150px",
                },
                on: {
                  click: () => {
                    this.$router.push({
                      name: "user-home",
                      query: { username: params.row.username },
                    });
                  },
                },
              },
              params.row.username
            );
          },
        },
      ];
    }


      this.contestID = this.$route.params.contestID;
      let query = this.$route.query;
      this.problemID = query.problemID;
      if (this.isAdminRole || this.isManager) {
        this.formFilter.myself = true;
      }
      // } else {
      //   this.formFilter.myself = query.myself === "0";
      // }

      this.formFilter.result = query.result || "";
      this.formFilter.username = query.username || "";
      this.page = parseInt(query.page) || 1;
      if (this.page < 1) {
        this.page = 1;
      }
      this.routeName = this.$route.name;
      this.getSubmissions();
    },
    buildQuery() {
      return {
        myself: this.formFilter.myself === true ? "0" : "1",
        result: this.formFilter.result,
        username: this.formFilter.username,
        page: this.page,
      };
    },
    getSubmissions() {
      let params = this.buildQuery();
      params.contest_id = this.contestID;
      params.problem_id = this.problemID;
      // params.start_date = this.betweenDate[0];
      // params.end_date = this.betweenDate[1];
      //인자가 없으면 첫페이지 다음페이지
      //인자가 있으면 첫페이지 이전페이지 다음페이지
      

      if(this.$route.query?.lastTime){
        params.lastTime = this.$route.query?.lastTime
        if(this.$route.query?.type){
          params.type = this.$route.query?.type
        }
      }

      let offset = (this.page - 1) * this.limit;

      let func = this.contestID
        ? "getContestSubmissionList"
        : "getSubmissionList";
      this.loadingTable = true;
      api[func](offset, this.limit, params)
        .then((res) => {
          let data = res.data.data;
          for (let v of data.results) {
            v.loading = false;
          }
          if (!this.$store.state.isMobile) {
            this.adjustRejudgeColumn();
          }
          this.loadingTable = false;
          this.submissions = data.results;
          this.total = data.total;
        })
        .catch(() => {
          this.loadingTable = false;
        });
    },
    // 라우트 변경, 라우트 변경 모니터링을 통한 데이터 요청, 라우트 이력 생성 가능, 사용자 복귀시 이전 상태 저장
    changeRoute(type) {
      let query = utils.filterEmptyValue(this.buildQuery());
      query.contestID = this.contestID;
      query.problemID = this.problemID;

    
      //마지막페이지의 시간을 가져와서 인자로 넣는다.
      
      if(type === "prev"){
          //이전 페이지는 맨위 보다 전
          
          const time = this.submissions?.[0]?.create_time;
          const dateObj = new Date(time);
          const timestamp = dateObj.getTime() / 1000;
          query.lastTime = timestamp;
          query.type ="prev";
      }
      if (type === "next"){
        
        const time = this.submissions?.[this.submissions.length -1]?.create_time
        const dateObj = new Date(time);
        const timestamp = dateObj.getTime() / 1000;
        query.lastTime = timestamp;
        query.type ="next";
      }

        
      let routeName = query.contestID
        ? "enterprise-contest-submission-list"
        : "submission-list";
      this.$router.push({
        name: routeName,
        query: utils.filterEmptyValue(query),
      });
    },
    goRoute(route) {
      this.$router.push(route);
    },
    adjustRejudgeColumn() {
      if (!this.rejudgeColumnVisible || this.rejudge_column) {
        return;
      }
      const judgeColumn = {
        title: this.$i18n.t("m.Option"),
        // fixed: "right",
        align: "center",
        width: 90,
        render: (h, params) => {
          return h(
            "Button",
            {
              props: {
                type: "primary",
                size: "small",
                loading: params.row.loading,
              },
              on: {
                click: () => {
                  this.handleRejudge(params.row.id, params.index);
                },
              },
            },
            "재채점"
          );
        },
      };

      const serverColumn = {
        title: "채점서버",
        align: "center",
        minWidth: "100px",
        render: (h, params) => {
          return h(
            "span",
            {
              style: {
                // 'padding-left': "5px",
                // "max-width": "150px",
              },
            },
            params.row.submission_ip ? params.row.submission_ip : ""
          );
        },
      };

      if (this.isAdminRole) {
        this.columns.push(serverColumn);
      }
      this.columns.push(judgeColumn);
      // 관리자 권한이면 채점 IP 추가하기

      this.rejudge_column = true;
    },
    handleResultChange(status) {
      this.page = 1;
      this.formFilter.result = status;
      this.changeRoute();
    },
    handleQueryChange() {
      this.page = 1;
      this.changeRoute();
    },
    handleRejudge(id, index) {
      this.submissions[index].loading = true;
      api.submissionRejudge(id).then(
        (res) => {
          this.submissions[index].loading = false;
          this.$success("Succeeded");
          this.getSubmissions();
        },
        () => {
          this.submissions[index].loading = false;
        }
      );
    },

    onCopy(event) {
      this.$success("코드가 복사되었습니다.");
    },
    onCopyError(e) {
      this.$error("코드를 복사하지 못했습니다.");
    },
    parentFunction(params) {
      this.pureCode = params;
    },
  },
  computed: {
    ...mapGetters(["isAuthenticated", "user", "isAdminRole", "isAdminRole", "isManager"]),
    title() {
      // 전부다 필요 없는 것 같음
      if (!this.contestID) {
        return this.$i18n.t("m.Submissions");
      } else if (this.problemID) {
        return this.$i18n.t("m.Submissions");
      } else {
        return this.$i18n.t("m.Submissions");
      }
    },
    status() {
      return this.formFilter.result === ""
        ? this.$i18n.t("m.Status")
        : this.$i18n.t(
            "m." + JUDGE_STATUS[this.formFilter.result].name.replace(/ /g, "_")
          );
    },
    rejudgeColumnVisible() {
      return this.user.admin_type === USER_TYPE.SUPER_ADMIN;
    },
  },
  watch: {
    $route(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.init();
      }
    },
    rejudgeColumnVisible() {
      this.adjustRejudgeColumn();
    },
    isAuthenticated() {
      this.init();
    },
  },
};
</script>

<style lang="less">
.ivu-table table {
  table-layout: auto !important;
}
.iview-table-judge-status > .ivu-table-cell {
  overflow: visible;
}
.copy-button {
  position: absolute;
  height: 35.8px;
}
.copy-button.up {
  top: 60px;
  right: 15px;
}
.copy-button.down {
  bottom: 21px;
  left: 15px;
}
pre {
  overflow: auto;
  white-space: -moz-pre-wrap; /* Mozilla, supported since 1999 */
  white-space: -pre-wrap; /* Opera */
  white-space: -o-pre-wrap; /* Opera */
  white-space: pre-wrap; /* CSS3 - Text module (Candidate Recommendation) http://www.w3.org/TR/css3-text/#white-space */
  word-wrap: break-word; /* IE 5.5+ */
}
// pre {
//     white-space: pre-wrap;       /* Since CSS 2.1 */
//     white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
//     white-space: -pre-wrap;      /* Opera 4-6 */
//     white-space: -o-pre-wrap;    /* Opera 7 */
//     word-wrap: break-word;       /* Internet Explorer 5.5+ */
// }
.ivu-btn-text {
  color: #57a3f3;
}
li > .el-date-editor {
  width: 240px !important;
}
.flex-container {
  #main {
    // flex: auto;
    width: 100vw;
    // margin-right: 18px;
    .filter {
      //     display: flex;
      //     align-items: center;
      // margin-right: -10px;
    }
  }
  #contest-menu {
    flex: none;
    width: 210px;
  }
}

.marginTop {
  margin-top: 20px;
}

.fontSize {
  font-size: 16px;
  display: flex;
  align-items: center;
}

.fontSize img {
  margin: 0 5px;
}

@media screen and (max-width: 600px) {
  .filter{
    display: flex;
    flex-wrap: wrap;
  }
  .ivu-card-extra {
    position: static !important;
    margin-bottom: 20px;
  }
  .ivu-card-extra ul {
    text-align: center;
  }

  .el-picker-panel__body {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
  }
  .el-picker-panel__sidebar {
    display: flex;
    width: 100vw;
  }

  .el-picker-panel__sidebar {
    min-width: 100vw;
    position: static !important;
  }
}
.page-wrapper{
    margin: 20px;
    float: right;
}
</style>
