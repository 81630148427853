import moment from 'moment'

// convert utc time to localtime
function utcToLocal (utcDt, format = 'YYYY년 M월 D일 HH시 mm분 ss초') {
  return moment.utc(utcDt).local().format(format)
}

function utcToLocalSimpleOnlyDate (utcDt, format = 'YYYY-M-D') {
  return moment.utc(utcDt).local().format(format)
}


function utcToLocalSimple (utcDt, format = 'YYYY-M-D HH:mm:ss') {
  return moment.utc(utcDt).local().format(format)
}

function utcToLocalJudge (utcDt, format = 'YYYY-MM-DD A hh시 mm분 ss초') {
  return moment.utc(utcDt).local().locale('ko').format(format)
}


// get duration from startTime to endTime, return like 3 days, 2 hours, one year ..
function duration (startTime, endTime) {
  let start = moment(startTime)
  let end = moment(endTime)
  let duration = moment.duration(start.diff(end, 'seconds'), 'seconds')
  // 하루에 넘을 때
  if (Math.abs(duration.asHours().toFixed(1)) >= 24){
    return duration.humanize()
  } 
  // 1시간 이상일 때
  else if(Math.abs(duration.asHours().toFixed(1)) >= 1){
    return Math.abs(duration.asHours().toFixed(1)) + ' hours'  
  }
  // 1시간 미만일 때
  return Math.abs(duration.asMinutes().toFixed(1)) + ' minutes'
}

function secondFormat (seconds) {
  let m = moment.duration(seconds, 'seconds')
  return Math.floor(m.asHours()) + ':' + m.minutes() + ':' + m.seconds()
}

export default {
  utcToLocal: utcToLocal,
  utcToLocalJudge : utcToLocalJudge,
  utcToLocalSimple,
  duration: duration,
  secondFormat: secondFormat,
  utcToLocalSimpleOnlyDate
}
