<template>
  <div class="toast-viewer">
    <div ref="viewer" >
    </div>
  </div>
</template>

<script>
import "@toast-ui/editor/dist/toastui-editor-viewer.css";
// ...

import 'prismjs/themes/prism.css';
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';

// Import prismjs
import Prism from 'prismjs';
import "prismjs/components/prism-c";
import "prismjs/components/prism-cpp";
import "prismjs/components/prism-java";
import "prismjs/components/prism-python";
import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';
export default {
  props:['content'],
  data(){
    return{
      viewer:null
    }
  },
  mounted() {
    this.getViewer();
    this.viewer.setMarkdown(this.content);
  },
  methods: {
    getViewer() {
      // this.getArticle().then((res) => {
       this.viewer =  new Viewer({
          el: this.$refs.viewer,
          // 표시하고자 하는 내용은 여기에 들어간다.
          // initialValue: this.content,

            plugins: [[codeSyntaxHighlight, { highlighter: Prism }]]
        });
      // });
    },
  },
};
</script>

<style>

/* :not(pre) > code[class*="language-"] {
    padding: 18px !important;
    border-radius: 0.3em;
    white-space: normal;
} */

.toastui-editor-contents pre{
  /* margin:2px 0px 0px; */
}
.toastui-editor-contents code{
  /* background-color: #F4F7F8 !important; */
  /* display: block; */
  
  /* padding-bottom: 3px; */
}

.toast-viewer{
  margin-top: 15px;
  padding:0px 20px 5px 20px;
}
</style>