<template>
  <el-row type="flex" :gutter="20">
    <el-col>
      <AdminPanel title="시스템 복구">
        <el-button type="success" :disabled="loading" @click="recovery">
          {{ loading ? "복구중.." : "복구하기 (약 3분 소요 )" }}
        </el-button>

        <div style="margin-top: 30px">
          <el-descriptions
            v-loading="loading"
            v-if="lastBuild.displayName"
            title="최근 완료된 빌드"
            size="medium"
          >
            <el-descriptions-item span="12" label="빌드 번호">
              {{ lastBuild.displayName }}
            </el-descriptions-item>
            <el-descriptions-item span="12" label="status">
              <!-- SUCCESS  -->
              <!-- UNSTABLE -->
              <!-- FAILED  -->
              <el-tag size="small" :type="setTag(lastBuild.result)">{{
                lastBuild.result
              }}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item span="12" label="빌드 시간"
              >{{ setTime(lastBuild.timestamp) }}
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </AdminPanel>
    </el-col>
  </el-row>
</template>

<script>
import axios from "axios";
import AdminPanel from "../components/AdminPanel.vue";
import moment from "moment";

export default {
  // jenkins 요청
  async created() {
    
    const DEV_SERVER = `/dev-backend-judge_server-reboot-system`;
    const MAIN_SERVER = `/backend-judge_server-reboot-system`;
    


    if (
      location.hostname === "pro.mincoding.co.kr" ||
      location.hostname === "mir.mincoding.co.kr"
    ) {
      this.selected_url = MAIN_SERVER;
    } else {
      this.selected_url = DEV_SERVER;
    }

    if (this.intervalTime) {
      clearInterval(this.intervalTime);
    }

    this.getData();
    this.intervalTime = setInterval(() => this.getData(), 3000);
  },
  data() {
    return {
      lastBuild: {},
      intervalTime: null,
      selected_url: null,
      loading: false,
      auth: {
        username: "mincoding",
        password: "asdfqwer1234@@",
      },
    };
  },
  beforeDestroy() {
    clearInterval(this.intervalTime);
  },
  methods: {
    setTime(timestamp) {
      return moment(timestamp).format("YYYY-M-D  HH시 mm분");
    },

    setTag(status) {
      if (status === "SUCCESS") return "success";
      if (status === "UNSTABLE") return "warning";
      if (status === "FAILED") return "danger";
    },
    recovery() {
      
      const jenkins_api = axios.create({
        baseURL: '/job',
       })

      const result = jenkins_api.get(
        `${this.selected_url}/build?token=11c8cad9aa933596fa96fe645ed25a8aa8`,
        {
          withCredentials: true,
          auth: this.auth,
        }
      );
    },
    async getData() {

      const jenkins_api = axios.create({
        baseURL: '/job',
       })

      const result = await jenkins_api.get(
        `${this.selected_url}/lastStableBuild/api/json`,
        {
          withCredentials: true,
          auth: this.auth,
        }
      );
      this.lastBuild = result.data;
      // console.log(result)

      const result2 = await jenkins_api.get(`${this.selected_url}/api/json`, {
        withCredentials: true,
        auth: this.auth,
      });
    
      // 빌드 완료 시점
      if (result2.data.lastBuild.number === result.data.number) {
        
        this.loading = false;
      } else {
        this.loading = true;
      }

      // 이제 result2의 lastBuild와 lastBuild
      // lastBuild와 lastCompleted Build가 같으면 -> build 완료 처리
      // 다르면 빌드중...을 표시하기
      // 빌드중일떄는 클릭을 못하게 disabled true를 하고 로딩을 넣는다
    },
  },
  components: { AdminPanel },
};
</script>

<style>
</style>