import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import contest from './modules/contest'
import board from './modules/board'
import notice from './modules/notice'
import elearning from './modules/e-learning'
import problemStep from './modules/problemStep'
import problem from "./modules/problem"
import utils from './modules/utils'
import api from '@oj/api'
import types from './types'
import createPersistedState from 'vuex-persistedstate';



Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'

const rootState = {
  isMobile:false,
  website: {},
  modalStatus: {
    mode: 'login', // or 'register',
    visible: false
  }
}

const rootGetters = {
  'website'(state) {
    return state.website
  },
  'modalStatus'(state) {
    return state.modalStatus
  }
}

const rootMutations = {
  CHANGE_IS_MOBILE(state, payload){
    state.isMobile = payload;
  },
  [types.UPDATE_WEBSITE_CONF](state, payload) {
    state.website = payload.websiteConfig
  },
  [types.CHANGE_MODAL_STATUS](state, { mode, visible }) {
    if (mode !== undefined) {
      state.modalStatus.mode = mode
    }
    if (visible !== undefined) {
      state.modalStatus.visible = visible
    }
  }
}

const rootActions = {
  getWebsiteConfig({ commit }) {
    api.getWebsiteConf().then(res => {
      commit(types.UPDATE_WEBSITE_CONF, {
        websiteConfig: res.data.data
      })
    })
  },
  changeModalStatus({ commit }, payload) {
    commit(types.CHANGE_MODAL_STATUS, payload)
  },
  changeDomTitle({ commit, state }, payload) {
    if (payload && payload.title) {
      window.document.title = state.website.website_name_shortcut + ' | ' + payload.title
    } else {
      window.document.title = state.website.website_name_shortcut + ' | ' + state.route.meta.title
    }
  }
}

export default new Vuex.Store({
  modules: {
    user,
    contest,
    board,
    notice,
    elearning,
    utils,
    problemStep,
    problem
  },
  plugins: [
    createPersistedState({
      paths: ['user','utils', 'board']
    })
  ],
  state: rootState,
  getters: rootGetters,
  mutations: rootMutations,
  actions: rootActions,
  strict: false
})

export { types }
