import moment from "moment";
import types from "../types";
import api from "@oj/api";


const state = {
	tagList: []
};

const getters = {};

const mutations = {
	[types.SET_TAG](state, payload) {

		state.tagList = payload;

	},
	[types.ADD_TAG](state, payload) {

		const result = state.tagList.some(li => li.id === payload.id);

		if (result) return;

		state.tagList = [...state.tagList, payload];

	},
	[types.REMOVE_TAG](state, payload) {

		state.tagList = state.tagList.filter(li => li != payload);

	},
	[types.CLEAR_TAG](state) {

		state.tagList = [];

	},

};

const actions = {
	//   async getBoard({ commit }, payload) {
	//     const response = await api.getBoardDetail(payload);
	//     const result = Object.entries(response).find(el => el[0] === "data");

	//     commit(types.POST_COMMENT, result[1]["data"]);
	//   }
};

export default {
	state,
	mutations,
	getters,
	actions
};
