<template>
  <div class="container">
    <div v-if="!mobileFlag&&$store.state.isMobile" class="mobile-sidemenu" ref="mobile_header">
      <SideMenu
        title="모바일"
        v-on:closeMenu="showGnb"
        v-click-outside="isMobileNavClick"
      ></SideMenu>
    </div>

    <div v-if="$store.state.isMobile" class="gnb" @click="showGnb">
      <i class="el-icon-s-unfold" ></i>
    </div>

    <div v-if="!$store.state.isMobile" class="pc-header" ref="header">
      <SideMenu title="pc"></SideMenu>
    </div>

    <!--
    <div id="header">
      <i class="el-icon-fa-font katex-editor" @click="katexVisible = true"></i>
      <screen-full :width="14" :height="14" class="screen-full"></screen-full>
      <el-dropdown @command="handleCommand">
        <span
          >{{ user.username }}<i class="el-icon-caret-bottom el-icon--right"></i
        ></span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="logout">Logout</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    -->
    <div class="admin-content-app">
      <transition name="fadeInUp" mode="out-in">
        <router-view></router-view>
      </transition>
      <!-- <div class="footer">Build Version: {{ version }}</div> -->
    </div>

    <el-dialog :title="$t('m.Latex_Editor')" :visible.sync="katexVisible">
      <KatexEditor></KatexEditor>
    </el-dialog>
  </div>
</template>

<script>
import { types } from "@/store";
import { mapGetters } from "vuex";
import SideMenu from "../components/SideMenu.vue";
import ScreenFull from "@admin/components/ScreenFull.vue";
import KatexEditor from "@admin/components/KatexEditor.vue";
import api from "../api";

export default {
  name: "app",
  data() {
    return {
      version: process.env.VERSION,
      katexVisible: false,
      mobileFlag: true,
    };
  },
  components: {
    SideMenu,
    KatexEditor,
    ScreenFull,
  },
  mounted() {},
  beforeRouteEnter(to, from, next) {
    api.getProfile().then((res) => {
      if (!res.data.data) {
        // not login
        next({ name: "login" });
      } else {
        next((vm) => {
          vm.$store.commit(types.CHANGE_PROFILE, { profile: res.data.data });
        });
      }
    });
  },
  methods: {
    handleCommand(command) {
      if (command === "logout") {
        api.logout().then(() => {
          this.$router.push({ name: "login" });
        });
      }
    },
    showGnb() {
      this.mobileFlag = !this.mobileFlag;
    },
    isMobileNavClick() {
      this.mobileFlag = true;
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>

<style lang="less" scoped>
a {
  background-color: transparent;
}

a:active,
a:hover {
  outline-width: 0;
}

img {
  border-style: none;
}

.container {
  display: flex;
  overflow: auto;
  font-weight: 400;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  background-color: #edecec;
  overflow-y: scroll;
  min-width: 600px;
  position: relative;
}

* {
  box-sizing: border-box;
}

#header {
  line-height: 50px;
  height: 50px;
  background: #f9fafc;
  .screen-full {
    margin-right: 8px;
  }
}

.gnb {
  font-size: 30px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 99;
  .el-icon-s-unfold {
    cursor: pointer;
    width: 30px;
    height: 30px;
  }
}

.admin-content-app {
  // padding: 0 !important;
  width: 80vw;
  margin-top: 0 !important;
  // padding-left: 205px !important;
}

.footer {
  margin: 15px;
  text-align: center;
  font-size: small;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate(0, 30px);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp-enter-active {
  animation: fadeInUp 0.8s;
}

.katex-editor {
  margin-right: 5px;
}

@media screen and (max-width: 600px) {
  .admin-content-app{
    width: 100vw;
  }
  .container{
    min-width: 300px;
  }
  .content-app {
    padding-left: 0px;
  }
  .pc-header {
    display: none;
  }
}

// @media screen and (max-width: 1200px) {
//   .content-app {
//     padding: 0 !important;
//     margin: 0 !important;
//   }
//   .pc-header {
//     display: none;
//   }
// }
</style>
