<template>
  <ckeditor
  id="editor"
    :editor="editor"
    :value="data"
    @input="onEditorInput"
    :config="editorConfig"
  ></ckeditor>
  
  
</template>

<script>
// import CkEditor from "@ckeditor/ckeditor5-vue2"
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
// import CodeBlock from 'ckeditor5-codeblock-with-syntax-highlight/src/codeblock'
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";

import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice";

import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";

import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Font from "@ckeditor/ckeditor5-font/src/font";
import Link from "@ckeditor/ckeditor5-link/src/link";
import List from "@ckeditor/ckeditor5-list/src/list";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import TableCaption from "@ckeditor/ckeditor5-table/src/tablecaption";
import TableProperty from "@ckeditor/ckeditor5-table/src/tableproperties";
import TableCellProperty from "@ckeditor/ckeditor5-table/src/tablecellproperties";


import ListProperties from "@ckeditor/ckeditor5-list/src/listproperties";
import TodoList from "@ckeditor/ckeditor5-list/src/todolist";
import FindAndReplace from "@ckeditor/ckeditor5-find-and-replace/src/findandreplace";
import GeneralHtmlSupport from "@ckeditor/ckeditor5-html-support/src/generalhtmlsupport";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat";

import Ko from "@ckeditor/ckeditor5-build-classic/build/translations/ko"

import LinkImage from "@ckeditor/ckeditor5-link/src/linkimage";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import ImageResizeEditing from "@ckeditor/ckeditor5-image/src/imageresize/imageresizeediting";
import ImageResizeHandles from "@ckeditor/ckeditor5-image/src/imageresize/imageresizehandles";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";
import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
import AutoImage from "@ckeditor/ckeditor5-image/src/autoimage";
import Uploader from "./Uploader";

import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';

export default {
  props: ["data", "toolbar"],

  components: {},

  created(){
    if(this.toolbar === 'comment'){
      this.editorConfig.toolbar = {
        items: [
            
            // "heading",
            // 'fontsize', '|',
            "alignment",
            "fontColor",
            "|",
            "bold",
            "italic",
            "strikethrough",
            "underline",
            "|",
            'bulletedList', 'numberedList',
            
            // "subscript",
            // "superscript",
            "|",

            "link",
            "|",

            // "code",
            "codeBlock",
            
            "uploadImage",
            "insertFileAndImage",
            
            
            "undo",
            "redo",
            
          ],    
        }
    
    }
  
  },
  mounted() {
    
    
  },
  data() {
    return {
      editorText: "",
      content: "",
      editor: ClassicEditor,
      editorData: "<p>Content of the editor.</p>",
      editorConfig: {
          language: "ko",
        plugins: [
          
          Heading,
          EssentialsPlugin,
          BoldPlugin,
          ItalicPlugin,
          LinkPlugin,
          ParagraphPlugin,
          BlockQuote,
          PasteFromOffice,
          Alignment,
          Underline,
          Strikethrough,
          Font,
          Link,
          List,
          TodoList,
          FindAndReplace,
          Uploader,
          Table,
          TableToolbar,
          TableCaption,
          TableProperty,
          TableCellProperty,
          Indent,
          IndentBlock,
          // Markdown,
          CodeBlock,
          GeneralHtmlSupport,
          Autoformat,
          Image,
          ImageToolbar,
          ImageCaption,
          ImageStyle,
          ImageResize,
          LinkImage,
          ImageUpload,
          ImageResizeEditing,
          ImageResizeHandles,
          // Base64UploadAdapter,
          ListProperties,
          AutoImage,
          SimpleUploadAdapter,
          MediaEmbed
        ],
        //  minimap: {
        //     container: this.$refs['minimap-container'],
        //     useSimplePreview: true
        // },
        toolbar: {
          items: [
            
            "heading",
            'fontsize', '|',
            "alignment",
            "fontColor",
            "|",
            "bold",
            "italic",
            "strikethrough",
            "underline",
            "|",
            'bulletedList', 'numberedList',
            
            // "subscript",
            // "superscript",
            "|",

            "link",
            "|",

            // "code",
            "codeBlock",
            "|",
            "insertTable",
            "|",
            "uploadImage",
            "insertFileAndImage",
            "|",
            "findAndReplace",
            "undo",
            "redo",
            "mediaEmbed",
          ],
        },
        
        link: {
          addTargetToExternalLinks: true,
          decorators: {
            addTargetToExternalLinks: {
              mode: "automatic",
              callback: (url) => {
                // console.log(url);
                //
                // console.log(/public\/upload\/*/g.test(url));

                return /public\/upload\/*/g.test(url);
              },
              attributes: {
                download: "download",
                // rel: 'noopener noreferrer'
              },
            },
          },
          // Let the users control the "download" attribute of each link.
          // decorators: [
          //     {
          //         mode: 'manual',
          //         label: 'Downloadable',
          //         attributes: {
          //             download: 'download'
          //         }
          //     }
          // ]
        },

        list: {
          properties: {
            styles: true,
            startIndex: true,
            reversed: true,
          },
        },
        shouldNotGroupWhenFull: true,
        allowedContent: true,
        image: {
          resizeUnit: "px",
          toolbar: [
            "imageStyle:alignBlockLeft",
            "imageStyle:block",
            "imageStyle:alignBlockRight",
            "|",
            "toggleImageCaption",
            "imageTextAlternative",
            "|",
            "linkImage",
            "resizeImage",
          ],
        },
        heading: {
          options: [
            {
              model: "paragraph",
              view: "p",
              title: "본문",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "헤더1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "헤더2",
              class: "ck-heading_heading2",
            },
            {
              model: "heading3",
              view: "h3",
              title: "헤더3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view: "h4",
              title: "헤더4",
              class: "ck-heading_heading4",
            },
          ],
        },
        fontSize: {
            options: [
                12,
                14,
                16,
      
                18,
                24,
        
            ]
        },
        // typing: {
        //   transformations: {
        //     remove: [
        //       "enDash",
        //       "emDash",
        //       "oneHalf",
        //       "oneThird",
        //       "twoThirds",
        //       "oneForth",
        //       "threeQuarters",
        //     ],
        //   },
        // },
        codeBlock: {
          languages: [
            // Do not render the CSS class for the plain text code blocks.

            // Use the "php-code" class for PHP code blocks.
            { language: "cpp", label: "C++" },
            { language: "python", label: "Python" },
            { language: "java", label: "Java" },
            { language: "cs", label: "C#" },
            { language: "plaintext", label: "Text", class: "" },

            // Use the "js" class for JavaScript code blocks.
            // Note that only the first ("js") class will determine the language of the block when loading data.
            // { language: 'javascript', label: 'JavaScript', class: 'js javascript js-code' },

            // Python code blocks will have the default "language-python" CSS class.
            // { language: 'python', label: 'Python' }
          ],
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "toggleTableCaption",
            "tableProperties",
            "tableCellProperties",
          ],
        },
        htmlSupport: {
          allow: [
            {
              name: "p",
              styles: true,
            },
            {
              name: "ul",
              styles: true,
            },
            {
              name: "li",
              styles: true,
            },
            {
              name: "ol",
              styles: true,
            },

            {
              name: "h*",
              styles: true,
            },
          ],
        },
        simpleUpload: {
          // The URL that the images are uploaded to.
          uploadUrl: "/api/ck_upload_image",

          // Enable the XMLHttpRequest.withCredentials property.
          withCredentials: true,

          // Headers sent along with the XMLHttpRequest to the upload server.
          headers: {
            "X-CSRF-TOKEN": "csrftoken",

            // Authorization: 'Bearer <JSON Web Token>'
          },
        },
        mediaEmbed: {
            
        }
        //   image: {
        //     toolbar: [
        //         'imageStyle:inline',
        //         'imageStyle:block',
        //         'imageStyle:side',
        //         '|',
        //         'toggleImageCaption',
        //         'imageTextAlternative'
        //     ]
        // },
      
        // The configuration of the editor.
      },
    };
  },
  watch: {
    // watch를 활용한 props 변경 감지
    // data(newValue) {
    //   if (this.editorText !== newValue) {
    //     try {
    //       // this.editor.setMarkdown(newValue);
    //     } catch (error) {

    //       // this.editor.setHTML(newValue);
    //     }
    //     // this.editor.setHTML(newValue);
    //   }
    // },

    editorText(newValue, oldValue) {
      if (newValue !== oldValue) {
        // console.log("변경")
        this.$emit("setContent", this.editorText);
      }
    },
  },
  methods: {
    onEditorInput(text, event, editor) {
  
      if(!text) this.editorText = "";
      else this.editorText = text;
      this.$emit("setContent", this.editorText);
      // this.data = text;
    },
  },
};
</script>

<style>
.ck-content .table table {
  border-collapse: collapse;
  border-spacing: 0;
  /* width: 100%; */
  width: inherit;
  height: 100%;
  border: 1px double hsl(0, 0%, 70%);
}

.ck-editor__editable ul, .ck-editor__editable ol{
  padding-left: 20px !important;
}


.ck-widget.ck-widget.media{
    position: relative;
    padding-top: 0;
    width: 50%;
    height: 0;
}

.ck-widget.ck-widget.media iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>