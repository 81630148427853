import api from "@oj/api";
import { mapState, mapActions, mapGetters } from "vuex";
import time from "@/utils/time";
import BoardHighlight from "@/pages/oj/components/BoardHighlight";
import Comment from "./Comment";
import ChangeCommentText from "../../components/ChangeCommentText.vue"
const mixins = {
    components: { Comment, BoardHighlight,  ChangeCommentText},

    data() {
        const index = this.$route.params.board_id;
        return {
            data: [],
            index: index,
            flag: false,
            commentIndex: "",
            input: "",
            comment: "",
        };
    },
    async mounted() {
        
        const reuslt = await this.getBoard(this.$route.params["board_id"]);
        if (!this.isSameCompany()) {
            this.$router.push("/");
            this.$error("권한이 없습니다.");
        }

        if (!this.isPublicPost() && !this.isPermission()) {
            this.$router.push("/");
            this.$error("권한이 없습니다.");
        }
    },
    computed: {
        ...mapGetters(["isAdminRole", "isManager"]),
        ...mapState(["board", "user"])
    },

    methods: {
        ...mapActions(["getBoard"]),
        toLocal(data) {
            const result = time.utcToLocal(data, "YYYY년 M월 D일 HH시 mm분");
            return result;
        },
        commentToLocal(data) {
            const result = time.utcToLocal(data, "(YY-MM-DD HH:mm)");
            return result;
        },
        deleteComment(id) {
            this.$Modal.confirm({
                content: "댓글을 삭제하시겠습니까?",
                onOk: async () => {
                    const hello = await api.deleteComment(id);
                    const reuslt = await this.getBoard(this.$route.params["board_id"]);
                },
                onCancel: () => { }
            });
        },
        chageflag(id, comment) {
            this.flag = !this.flag;
            this.commentIndex = id;
            let str = comment.replace(/<br\s*\/?>/gm, "\n");
            this.comment = str;
        },
        async cancle() {
            const result = await this.getBoard(this.$route.params["board_id"]);
            this.flag = false;
            this.comment = "";
            this.commentIndex = "";
        },
        async changeComment(comment_id) {
            if (!this.comment.trim().length) {
                this.$error("댓글을 입력해주세요");
                return;
            }
            let str = this.comment.replace(/ /g, "\u00a0");
            str = str.replace(/(?:\r\n|\r|\n)/g, "<br/>");
            this.$Modal.confirm({
                content: "댓글을 수정하시겠습니까?",
                onOk: async () => {
                    await api.putComment(comment_id, str);
                    const reuslt = await this.getBoard(this.$route.params["board_id"]);
                    this.flag = false;
                    this.comment = "";
                    this.commentIndex = "";
                },
                onCancel: () => { }
            });
        },
        secretBoard(flag) {
            if (this.isPermission()) {
                let type = "";
                if (flag === 3) type = "normal";
                else type = "secret";
                this.$Modal.confirm({
                    content: `해당 게시글을 ${type === "normal" ? "공개" : "비공개"
                        } 처리 하시겠습니까?`,
                    onOk: async () => {
                        await api.secretBoard(this.$route.params["board_id"], type);
                        const reuslt = await this.getBoard(this.$route.params["board_id"]);
                    },
                    onCancel: () => { }
                });
            } else {
                this.$error("권한이 없습니다.");
            }
        },
        secretComment(id, flag) {
            let type = "";
            if (flag === 3) type = "normal";
            else type = "secret";
            this.$Modal.confirm({
                content: `해당 댓글을 ${type === "normal" ? "공개" : "비공개"
                    } 처리 하시겠습니까?`,
                onOk: async () => {
                    await api.secretComment(id, type);
                    const reuslt = await this.getBoard(this.$route.params["board_id"]);
                },
                onCancel: () => { }
            });
        },
        isPermission() {
        
            return (this.user && this.user.profile.user.id === this.board['board']['created_by'].id) || this.isAdminRole || this.isManager
        },
        isPublicPost() {
            return this.board.board.flag === 0;
        },
        isSameCompany() {
            //관리자 글을 못보는문제 해결
            if(this.board['board']['created_by'].admin_type === "Super Admin"){
                    return true
            }

            if(this.board['board']['created_by'].admin_type === 'Regular User'){
                return true
            }
            // 기존유저들 고려 코드

            return (this.user && this.user.profile.github === this.board['board']['created_by'].github) || this.isManager || this.isAdminRole || (this.user.profile.user.admin_type === this.board['board']['created_by'].admin_type)
        }
    }
}


export default mixins;