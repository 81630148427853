<template>
  <div style="background-color: white">
    <el-container class="create-container">
      <el-header>
        <div></div>
        <div class="detail_title">
          <span>질문게시판</span>
          <el-tag
            v-if="board['board'].contest?.id"
            @click="() => moveRoute('contest')"
            type="primary"
            class="detail_title_tag"
            >{{ board["board"].contest?.title }}</el-tag
          >
          <el-tag
            v-if="board['board'].problem?.id"
            @click="() => moveRoute('problem')"
            type="success"
            class="detail_title_tag"
            >{{ board["board"].problem?.title }}</el-tag
          >
        </div>
        <div class="detail-header-wrapper">
          <div></div>
          <div
            v-if="board['board'].flag === 3"
            class="detail_title"
            style="text-align: center; margin-left: 15%"
          >
            {{ board["board"].title }} 🔒
          </div>
          <div
            v-else
            class="detail_title"
            style="text-align: center; margin-left: 15%"
          >
            {{ board["board"].title }}
          </div>
          <div v-if="$store.state.isMobile" class="detail_subtitle">
            <div class="detail_subtitle-name">
              {{ board["board"].real_name }} /
              {{ board["board"].created_by.username }}
            </div>
            <div class="detail_subtitle-date">
              {{ simpleToLocal(board["board"].created_time) }}
            </div>
          </div>
          <div v-else class="detail_subtitle">
            <span>
              작성자 : {{ board["board"].real_name }} /
              {{ board["board"].created_by.username }}
            </span>
            <span> 작성일 : {{ toLocal(board["board"].created_time) }} </span>
          </div>
        </div>
      </el-header>
      <hr />
      <el-main>
        <div class="detail_content">
          <div class="detail_optionbtn">
            <el-button
              :size="$store.state.isMobile ? 'mini' : 'medium'"
              @click="list"
              >목록</el-button
            >

            <el-button
              :size="$store.state.isMobile ? 'mini' : 'medium'"
              v-if="
                isAdminRole ||
                (user.profile.user && user.profile.user.id) ===
                  (board['board']['created_by'] && board['board'].created_by.id)
              "
              @click="updateData"
              >수정</el-button
            >

            <el-button
              :size="$store.state.isMobile ? 'mini' : 'medium'"
              @click="secretBoard(board['board'].flag)"
              v-if="
                isAdminRole ||
                (user.profile.user && user.profile.user.id) ===
                  (board['board']['created_by'] && board['board'].created_by.id)
              "
            >
              <span v-if="board['board'].flag == 3"> 공개 </span>
              <span v-else> 비공개 </span>
            </el-button>

            <el-button
              :size="$store.state.isMobile ? 'mini' : 'medium'"
              @click="changeAnnounce(board['board']['isNotice'])"
              v-if="isAdminRole || isManager"
            >
              <span v-if="board['board'].isNotice"> 공지해제 </span>
              <span v-else> 공지하기</span>
            </el-button>

            <el-button
              :size="$store.state.isMobile ? 'mini' : 'medium'"
              @click="deleteBoard"
              v-if="
                isAdminRole || 
                ((user.profile.user && user.profile.user.id) ===
                  (board['board']['created_by'] && board['board'].created_by.id) === true)
              "
              >삭제</el-button
            >

            <el-dropdown
              v-if="isAdminRole"
              style="margin-left: 15px"
              :size="$store.state.isMobile ? 'mini' : 'medium'"
              :type="type"
              @command="handleSetType"
            >
              <el-button
                :type="type"
                :size="$store.state.isMobile ? 'mini' : 'medium'"
              >
                {{ typeText }}<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="0">new</el-dropdown-item>
                <el-dropdown-item command="1">처리중</el-dropdown-item>
                <el-dropdown-item command="2">완료</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <!-- <ToastViewer v-if="content" :content="content">
          </ToastViewer> -->

          <BoardHighlight
            class="bg-code ck-content"
            :code="content"
          ></BoardHighlight>
          <!-- <div v-html="content">

          </div> -->
        </div>
      </el-main>
      <el-footer>
        <div
          v-for="c in board['board'].comments"
          :key="c.id"
          class="detail_comment"
        >
          <div class="board_comment-wrapper">
            <div class="board_comment">
              <div v-if="$store.state.isMobile">
                <div class="detail_comment_font">
                  {{ c.real_name }}
                  <span> {{ commentToLocal(c.created_time) }} </span>
                </div>
                <!-- <div >
                  {{ simpleToLocal(c.created_time) }}</div
                > -->
              </div>
              <span v-else>
                <span class="detail_comment_font">{{ c.real_name }} </span>
                <span class="detail_comment_font">
                  {{ commentToLocal(c.created_time) }}</span
                >
              </span>
              <div class="button-wrapper" style="margin-left: auto">
                <el-button
                  :size="$store.state.isMobile ? 'mini' : 'medium'"
                  class="detail_comment_button"
                  @click="chageflag(c.id, c.comment)"
                  :disabled="flag"
                  v-if="
                    isAdminRole ||
                    (commentIndex !== c.id &&
                      user.profile.user &&
                      user.profile.user.id === c.created_by.id)
                  "
                  >수정</el-button
                >
                <el-button
                  :size="$store.state.isMobile ? 'mini' : 'medium'"
                  class="detail_comment_button"
                  @click="secretComment(c.id, c.flag)"
                  v-if="
                    isAdminRole ||
                    (commentIndex !== c.id &&
                      user.profile.user &&
                      user.profile.user.id === c.created_by.id)
                  "
                >
                  <span v-if="c.flag == 3"> 공개 </span>
                  <span v-else> 비공개 </span>
                </el-button>
                <el-button
                  :size="$store.state.isMobile ? 'mini' : 'medium'"
                  class="detail_comment_button"
                  @click="deleteComment(c.id)"
                  v-if="
                    isAdminRole ||
                    (user.profile.user &&
                      user.profile.user.id === c.created_by.id)
                  "
                  >삭제</el-button
                >
              </div>
            </div>
            <div v-if="flag && commentIndex === c.id" class="textarea">
              <!-- <el-input
                class="textarea"
                type="textarea"
                :rows="3"
                autosize
                placeholder="댓글을 입력해주세요"
                v-model="comment"
              ></el-input> -->

              <VueCkEditor
                :data="comment"
                @setContent="setComment"
                toolbar="comment"
              ></VueCkEditor>

              <el-button
                class="detail_comment_button"
                @click="changeComment(c.id)"
                >변경</el-button
              >
              <el-button class="detail_comment_button" @click="cancle()"
                >취소</el-button
              >
            </div>
            <div
              v-if="
                c.flag === 3 &&
                (isAdminRole ||isManager || c.created_by.id === user.profile.user.id) &&
                !flag
              "
              class="comment-font comment-style"
            >
              <BoardHighlight
                class="bg-code ck-content"
                :code="c.comment"
              ></BoardHighlight>
              <!-- 🔒 -->
            </div>
            <div class="comment-font" v-else-if="c.flag === 3 && !flag">
              비공개 댓글입니다. 🔒
            </div>
            <!-- <div class="comment-font comment-style" v-else-if="!flag" v-html="c.comment"></div> -->
            <div class="comment-font" v-else-if="!flag">
              <BoardHighlight
                class="bg-code ck-content"
                :code="c.comment"
              ></BoardHighlight>
              <!-- <ChangeCommentText :comment="c.comment"></ChangeCommentText> -->
            </div>
          </div>
        </div>

        <Comment />
      </el-footer>
    </el-container>
  </div>
</template>

<script>
// import time from "@/utils/time";
import api from "@oj/api";
// import Comment from "./Comment";
// import { mapState, mapActions, mapGetters } from "vuex";
import BoardHighlight from "@/pages/oj/components/BoardHighlight";
import ChangeCommentText from "@/pages/oj/components/ChangeCommentText";
// import ToastViewer from "@/pages/admin/components/ToastViewer"
import mixins from "./detailMixins";
import ToastViewerMixins from "@/pages/admin/components/toastViewerMixins.js";
// import toastViewerMixins from "@/pages/admin/components/toastViewerMixins.js";
import VueCkEditor from "@/pages/admin/components/VueCkEditor.vue";
export default {
  mixins: [mixins, ToastViewerMixins],
  name: "detail",
  props: ["code"],
  // components: { Comment, BoardHighlight },
  components: { BoardHighlight, ChangeCommentText, VueCkEditor },
  mounted() {
    // this.handleSetType(this.board['board'].process)
  },
  methods: {
    deleteBoard() {
      if (this.isPermission()) {
        this.$Modal.confirm({
          content: "게시글을 삭제하시겠습니까?",
          onOk: async () => {
            // 관리자가 아니면 댓글이 달린 게시글은 삭제할수없게 하기

            let deleteFlag = 0;
            

            if (
              this.user.profile.user.id === this.board["board"].created_by.id
            ) {
              deleteFlag = 1;
            }

            if (this.board["board"].comments.length > 0) {
              const result = this.board["board"]?.comments.every((comment) => {
                return this.user.profile.user.id === comment.created_by.id;
              });

              if (result) {
                if (
                  this.user.profile.user.id ===
                  this.board["board"].created_by.id
                ) {
                  deleteFlag = 1;
                }
              } else {
                deleteFlag = 0;
              }
            }
            if (this.isAdminRole) {
              deleteFlag = 1;
            }

            if (deleteFlag === 1) {
              await api.deleteBoard(this.$route.params["board_id"]);
              if (this.$route.params.contestID) {
                this.$router.push({
                  path: `/enterprise/contest/${this.$route.params.board_name.toLowerCase()}/${
                    this.$route.params.contestID
                  }/qna`,
                  query: { page: this.currentPage },
                });
              } else {
                this.$router.push({
                  path: `/qna/${this.$route.params.board_name.toLowerCase()}`,
                  query: { page: this.$route.query.page },
                });
              }
            } else if (deleteFlag === 0) {
              this.$error("답변이 달린 게시글은 삭제할수 없습니다.");
            }
          },
          onCancel: () => {},
        });
      } else {
        this.$error("권한이 없습니다.");
      }
    },
    async updateData() {
      if (this.isPermission()) {
        if (this.$route.params.contestID) {
          try {
            if (!this.isAdminRole) {
              await api.getBoardAuthCheck(this.$route.params.contestID);
            }

            this.$router.push({
              path: `/enterprise/contest/${this.$route.params.board_name.toLowerCase()}/${
                this.$route.params.contestID
              }/qna/${this.$route.params.board_id}/edit`,
            });
          } catch (error) {}
        } else {
          this.$router.push({
            path: `/qna/${this.$route.params.board_name.toLowerCase()}/${
              this.$route.params["board_id"]
            }/edit`,
            query: { page: this.$route.query.page },
          });
        }
      } else {
        this.$error("권한이 없습니다.");
      }
    },
    list() {
      if (this.$route.params.contestID) {
        this.$router.push({
          path: `/enterprise/contest/${this.$route.params.board_name.toLowerCase()}/${
            this.$route.params.contestID
          }/qna`,
        });
      } else {
        this.$router.push({
          path: `/qna/${this.$route.params.board_name.toLowerCase()}`,
          query: { page: this.$route.query.page },
        });
      }
    },
    moveRoute(target) {
      if (target === "contest") {
        // 관리자는 다르게 진행하기
        window.open(
          `/enterprise/contest/${this.$route.params.board_name.toLowerCase()}/${
            this.board["board"].contest?.id
          }`
        );
      }
      if (target === "problem") {
        window.open(
          `/enterprise/contest/${this.$route.params.board_name.toLowerCase()}/${
            this.board["board"].contest?.id
          }/problem/${this.board["board"].problem?._id}`
        );
      }
    },
    // setType(process) {
    //   if (process == 0) return "primary";
    //   if (process == 1) return "warning";
    //   if (process == 2) return "success";
    // },

    // setTypeText(process) {
    //   if (process == 0) return "new";
    //   if (process == 1) return "처리중";
    //   if (process == 2) return "완료";
    // },

    // handleSetType(command) {
    //   if (command == 0) {
    //     this.type = "primary";
    //     this.typeText = 'new';
    //   }
    //   if (command == 1) {
    //     this.type = "warning";
    //     this.typeText = '처리중';
    //   }
    //   if (command == 2) {
    //     this.type = "success";
    //     this.typeText = '완료';
    //   }
    // },
  },
};
</script>

<style>
.textarea .ck.ck-editor__editable {
  min-height: 150px !important;
}
</style>
<style scoped lang="less">
.create-container {
  padding: 0 13% !important;
}

@media (max-width: 618px) {
  .create-container {
    padding: 0 !important;
  }
}
.el-header {
  background: white;
  padding: 40px 40px;
  height: 100% !important;
}
.el-footer {
  height: 100%;
}
.el-main {
  background: white;
  min-height: 30vh;
}
.el-footer {
  height: 100% !important;
  padding: 0 !important;
}

.el-input input {
  border-top: 1px solid #eeeeee;
}
body .el-container {
  margin-bottom: 40px;
  padding: 30px 20px;
  width: 90%;
  margin: 0px auto;
}
.detail-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.detail_title {
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 10px;
  word-break: break-all;
  display: flex;
  align-content: center;
}

.detail_title_tag {
  margin-left: 8px;
  cursor: pointer;
}

.detail_title_tag:hover {
  opacity: 0.6;
}

.detail_subtitle {
  margin-right: 10px;
  text-align: left;
  display: flex;
}
.detail_subtitle > span {
  margin-left: 30px;
}

.detail_content {
  // min-height: 100px;
  // font-size: 16px;
  // width: 100%;
}
.detail_optionbtn {
  text-align: right;
}

.detail_comment {
  border: 1px solid #eeeeee;
  background-color: white;
  padding: 20px 15px 5px 20px;
  margin-bottom: 10px;
}
.detail_comment_button {
  margin-left: auto;
  font-size: 12px;
}
.detail_comment_font {
  font-size: 13px;
  font-weight: 600;
  margin-right: 20px;
}

.board_comment-wrapper {
  display: flex;
  flex-direction: column;
}
.board_comment {
  display: flex;
}
.board_comment button {
  padding: 11px !important;
}
.comment .el-textarea__inner {
  min-height: 45px !important;
}

.comment-font {
  // font-size: 14px;
  // line-height: 20px;
}

.comment-style {
  word-break: break-all;
}

.textarea {
  margin: 10px 0px;
}

.bg-code {
  margin-top: 20px;
}

@media screen and (max-width: 600px) {
  body .el-container {
    width: 100%;
    padding: 5px !important;
  }
  .create-container .el-header {
    padding: 15px 0 !important;
  }
  .detail_title {
    text-align: center;
    display: none;
    font-size: 16px;
  }
  .detail-header-wrapper {
    flex-direction: column;
  }

  .detail-header-wrapper .detail_title {
    margin-left: 0 !important;
    font-size: 16px;
    display: block;
  }
  .detail_subtitle {
    width: 100%;
    margin-right: 10px;
    justify-content: flex-end;
    align-items: center;
    // flex-direction: column;
  }
  .detail_subtitle-name {
    font-weight: bold;
  }

  .detail_subtitle-date {
    margin-left: 10px;
    font-size: 12px;
  }
  .detail_subtitle > span {
    margin: 0;
    font-size: 12px;
  }

  .el-main {
    padding: 0;
    padding-top: 10px;
  }
  .el-main img {
    max-width: 100vw;
  }

  .detail_comment {
    min-height: 110px;
    padding: 5px 10px;
  }
  .detail_comment_font {
    font-size: 14px;
    font-weight: 14px;
    display: flex;
    align-items: center;
  }
  .detail_comment_font > span {
    margin-left: 5px;
    font-weight: normal;
    font-size: 12px;
  }

  .board_comment {
    flex-wrap: wrap;
  }
  .board_comment button {
    padding: 7px 15px !important;
  }

  .button-wrapper {
    display: flex;
  }

  .el-footer {
    margin-top: 20px;
  }
  .button-wrapper > button {
    margin: 2px 0;
  }
}
</style>