<template>
  <div style="display: inline-block">
    <el-tooltip class="item" effect="dark" :content="name" placement="top">
      <el-button plain :icon="'el-icon-' + icon" size="mini" :type="type"  :disabled="disabled">
      </el-button>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: "IconBtn",
  props: {
    name: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
    },
    disabled:{
      type: Boolean,
      required: false
    }
  },
  mounted() {},
};
</script>
<style>
.el-button--mini > i {
  font-size: 14px !important;
}
</style>
