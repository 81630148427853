<!-- 비밀코드 입력시 먼저 비밀코드 테이블에 등록
      auth에 리스트들을 받아와서 없는 경우 추가하게 하기 
      작성시 먼저 비밀코드 테이블이 생성및 등록
      그후 해당 비밀코드 테이블의 id와 auth리스트들을 중복해서 secret_auth DB에 저장 
      secret_code_auth 테이블에 각각 값들을 저장한다.
      -->
<template>
  <div class="problem">
    <AdminPanel :title="title">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-position="top"
        label-width="70px"
      >
        <el-row :gutter="20">
          <el-col :span="18">
            <el-form-item v-if="!isEdit" label="권한">
              <el-input
                placeholder="등록할 권한을 작성하세요"
                v-model="form.code"
              ></el-input>
            </el-form-item>
            <el-form-item v-else label="권한(수정)">
              <el-input
                placeholder="등록할 권한을 작성하세요"
                v-model="form.code"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <save @click.native="submit()">Save</save>
      </el-form>
    </AdminPanel>
  </div>
</template>

<script>
import AdminPanel from "@admin/components/AdminPanel.vue";
import api from "../../../api";
export default {
  name: "Problem",
  components:{
AdminPanel
  },
  data() {
    return {
      isEdit:false,
      checkList: [],
      form: {
        id:"",
        code: ""
      }
    };
  },
  async mounted() {
    
    if(this.$route.path.indexOf("edit") > 0){
      this.isEdit = true
      const result = await api.getMincodingAuth(this.$route.params.id);

      this.form.code = result.data.data.name
      this.form.id = result.data.data.id
    }
    // mounted에서 해야할 작업
    // 권한 선택 list 변경시키기
  },

  methods: {
    async submit() {
      const name = this.form.code;
      // axios 호출 라인
      // 수정
      if(this.isEdit){
      await api.updateMincodingAuth(this.form.id, name)
      // 생성
      }else{
        await api.createMincodingAuth(name);
      }
      // 완료시
      this.$router.push("/mincoding_auth");
    }
  }
};
</script>

<style lang="less" scoped>
.problem {
  .difficulty-select {
    width: 120px;
  }
  .spj-radio {
    margin-left: 10px;
    &:last-child {
      margin-right: 20px;
    }
  }
  .input-new-tag {
    width: 78px;
  }
  .button-new-tag {
    height: 24px;
    line-height: 22px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .tags {
    .el-tag {
      margin-right: 10px;
    }
  }
  .accordion {
    margin-bottom: 10px;
  }
  .add-samples {
    width: 100%;
    background-color: #fff;
    border: 1px dashed #aaa;
    outline: none;
    cursor: pointer;
    color: #666;
    height: 35px;
    font-size: 14px;
    &:hover {
      background-color: #f9fafc;
    }
    i {
      margin-right: 10px;
    }
  }
  .add-sample-btn {
    margin-bottom: 10px;
  }
}
</style>

<style>
.dialog-compile-error {
  width: auto;
  max-width: 80%;
  overflow-x: scroll;
}
</style>
