<template>
  <div class="view">
    <AdminPanel :title="'SMTP 설정'">
      <el-form label-position="left" label-width="70px" :model="smtp">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="12" :lg="12">
            <el-form-item :label="'서버'" required>
              <el-input
                v-model="smtp.server"
                placeholder="SMTP Server Address"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12">
            <el-form-item :label="'포트'" required>
              <el-input
                type="number"
                v-model="smtp.port"
                placeholder="SMTP Server Port"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12">
            <el-form-item :label="'이메일'" required>
              <el-input
                v-model="smtp.email"
                placeholder="Account Used To Send Email"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12">
            <el-form-item :label="'비밀번호'" label-width="90px" required>
              <el-input
                v-model="smtp.password"
                type="password"
                placeholder="SMTP Server Password"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="TLS">
              <el-switch v-model="smtp.tls"> </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-button type="primary" @click="saveSMTPConfig">저장</el-button>
      <el-button
        type="warning"
        @click="testSMTPConfig"
        v-if="saved"
        :loading="loadingBtnTest"
        >테스트 이메일 보내기</el-button
      >
    </AdminPanel>

    <AdminPanel :title="'웹 설정'">
      <el-form
        label-position="left"
        label-width="100px"
        ref="form"
        :model="websiteConfig"
      >
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="12" :lg="12">
            <el-form-item :label="'기본 URL'" required>
              <el-input
                v-model="websiteConfig.website_base_url"
                placeholder="Website Base Url"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12">
            <el-form-item :label="'이름'" required>
              <el-input
                v-model="websiteConfig.website_name"
                placeholder="Website Name"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12">
            <el-form-item :label="'바로가기'" required>
              <el-input
                v-model="websiteConfig.website_name_shortcut"
                placeholder="Website Name Shortcut"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="'푸터'" required>
              <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
                v-model="websiteConfig.website_footer"
                placeholder="Website Footer HTML"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-col :xs="24" :sm="24" :md="12" :lg="12">
              <el-form-item :label="'등록 허용'" label-width="200px">
                <el-switch
                  v-model="websiteConfig.allow_register"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                >
                </el-switch>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12">
              <el-form-item :label="'제출 목록 전체보기'" label-width="200px">
                <el-switch
                  v-model="websiteConfig.submission_list_show_all"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                >
                </el-switch>
              </el-form-item>
            </el-col>
          </el-col>
        </el-row>
      </el-form>
      <save @click.native="saveWebsiteConfig"></save>
    </AdminPanel>
  </div>
</template>

<script>
import api from "../../api.js";
import AdminPanel from "@admin/components/AdminPanel.vue";
export default {
  name: "Conf",
  components: {
    AdminPanel,
  },
  data() {
    return {
      init: false,
      saved: false,
      loadingBtnTest: false,
      smtp: {
        server: "smtp.example.com",
        port: 25,
        password: "",
        email: "email@example.com",
        tls: true,
      },
      websiteConfig: {},
    };
  },
  mounted() {
    api.getSMTPConfig().then((res) => {
      if (res.data.data) {
        this.smtp = res.data.data;
      } else {
        this.init = true;
        this.$warning("Please setup SMTP config at first");
      }
    });
    api
      .getWebsiteConfig()
      .then((res) => {
        this.websiteConfig = res.data.data;
      })
      .catch(() => {});
  },
  methods: {
    saveSMTPConfig() {
      if (!this.init) {
        api.editSMTPConfig(this.smtp).then(
          () => {
            this.saved = true;
          },
          () => {}
        );
      } else {
        api.createSMTPConfig(this.smtp).then(
          () => {
            this.saved = true;
          },
          () => {}
        );
      }
    },
    testSMTPConfig() {
      this.$prompt("Please input your email", "", {
        inputPattern:
          /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        inputErrorMessage: "Error email format",
      })
        .then(({ value }) => {
          this.loadingBtnTest = true;
          api.testSMTPConfig(value).then(
            () => {
              this.loadingBtnTest = false;
            },
            () => {
              this.loadingBtnTest = false;
            }
          );
        })
        .catch(() => {});
    },
    saveWebsiteConfig() {
      api
        .editWebsiteConfig(this.websiteConfig)
        .then(() => {})
        .catch(() => {});
    },
  },
};
</script>
