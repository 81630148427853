<!-- 비밀코드 입력시 먼저 비밀코드 테이블에 등록
      auth에 리스트들을 받아와서 없는 경우 추가하게 하기 
      작성시 먼저 비밀코드 테이블이 생성및 등록
      그후 해당 비밀코드 테이블의 id와 auth리스트들을 중복해서 secret_auth DB에 저장 
      secret_code_auth 테이블에 각각 값들을 저장한다.
      -->
<template>
  <div class="problem">
    <AdminPanel>
      <el-form ref="form" :model="form" label-position="top" label-width="70px">
        <el-row :gutter="20">
          <el-col  :xs="24" :sm="24" :md="18" :lg="18"  >
            <el-form-item label="고유 그룹명 (영어소문자)" required for="email">
              <el-input
                id="email"
                v-if="!isEdit"
                placeholder="예시) ssafy"
                v-model="form.router_name"
              ></el-input>
              <el-input
                v-else
                disabled
                placeholder="예시) ssafy"
                v-model="form.router_name"
              >
              </el-input>
            </el-form-item>
          </el-col>
        <!-- <el-col :span="18">
            <el-form-item label="게시판 식별 주소(각 용도별 게시판 분기를 위한 용도)" for="company">
              <el-input
                id="company"
                v-if="!isEdit"
                placeholder="DB에 저장할 파라미터 (ex test-review-miracom - 미라콤 리뷰 게시판)"
                v-model="form.company"
              ></el-input>
              <el-input
              id="company"
                v-else
                placeholder="DB에 저장할 파라미터 (ex test-review-miracom - 미라콤 리뷰 게시판)"
                v-model="form.company"
              >
              </el-input>
            </el-form-item>
          </el-col> -->
        </el-row>

        <el-row :gutter="20">
          <el-col  :xs="24" :sm="24" :md="18" :lg="18"  >
            <el-form-item label="User가 입력해야 하는 비밀 코드" required for="code">
              <el-input
                id="code"
                v-if="!isEdit"
                placeholder="예시) ssafy4912@"
                v-model="form.code"
              ></el-input>
              <el-input v-else v-model="form.code" disabled> </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col  :xs="24" :sm="24" :md="18" :lg="18"  >
            <el-form-item label="관리자에게 표시될 그룹 이름 (한글가능)" required for="groupName">
              <el-input
                id="groupName"
                v-if="!isEdit"
                placeholder="예시) SSAFY 전용"
                v-model="form.group"
              ></el-input>
              <el-input v-else v-model="form.group"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col  :xs="24" :sm="24" :md="9" :lg="9"  >
            <el-form-item label="옵션1) 이메일 조건" for="email">
              <el-input
                id="email"
                v-if="!isEdit"
                placeholder="예시) @samsung.com"
                v-model="form.verifyEmail"
              ></el-input>
              <el-input
                v-else
                placeholder="에시) @samsung.com"
                v-model="form.verifyEmail"
              >
              </el-input>
            </el-form-item>
          </el-col>

          <el-col  :xs="24" :sm="24" :md="9" :lg="9"  >
            <el-form-item label="옵션2) 아이디 접두사 조건" for="email">
              <el-input
                id="email"
                v-if="!isEdit"
                placeholder="예시) miracom_"
                v-model="form.verify_username"
              ></el-input>
              <el-input
                v-else
                placeholder="예시) miracom_"
                v-model="form.verify_username"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col  :xs="24" :sm="24" :md="8" :lg="8"  >
            <el-form-item label="로고 이미지 업로드 (.svg 로고파일)" for="code">
              <label class="file-select">
                <span class="select-button">
                  <span>Select File</span>
                </span>
                <input type="file" @change="handleFileChange" accept=".svg"/>
              </label>
            </el-form-item>
            <el-form-item v-if="form.logo.name" label="로고 삭제">
              <label class="file-select">
                <span class="delete-button">
                  <span>Delete Logo</span>
                </span>
                <button @click="deleteLogoClicked" style="display:none"/>
              </label>
            </el-form-item>
          </el-col>

          <el-col  :xs="24" :sm="24" :md="5" :lg="5"  >
            <el-form-item v-if="form.logo.name" label="미리보기" for="code">
            </el-form-item>
            <div id="preview"></div>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col  :xs="24" :sm="24" :md="18" :lg="18"  >
            <el-form-item label="사용할 게시판 선택" for="qna">
              <div>
                <el-switch
                id="qna"
                v-model="form.is_qna_visible"
                inactive-text="질문 게시판 활성화"
              >
              </el-switch>
              </div>
              <div style="margin-top:15px">
                <el-switch
                id="notice"
                v-model="form.is_notice_visible"
                inactive-text="공지 게시판 활성화"
              >
              </el-switch>
              </div>
            <div style="margin-top:15px">
                <el-switch
                id="notice"
                v-model="form.is_contest_visible"
                inactive-text="집합교육 활성화"
              >
              </el-switch>
            </div>

            <div style="margin-top:15px">
                <el-switch
                id="notice"
                v-model="form.is_e_learning_visible"
                inactive-text="E_Learning 활성화"
              >
              </el-switch>
            </div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item style="margin-top: 15px" label="권한 선택">
          <el-col :span="24">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                @change="clickCheckbox"
                v-for="auth in form.auth_list"
                :label="auth.id"
                :key="auth.title"
                >{{ auth.title }}</el-checkbox
              >
            </el-checkbox-group>
          </el-col>
        </el-form-item>

        <save @click.native="submit()">Save</save>
      </el-form>
    </AdminPanel>
  </div>
</template>

<script>
import AdminPanel from "@admin/components/AdminPanel.vue";
import api from "../../../api";
export default {
  name: "Problem",
  components:{
    AdminPanel
  },
  data() {
    return {
      // type
      isEdit: false,
      checkList: [],
      form: {
        checkList: [],
        code: "",
        group: "",
        verifyEmail: "",
        auth_list: ["all", "시작반 1", "시작반 2", "훈련반 1", "훈련반 2"],
        is_qna_visible: false,
        is_notice_visible: false,
        is_contest_visible:false,
        is_e_learning_visible:false,
        router_name: "",
        verify_username:"",
        logo: {
          content: "",
          original_name: "",
          name: "",
        },
        // company: ""
        logo: {
          content: "",
          original_name: "",
          name: "",
        },
      },
    };
  },
  async mounted() {
    const result = await api.getProblemStepList();

    // const result = await api.getMincodingAuthList();
    // consoz
    if (result && result.data.data.data.length) {
      this.form.auth_list = result.data.data.data;
      // console.log(this.form.auth_list);
    }
    // mounted에서 해야할 작업
    // 권한 선택 list 변경시키기
    if (this.$route.path.indexOf("edit") > 0) {
      this.isEdit = true;
      const result = await api.getSecretCodeAuth({
        auth_id: this.$route.params.id,
      });

      this.form.code = result.data.data.name;
      this.form["verifyEmail"] = result.data.data["verify_email"];
      this.form["group"] = result.data.data["group"];
      this.form["is_qna_visible"] = result.data.data["is_qna_visible"];
      this.form["is_notice_visible"] = result.data.data["is_notice_visible"];
      this.form["is_contest_visible"] = result.data.data["is_contest_visible"];
      this.form["is_e_learning_visible"] = result.data.data["is_e_learning_visible"];
      this.form["router_name"] = result.data.data["router_name"];
      this.form["verify_username"] = result.data.data["verify_username"];
      this.form["logo"]["original_name"] = result.data.data["logo_original_name"];
      this.form["logo"]["name"] = result.data.data["logo_name"];
      this.form["logo"]["content"] = result.data.data["logo_content"];

      if(this.form.logo){
        document.getElementById('preview').innerHTML = this.form.logo.content;
      }
      
      // this.form["company"] = result.data.data["company"];
      this.checkList = result.data.data.secret_code.map((li) => {
        return li.problem_step.id;
      });
      // console.log(this.checkList);

      // this.form.code = result.data.data.name
      // this.form.id = result.data.data.id
      // 내일 해야할것 비밀코드 수정및
      // 권한 리스트 수정하기
      // 해당 컬럼을 지우는 방식으로 진행
      // x표를 누르면 해당 auth_id의 컬럼을 지우는 방식
      // 위와같이 업데이트를 진행할것
      // 업데이트 후
      // 이제 유저 컬럼 추가하고
      // 유저 컬럼한곳에다가 데이터 작성하고 Enter 딱 눌렀을때
      // 컬럼에 권한의 이름 추가하고
      // 유저 프로필로부터 이름으로 해당 secert_code DB검색 넣어서 프로필에서 뿌려주기
      // 위와 같은 권한을 가지고 있기 때문에 해당 secret_code를 기반으로 권한 뿌리기

      //
    }
  },

  methods: {
    clickCheckbox(checked, e) {},
    changeCheckbox() {
      if (this.checkList.includes("all")) {
        this.checkList = ["all"];
      }
    },
    async submit() {
      if(!this.form.router_name){
        alert("고유 그룹명을 입력해주세요");
        return;
      }
      
      // 비밀코드
      const name = this.form.code;
      //secret_code로 넘겨야한다;
      if (!this.form.code) {
        alert("비밀 코드를 입력해주세요");
        return;
      }
      
      if(!this.form.group){
        alert("관리자에게 표시될 그룹 이름을 입력해주세요");
        return;
      }
      
      if (!this.checkList.length) {
        alert("권한을 부여해 주세요");
        return;
      }

      // const form = new FormData();
      // form.append("secret_code", this.form.code);
      // for(let i = 0; i < this.checkList.length; i++){
      //   form.append("auth", this.checkList[i]);
      // }

      // this.checkList
      if (this.isEdit) {
        // 수정 상태일때 권한 마다 요청을 N개 보낸다 (리스트 갯수대로 보내면 될듯)
        // 그러면 총 정보를 가지고있는 배열이 필요하다
        // 전부 가져와서
        // delete로 날리기
        // name 보내서 날리기
        const result = await api.updateSecretCodeAuth({
          secret_code: this.form.code,
          group: this.form.group,
          verify_email: this.form.verifyEmail,
          code: this.checkList,
          is_notice_visible: this.form.is_notice_visible,
          is_qna_visible: this.form.is_qna_visible,
          is_contest_visible: this.form.is_contest_visible,
          is_e_learning_visible : this.form.is_e_learning_visible ,
          router_name: this.form.router_name,
          verify_username: this.form.verify_username,
          logo:this.form.logo
          // company: this.form.company
        });
      } else {
        const result = await api.createSecretCodeAuth({
          secret_code: this.form.code,
          group: this.form.group,
          verify_email: this.form.verifyEmail,
          code: this.checkList,
          is_notice_visible: this.form.is_notice_visible,
          is_qna_visible: this.form.is_qna_visible,
          is_contest_visible: this.form.is_contest_visible,
          is_e_learning_visible : this.form.is_e_learning_visible ,
          router_name: this.form.router_name,
          verify_username: this.form.verify_username,
          logo:this.form.logo
          // company: this.form.company
        })
      }

      // 권한 리스트
      // 배열로 데이터를 보낸
      this.$router.push("/admin/secret_code_auth");
    },
    handleFileChange(event) {
      let logo = event.target.files[0];

      if(logo.name.substring(logo.name.lastIndexOf('.'), logo.name.length).toLowerCase() !== ".svg"){
        this.$error("Upload Only SVG Format");
        return;
      }


      if(logo){
        this.form.logo.name = this.getLogoNameWithDate(logo);
        this.form.logo.original_name = logo.name;

        let reader = new FileReader();
        reader.onload = (e) =>{
          document.getElementById('preview').innerHTML = reader.result;
          this.form.logo.content = reader.result;
        };

        reader.readAsText(logo);
      }
    },
    getLogoNameWithDate(logo){
      let name = logo.name.substring(0, logo.name.lastIndexOf('.'));
      let extension = logo.name.substring(logo.name.lastIndexOf('.'), logo.name.length);
      const today = new Date();
      name = name + today.getFullYear() + (today.getMonth()+1) + today.getDate() + 
              today.getHours() + "H" + today.getMinutes() + "M" + today.getSeconds() + "S";
      name += extension;
      return name;
    },
    deleteLogoClicked(){
      this.form.logo = {
          content: "",
          original_name: "",
          name: "",
      };
      document.getElementById('preview').innerHTML = this.form.logo.content;
    },
  },
};
</script>

<style lang="less" scoped>
.problem {
  .difficulty-select {
    width: 120px;
  }
  .spj-radio {
    margin-left: 10px;
    &:last-child {
      margin-right: 20px;
    }
  }
  .input-new-tag {
    width: 78px;
  }
  .button-new-tag {
    height: 24px;
    line-height: 22px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .tags {
    .el-tag {
      margin-right: 10px;
    }
  }
  .accordion {
    margin-bottom: 10px;
  }
  .add-samples {
    width: 100%;
    background-color: #fff;
    border: 1px dashed #aaa;
    outline: none;
    cursor: pointer;
    color: #666;
    height: 35px;
    font-size: 14px;
    &:hover {
      background-color: #f9fafc;
    }
    i {
      margin-right: 10px;
    }
  }
  .add-sample-btn {
    margin-bottom: 10px;
  }
}
</style>

<style>

#preview >svg{
  max-width: 300px;
  max-height: 300px;
}

.dialog-compile-error {
  width: auto;
  max-width: 80%;
  overflow-x: scroll;
}
.file-select > .select-button {
  padding: 1rem;

  color: white;
  /* background-color: #2EA169; */
  background-color: #3399FF;

  border-radius: .3rem;

  text-align: center;
  font-weight: bold;
}

.file-select > .delete-button {
  padding: 1rem;

  color: white;
  background-color: #808080;

  border-radius: .3rem;

  text-align: center;
  font-weight: bold;
}

/* Don't forget to hide the original file input! */
.file-select > input[type="file"] {
  display: none;
}
</style>
