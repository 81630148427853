<template>
  <div class="view">
    <AdminPanel title="집합교육 목록">
      <div slot="header">
        <el-input
          v-model="keyword"
          prefix-icon="el-icon-search"
          placeholder="Keywords"
        >
        </el-input>
      </div>
      <el-table
        empty-text="검색한 정보가 없습니다"
        v-loading="loading"
        element-loading-text="loading"
        ref="table"
        :data="contestList"
        style="width: 100%"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <p>Start Time: {{ props.row.start_time | localtime }}</p>
            <p>End Time: {{ props.row.end_time | localtime }}</p>
            <p>Create Time: {{ props.row.create_time | localtime }}</p>
            <p>Creator: {{ props.row.created_by.username }}</p>
          </template>
        </el-table-column>
        <el-table-column
          v-if="!$store.state.isMobile"
          prop="id"
          width="80"
          label="ID"
        >
        </el-table-column>
        <el-table-column prop="title" min-width="200" label="제목">
        </el-table-column>
        <!--
        <el-table-column label="Rule" width="130">
          <template slot-scope="scope">
            <el-tag type="gray">{{ scope.row.rule_type }}</el-tag>
          </template>
        </el-table-column>
        -->
        <!--        <el-table-column v-if="!$store.state.isMobile" label="공개 / 비공개" width="180">-->
        <!--          <template slot-scope="scope">-->
        <!--            <el-tag-->
        <!--              :type="-->
        <!--                scope.row.contest_type === 'Public' ? 'success' : 'primary'-->
        <!--              "-->
        <!--            >-->
        <!--              {{-->
        <!--                scope.row.contest_type === "Password Protected"-->
        <!--                  ? "Locked"-->
        <!--                  : scope.row.contest_type-->
        <!--              }}-->
        <!--            </el-tag>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column v-if="$store.state.isMobile" label="상태" width="130">
          <template v-if="$store.state.isMobile" slot-scope="scope">
            <el-tag
              size="mini"
              :type="
                scope.row.status === '-1'
                  ? 'danger'
                  : scope.row.status === '0'
                  ? 'success'
                  : 'primary'
              "
            >
              {{ scope.row.status | contestStatus }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column v-else label="상태" width="130">
          <template slot-scope="scope">
            <el-tag
              :type="
                scope.row.status === '-1'
                  ? 'danger'
                  : scope.row.status === '0'
                  ? 'success'
                  : 'primary'
              "
            >
              {{ scope.row.status | contestStatus }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="100" label="표시여부">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.visible"
              active-text=""
              inactive-text=""
              @change="handleVisibleSwitch(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>

        <!--        <el-table-column v-if="!$store.state.isMobile" width="140" align="center" label="강사님 코드 공개">-->
        <!--          <template slot-scope="scope">-->
        <!--            <el-switch-->
        <!--              v-model="scope.row['teacher_code_visible']"-->
        <!--              active-text=""-->
        <!--              inactive-text=""-->
        <!--              @change="handleTeacherCodeVisible(scope.row)"-->
        <!--            >-->
        <!--            </el-switch>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column width="300" label="세부설정">
          <div slot-scope="scope">
            <icon-btn
              name="강의 편집"
              icon="edit"
              @click.native="goEdit(scope.row.id)"
            ></icon-btn>
            <icon-btn
              name="문제 목록"
              icon="s-order"
              @click.native="goContestProblemList(scope.row.id)"
            ></icon-btn>
            <icon-btn
              name="강사님 자료"
              icon="suitcase"
              @click.native="goContestAnnouncement(scope.row.id)"
            ></icon-btn>
            <icon-btn
              v-if="!$store.state.isMobile"
              icon="download"
              name="정답 소스코드 다운로드"
              @click.native="openDownloadOptions(scope.row.id)"
            ></icon-btn>
            <icon-btn
              v-if="scope.row.visible"
              icon="link"
              name="해당 페이지로 이동"
              @click.native="goHompage(scope.row)"
            ></icon-btn>
            <icon-btn
              v-if="isAdminRole"
              icon="delete"
              name="집합교육 삭제"
              @click.native="deleteContest(scope.row)"
            ></icon-btn>
          </div>
        </el-table-column>
      </el-table>
      <div class="panel-options">
        <el-pagination
          class="page"
          layout="prev, pager, next"
          @current-change="currentChange"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :total="total"
        >
        </el-pagination>

        <el-button
          v-if="isAdminRole"
          @click="contestVisible = !contestVisible"
          size="small"
          type="primary"
          >집합교육 복사</el-button
        >
      </div>
    </AdminPanel>
    <el-dialog
      title="정답 소스코드 다운로드"
      width="30%"
      :visible.sync="downloadDialogVisible"
    >
      <el-switch
        v-model="excludeAdmin"
        active-text="관리자가 제출한 소스코드 제외"
      ></el-switch>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="downloadSubmissions"
          >다운로드</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="복사할 집합교육 선택"
      top="50px"
      :width="$store.state.isMobile ? '100vw' : '50%'"
      :visible.sync="contestVisible"
      @close-on-click-modal="false"
    >
      <!-- :contestID="contestId"
        @on-change="getProblemList" -->
      <add-contest @on-change="getContestListEmit"></add-contest>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api.js";
import utils from "@/utils/utils";
import { CONTEST_STATUS_REVERSE } from "@/utils/constants";
import AdminPanel from "@admin/components/AdminPanel.vue";
import { mapGetters } from "vuex";

import AddContest from "./AddContest.vue";
import IconBtn from "@admin/components/btn/IconBtn";
export default {
  name: "ContestList",
  data() {
    return {
      pageSize: 10,
      total: 0,
      contestList: [],
      keyword: "",
      loading: false,
      excludeAdmin: true,
      currentPage: this.$route.query.page ? +this.$route.query.page : 1,
      currentId: 1,
      downloadDialogVisible: false,
      contestVisible: false,
      pageSize: 10,
      total: 0,
    };
  },
  components: { AdminPanel, AddContest },
  mounted() {
    this.getContestList(this.currentPage);
  },
  filters: {
    contestStatus(value) {
      return CONTEST_STATUS_REVERSE[value].name;
    },
  },

  methods: {
    // 페이지 번호 콜백 전환
    currentChange(page) {
      this.currentPage = page;
      this.getContestList(page);
      this.$router.push({
        path: `contest`,
        query: { page: this.currentPage },
      });
    },
    async getContestList(page) {
      this.loading = true;
      await api
        .getContestList((page - 1) * this.pageSize, this.pageSize, this.keyword)
        .then(
          (res) => {
            this.loading = false;
            this.total = res.data.data.total;
            this.contestList = res.data.data.results;
          },
          (res) => {
            this.loading = false;
          }
        );
    },
    openDownloadOptions(contestId) {
      this.downloadDialogVisible = true;
      this.currentId = contestId;
    },
    downloadSubmissions() {
      let excludeAdmin = this.excludeAdmin ? "1" : "0";
      let url = `/admin/download_submissions?contest_id=${this.currentId}&exclude_admin=${excludeAdmin}`;
      utils.downloadFile(url);
    },
    async handleTeacherCodeVisible(row) {
      await api.changeTeacherCodeVisible(row.id, row["teacher_code_visible"]);
      this.getContestList(this.currentPage);
    },
    goEdit(contestId) {
      this.$router.push({
        name: "edit-contest",
        params: { contestId },
        query: { page: this.currentPage },
      });
    },
    // goContestAnnouncement(contestId) {
    //   this.$router.push({
    //     name: "admin-contest-announcement",
    //     params: { contestId },
    //   });
    // },
    // goContestProblemList(contestId) {
    //   this.$router.push({
    //     name: "admin-contest-problem-list",
    //     params: { contestId },
    //   });
    // },
    // 페이지 번호 콜백 전환
    currentChange(page) {
      this.currentPage = page;
      this.getContestList(page);
      this.$router.push({
        path: `contest`,
        query: { page: this.currentPage },
      });
    },
    async getContestList(page = 1) {
      this.loading = true;
      await api
        .getContestList((page - 1) * this.pageSize, this.pageSize, this.keyword)
        .then(
          (res) => {
            this.loading = false;
            this.total = res.data.data.total;
            this.contestList = res.data.data.results;
          },
          (res) => {
            this.loading = false;
          }
        );
    },
    openDownloadOptions(contestId) {
      this.downloadDialogVisible = true;
      this.currentId = contestId;
    },
    downloadSubmissions() {
      let excludeAdmin = this.excludeAdmin ? "1" : "0";
      let url = `/admin/download_submissions?contest_id=${this.currentId}&exclude_admin=${excludeAdmin}`;
      utils.downloadFile(url);
    },
    async handleTeacherCodeVisible(row) {
      await api.changeTeacherCodeVisible(row.id, row["teacher_code_visible"]);
      this.getContestList(this.currentPage);
    },
    goEdit(contestId) {
      this.$router.push({
        name: "edit-contest",
        params: { contestId },
        query: { page: this.currentPage },
      });
    },
    goContestAnnouncement(contestId) {
      this.$router.push({
        name: "admin-contest-announcement",
        params: { contestId },
        query: { page: this.currentPage },
      });
    },
    goContestProblemList(contestId) {
      this.$router.push({
        name: "admin-contest-problem-list",
        params: { contestId },
        query: { page: this.currentPage },
      });
    },
    handleVisibleSwitch(row) {
      api.editContest(row);
    },
    page() {
      if (this.$route.query.page) {
        this.currentPage = +this.$route.query.page;
      } else {
        this.currentPage = 1;
      }
      this.getContestList(this.currentPage);
    },
    goHompage(row) {
      window.open(`${window.location.origin}/enterprise/contest/${row.secret_code.router_name}/${row.id}`);
    },
    copyContest(contest_id) {
      api.copyContest(1);
    },

    getProblemList(page = 1) {
      this.loading = true;
      let funcName =
        this.routeName === "problem-list"
          ? "getProblemList"
          : "getContestProblemList";
      let params = {
        limit: this.pageSize,
        offset: (page - 1) * this.pageSize,
        keyword: this.keyword,
        contest_id: this.contestId,
      };
      api[funcName](params).then(
        (res) => {
          this.loading = false;
          this.total = res.data.data.total;
          for (let problem of res.data.data.results) {
            problem.isEditing = false;
          }
          this.problemList = res.data.data.results;
        },
        (res) => {
          this.loading = false;
        }
      );
    },
    currentChange(page) {
      this.currentPage = page;
      this.getContestList(page);
      this.$router.push({
        query: { page: this.currentPage },
      });
    },
    getContestListEmit() {
      this.contestVisible = false;
      this.getContestList();
    },
    async deleteContest(params) {
      const result = confirm(
        `${params.title} \n해당 집합교육을 지우시겠습니까? `
      );
      if (result === true) {
        // 집합교육 삭제 기능 추가하기
        await api.deleteContest(params.id);
        this.getContestList(this.currentPage);
      }
    },
  },
  computed: {
    ...mapGetters(["user", "isSuperAdmin", "hasProblemPermission", "isManager", "isAdminRole"]),
  },

  watch: {
    keyword() {
      this.currentChange(1);
    },
    "$route.query": function () {
      this.page();
    },
  },
};
</script>

<style scoped>
/* .panel-options{
  position: relative;
} */
</style>
