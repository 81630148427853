var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-container',{staticClass:"board-container"},[_c('el-header',[_c('span',[_vm._v("시험후기")]),_c('el-input',{staticClass:"search-input",attrs:{"prefix-icon":"el-icon-search","placeholder":"제목 / 내용 / 이름 검색"},on:{"change":_vm.chagePage},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1),_c('el-main',[_c('el-table',{attrs:{"border":"","data":_vm.data,"empty-text":"검색한 정보가 없습니다","header-cell-style":_vm.cellStyle,"row-class-name":"myrow"},on:{"cell-click":_vm.detail}},[_c('el-table-column',{style:({ backgrondColor: 'rgb(252, 230, 190)' }),attrs:{"prop":"id","align":"center","width":_vm.$store.state.isMobile ? '70px' :'140px',"label":"글 번호"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"board-line"},[_vm._v(" "+_vm._s(scope.row.id)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"title","label":"제목","min-width":_vm.$store.state.isMobile ? '200px' :'400px',"header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
              scope.row.flag == 3 &&
              _vm.user.profile &&
              (_vm.isAdminRole || _vm.isManager ||
                scope.row.created_by.id == _vm.user.profile.user.id)
            )?_c('div',{staticClass:"board-line"},[_vm._v(" "+_vm._s(scope.row.title)),(
                scope.row.board.length && _vm.getCommentCount(scope.row.board)
              )?_c('span',[_vm._v("["+_vm._s(_vm.getCommentCount(scope.row.board))+"]")]):_vm._e(),_vm._v("🔒 ")]):(scope.row.flag == 3)?_c('div',{staticClass:"board-line"},[_vm._v(" 비공개 글입니다. 🔒 ")]):_c('div',{staticClass:"board-line"},[_vm._v(" "+_vm._s(scope.row.title)+" "),(
                scope.row.board.length && _vm.getCommentCount(scope.row.board)
              )?_c('span',{staticClass:"board-line"},[_vm._v("["+_vm._s(_vm.getCommentCount(scope.row.board))+"]")]):_vm._e()])]}}])}),_c('el-table-column',{attrs:{"prop":"real_name","label":"작성자 (ID)","min-width":_vm.$store.state.isMobile ? '100px' :'70px',"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"board-line"},[_vm._v(" "+_vm._s(scope.row["created_by"]["real_name"])+" ")]),_c('div',{staticClass:"board-line"},[_vm._v(" ("+_vm._s(scope.row["created_by"]["username"])+") ")])]}}])}),_c('el-table-column',{attrs:{"prop":"created_time","label":"작성 날짜","min-width":_vm.$store.state.isMobile ? '100px' :'70px',"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"board-line"},[_vm._v(" "+_vm._s(scope.row.created_time && _vm.toLocal(scope.row.created_time))+" ")]),_c('div',{staticClass:"board-line"},[_vm._v(" ("+_vm._s(scope.row.created_time && _vm.toLocalTime(scope.row.created_time))+") ")])]}}])})],1)],1),_c('el-footer',[_c('div',{staticClass:"page-wrapper"},[_c('el-pagination',{staticClass:"page",attrs:{"layout":"prev, pager, next","page-size":10,"current-page":_vm.currentPage,"total":_vm.total},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event},"current-change":_vm.chagePage}}),_c('el-button',{staticClass:"write-btn",attrs:{"size":_vm.$store.state.isMobile ?'mini':'medium',"type":"primary"},on:{"click":_vm.write}},[_vm._v("글쓰기")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }