<template>
    <div class="co-text" style="word-break: break-all;">
      {{ changeCommentText(comment) }}
    </div>

</template>

<script>
export default {
  props: ["comment"],
  methods: {
     changeCommentText(comment){
       return comment.replace(/<br\s*\/?>/gm, "\n");
    }
  }
};
</script>

<style>
.co-text{
    white-space: pre-line;
}
</style>