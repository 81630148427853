<template>
  <div class="panel" :class="{ small: small }">
    <header>
      <div class="title">

        <template v-if="$slots.title">
          <slot name="title"></slot>
        </template>
        <template v-else>
          <el-popover trigger="hover">
            <p>회원 ID를 클릭하면 회원정보를 변경할 수 있습니다.</p>
            <!-- <i slot="reference" class="el-icon-fa-exclamation-circle"></i> -->
          </el-popover>
          {{ title }}
        </template>
      </div>

      <div class="header_right">
        <slot name="header"></slot>
      </div>
    </header>

    <div class="body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserPanel",
  props: {
    title: {
      type: String,
      required: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped lang="less">
.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  &.small {
    max-width: 830px;
    min-width: 700px;
    margin-left: 20px;
    margin-top: 10px;
  }
  header {
    position: relative;
    z-index: 10;
    height: 50px;
    > .title {
      position: absolute;
      margin-left: 30px;
      // margin: 0;
      top: 55%;
      transform: translate(0, -50%);
      color: #333;
      border-color: #ddd;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: 0.025em;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
    > .header_right {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translate(0, -50%);
    }
  }
  .body {
    padding:10px 15px 15px 15px;
  }
}

// @media screen and (max-width: 900px) {
//   .panel {
//     header {
//       height: 100px;
//       > .title {
//         left: 205px;
//         top: 20%;
//       }
//       > .header_right {
//         left: 205px;
//         width: 40%;
//         top: 60%;
//       }
//     }
//   }
// }

@media screen and (max-width: 600px) {
  .title{
    margin-left: 50px !important;
  }
  .panel {
    header {
      > .header_right {
        width: 55%;
      }
    }
  }
}


</style>
<style lang="less">
.panel-options {
  background-color: transparent;
  position: relative;
  height: 50px;
  button {
    margin-top: 18px;
    margin-right: 10px;
  }
  > .page {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}

@media screen and (max-width: 700px) {
  .panel .body{
    padding-left: 3px;
    // padding:0 !important;
    width:100vw;
  }
}
</style>
