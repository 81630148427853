<template>
  <div class="problem">
    <AdminPanel title="문제 가져오기">
      <el-form
        ref="form"
        :model="problem"
        :rules="rules"
        label-position="top"
        label-width="70px"
      >
        <!-- 
        요구사항 정리 
        1. vuex를 통해 addProblemDialogVisible을 관리한다 
        3. 클릭시에 창 닫아버리기 
        4. 체크된 파일 표시 
        2. vuex에서 데이터 전송시에 router상에 있는 title의 제목을 바꿔버린다. 
        //체크된 파일을 표시해야하며 
        드래그시 순서를 바꿀수 있게까지 진행하면 완료된다. 
       -->
        <div
          v-if="
            problemStep.problemStepLevelDetailProblem['id'] ||
            problem.problem_id
          "
        >
          <el-button
            @click="deleteProblem"
            type="primary"
            icon="el-icon-delete"
            >{{
              problem.problem_id ||
              problemStep.problemStepLevelDetailProblem["_id"]
            }}</el-button
          >
        </div>

        <el-button
          v-else
          type="primary"
          size="small"
          icon="el-icon-plus"
          @click="CHANGE_PROBLEM_STEP_LEVEL_DETAIL_DIALOG(true)"
        >
          기존 문제 추가하기
        </el-button>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="17" :lg="17">
            <el-form-item prop="title" :label="'제목'" for="title">
              <el-input
                id="title"
                :placeholder="'제목'"
                v-model="problemStep.title"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="3" :lg="3">
            <el-form-item label="순서" required for="order">
              <el-input
                id="order"
                placeholder="순서"
                v-model="problem.order"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :xs="24" :sm="24" :md="18" :lg="18">
            <el-form-item label="내용" for="content">
              <el-input
                id="content"
                placeholder="내용"
                v-model="problem.content"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="6" :lg="6">
            <el-form-item :label="'보이기 설정'" for="visible">
              <el-switch
                id="visible"
                v-model="problem.visible"
                active-text=""
                inactive-text=""
              >
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :xs="24" :sm="24" :md="18" :lg="18">
            <el-form-item label="난이도" required for="difficulty">
              <el-select
                v-model="problem.difficulty"
                placeholder="Select"
                id="difficulty"
              >
                <el-option
                  v-for="item in difficulty"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-dialog
          title="문제 추가"
          top="50px"
          :width="$store.state.isMobile ? '100vw' : '80%'"
          :visible.sync="problemStep.problemStepLevelDetailDialogVisible"
          @close-on-click-modal="false"
        >
          <AddProblemStepLevelDetailProblem></AddProblemStepLevelDetailProblem>
        </el-dialog>

        <save @click.native="submit()">Save</save>
      </el-form>
    </AdminPanel>
  </div>
</template>

<script>
import api from "../../api";
import AddProblemStepLevelDetailProblem from "./AddProblemStepLevelDetailProblem.vue";
import { mapState, mapMutations } from "vuex";
import AdminPanel from "@admin/components/AdminPanel.vue";
export default {
  name: "problem-step-level-detail-create",
  components: {
    AddProblemStepLevelDetailProblem,
    AdminPanel,
  },
  data() {
    return {
      rules: {
        // title: {
        //   required: true,
        //   message: "제목은 필수입니다.",
        //   trigger: "blur"
        // }
      },
      problem: {
        problem_step_id: "",
        title: "",
        order: 1,
        content: "",
        content_detail: "",
        visible: false,
        problem_step_level_id: "",
        problem_step_level_detail_id: "",
        problem_id: "",
        difficulty: 1,
      },
      difficulty: [
        { label: "low", value: 1 },
        { label: "middle", value: 2 },
        { label: "high", value: 3 },
      ],
      title: "",
      mode: "",
      routeName: "",
      addProblemDialogVisible: false,
    };
  },
  async mounted() {
    this.problem.problem_step_id = this.$route.params.problem_step_id;
    this.problem.problem_step_level_id =
      this.$route.params.problem_step_level_id;
    this.problem.problem_step_level_detail_id =
      this.$route.params.problem_step_level_detail_id;
    this.routeName = this.$route.name;
    if (this.routeName === "problem_step_level_detail_edit") {
      this.mode = "edit";
    } else {
      this.mode = "add";
      this.CHANGE_TITLE("");
    }
    if (this.mode === "edit") {
      this.title = "level 수정하기";
      const step = await api.getProblemStepLevelDetail(
        this.problem.problem_step_level_detail_id
      );
      this.problem = step.data.data.data;
      this.problem.problem_id = this.problem.problem["id"];
      this.problemStep.title = this.problem.title;
    } else {
      this.title = "level 만들기";
      const { data } = await api.getProblemStepLevelDetailList(
        this.$route.params.problem_step_level_id
      );
      if (data && data["data"]["data"].length) {
        const list = data["data"]["data"];

        this.problem = {
          ...this.problem,
          order: list[list.length - 1].order + 1,
        };
      }
    }
  },
  computed: {
    ...mapState(["problemStep"]),
  },
  methods: {
    ...mapMutations([
      "CHANGE_PROBLEM_STEP_LEVEL_DETAIL_DIALOG",
      "CHANGE_PROBLEM_STEP_LEVEL_DETAIL_PROBLEM",
      "CHANGE_TITLE",
    ]),
    deleteProblem() {
      this.CHANGE_PROBLEM_STEP_LEVEL_DETAIL_PROBLEM([]);
      this.problem.problem_id = "";
    },
    async submit() {
      if (!this.problemStep.title.length) {
        this.$error("기존 문제를 추가해 주세요");
        return;
      }

      if (this.mode === "edit") {
        this.problem.problem_step_level_detail_id =
          this.$route.params.problem_step_level_detail_id;
        this.problem.title = this.problemStep.title;
        this.problem.problem_id = this.problem.problem.id;
        if (
          this.problemStep["problemStepLevelDetailProblem"] &&
          this.problemStep["problemStepLevelDetailProblem"].id
        ) {
          this.problem.problem_id =
            this.problemStep["problemStepLevelDetailProblem"].id;
        }
        if (!this.problem.problem_id) {
          this.$error("문제는 필수입니다.");
          return;
        }
        await api.editProblemStepLevelDetail(this.problem);
      } else {
        this.problem.problem = this.problemStep.problemStepLevelDetailProblem;
        this.problem.title = this.problemStep.title;
        this.problem.problem_id = this.problem.problem.id;
        await api.createProblemStepLevelDetail(this.problem);
      }
      this.CHANGE_PROBLEM_STEP_LEVEL_DETAIL_PROBLEM([]);
      this.$router.push({
        path: `/admin/problem-steps/${this.$route.params.problem_step_id}/level/${this.$route.params.problem_step_level_id}/detail`,
      });
    },
  },
};
</script>
