<template>
  <div>
    <div v-if="$route.path.indexOf('admin') < 0">
      <div v-if="host.indexOf('mir') > -1 || host.indexOf('dev-mir') > -1">
        <NavBarMiracom class="pc-nav"></NavBarMiracom>
        <MobileNavBarMiracom class="mobile-nav"></MobileNavBarMiracom>
      </div>
      <div v-else>
        <NavBar class="pc-nav"></NavBar>
        <MobileNavBar class="mobile-nav"></MobileNavBar>
      </div>
      <div class="content-app">
        <transition name="fadeInUp" mode="out-in">
          <router-view></router-view>
        </transition>
        <div class="footer">
          <!--
        <p v-html="website.website_footer"></p>
        -->
          <p>&copy; 2021 MINCODING ALL RIGHTS RESERVED</p>
          <!--
        <p>Powered by <a href="https://github.com/QingdaoU/OnlineJudge"></a>
          <span v-if="version">&nbsp; Version: {{ version }}</span>
        --></div>
      </div>
      <BackTop
        style="position: fixed; right: 0; left: 30px; width: 45px"
      ></BackTop>
    </div>
    <div v-else id="app">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import NavBar from "@oj/components/NavBar.vue";
import MobileNavBar from "@oj/components/MobileNavBar.vue";
import NavBarMiracom from "@oj/components/NavBarMiracom.vue";
import MobileNavBarMiracom from "@oj/components/MobileNavBarMiracom.vue";

export default {
  name: "app",
  components: {
    NavBar,
    MobileNavBar,
    NavBarMiracom,
    MobileNavBarMiracom,
  },
  data() {
    return {
      version: process.env.VERSION,
      host: null,
    };
  },
  created() {
    if (window.location.hostname) {
      this.host = window.location.hostname;
    }
    try {
      document.body.removeChild(document.getElementById("app-loader"));
    } catch (e) {}
  },
  mounted() {
    this.getWebsiteConfig();
    // console.log(this.$store.state.isMobile);

    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    // console.log("beforeDestroy...");
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    ...mapActions(["getWebsiteConfig", "changeDomTitle"]),
    handleResize(event) {
      // console.log(window.innerWidth);
      // console.log(this.$store.state.isMobile);

      if (window.innerWidth < 600 && this.$store.state.isMobile === false) {
        this.$store.commit("CHANGE_IS_MOBILE", true);
        // console.log(this.$store.state.isMobile);
      } else if (
        window.innerWidth > 600 &&
        this.$store.state.isMobile === true
      ) {
        this.$store.commit("CHANGE_IS_MOBILE", false);
        // console.log(this.$store.state.isMobile);
      }
      // if(window.innerWidth < 600px){

      // }
      // mobile 감지
      // this.width = window.innerWidth;
      // this.height = window.innerHeight;
      //
    },
  },
  computed: {
    ...mapState(["website"]),
  },
  watch: {
    website() {
      this.changeDomTitle();
    },
    $route() {
      this.changeDomTitle();
    },
  },
};
</script>

<style lang="less">
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  background-color: transparent;
  &:active,
  &:hover {
    outline-width: 0;
  }
}

.ck-content ol {
  padding-left: 20px !important;
}

.ck-content ul {
  padding-left: 20px !important;
}

.el-form-item__content .ck {
  line-height: normal !important;
}

// pre {
//   code[class|="language"] {
//     & {
//       display: block;
//       background: #f8f8f9;
//       padding: 10px;
//     }
//   }
// }

.content-app {
  padding-top: 60px;
}

.mobile-nav {
  display: none;
}
@media screen and (max-width: 1170px) {
  .content-app {
    margin-top: 120px;
    padding: 0 2%;
  }
}
// @media screen and (max-width: 700px) {
//   .content-app {
//     margin-top: 120px;
//   }
// }
// @media screen and (max-width: 618px) {
//   .content-app {
//     margin-top: 160px;
//   }
// }

@media screen and (max-width: 600px) {
  .content-app {
    padding: 0;
    margin: 0;
    margin-top: 59px;
  }
  .pc-nav {
    display: none;
  }
  .mobile-nav {
    display: block;
  }
}

@media screen and (min-width: 1200px) {
  .content-app {
    margin-top: 60px;
    padding: 0 2%;
  }
}

.footer {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  font-size: small;
}

.fadeInUp-enter-active {
  animation: fadeInUp 0.8s;
}

#app {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
// figure > img {
//   width: inherit;
// }
.bg-code {
  margin: 30px 0;
  margin-left: 20px;
}
main.el-main {
  padding-left: 0;
}

// .bg-code table {
// width: 97% !important;
// }
.ck > .ck-editor__editable {
  min-height: 300px;
}

@media screen and (max-width: 600px) {
  .bg-code {
    // padding:0 20px ;
    margin: 8px 20px;
    // margin-left: 20px;
  }
}
/* ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
    clear: both;
    margin: 0.9em 0;
    display: block;
    min-width: 15em;
}


</style>
