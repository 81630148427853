<template>
  <ul>
    <slot></slot>
  </ul>
</template>

<script>
  export default {
    name: 'VerticalMenu'
  }
</script>

<style scoped lang="less">
</style>
