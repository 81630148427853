<template>
  <div class="problem">
    <AdminPanel :title="title">
      <el-form
        ref="form"
        :model="problem"
        :rules="rules"
        label-position="top"
        label-width="70px"
      >
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="17" :lg="17">
            <el-form-item prop="title" :label="'제목'" for="title">
              <el-input
                id="title"
                :placeholder="'제목'"
                v-model="problem.title"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="3" :lg="3">
            <el-form-item label="순서" required for="order">
              <el-input
                id="order"
                placeholder="순서"
                v-model="problem.order"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :xs="24" :sm="24" :md="18" :lg="18">
            <el-form-item label="내용" required for="content">
              <el-input
                id="content"
                placeholder="내용"
                v-model="problem.content"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="6" :lg="6">
            <el-form-item :label="'보이기 설정'" for="visible">
              <el-switch
                id="visible"
                v-model="problem.visible"
                active-text=""
                inactive-text=""
              >
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-form-item label="상세 내용 (페이지 상단 노출) " required>
            <!-- <ckeditor :editor="editor" v-model="problem.content_detail" :config="editorConfig"></ckeditor> -->
            <VueCkEditor
              :data="problem.content_detail"
              @setContent="setProblemContentDetail"
            ></VueCkEditor>
            <!-- <ToastEditor
            :data="problem.content_detail"
            @setContent="setProblemContentDetail"
          /> -->
            <!-- <Simditor v-model="problem.content_detail" placeholder=""></Simditor> -->
          </el-form-item>
        </el-row>

        <save @click.native="submit()">Save</save>
      </el-form>
    </AdminPanel>
  </div>
</template>

<script>
import api from "../../api";
import Simditor from "@/pages/admin/components/Simditor";
// import toastEditorMixins from "@/pages/admin/components/toastEditorMixins";
import AdminPanel from "@admin/components/AdminPanel.vue";
import VueCkEditor from "../../components/VueCkEditor.vue";
// import ckEditorMixin from "@/pages/admin/components/ckEditorMixin.js"
export default {
  mixins: [],
  name: "problem-step-level-create",
  components: {
    Simditor,
    AdminPanel,
    VueCkEditor,
  },
  data() {
    return {
      rules: {
        title: {
          required: true,
          message: "제목은 필수입니다.",
          trigger: "blur",
        },
      },
      problem: {
        problem_step_id: "",
        title: "",
        order: 1,
        content: "",
        content_detail: "",
        visible: false,
        problem_step_level_id: "",
      },
      title: "",
      mode: "",
      routeName: "",
    };
  },
  async mounted() {
    this.problem.problem_step_id = this.$route.params.problem_step_id;
    this.problem.problem_step_level_id =
      this.$route.params.problem_step_level_id;
    this.routeName = this.$route.name;
    if (this.routeName === "problem_step_level_edit") {
      this.mode = "edit";
    } else {
      this.mode = "add";
    }
    if (this.mode === "edit") {
      this.title = "level 수정하기";

      const step = await api.getStepLevel(this.problem.problem_step_level_id);
      this.problem = step.data.data.data;
    } else {
      this.title = "level 만들기";

      const { data } = await api.getProblemStepLevelList(
        this.$route.params.problem_step_id
      );
      if (data && data["data"]["data"].length) {
        const list = data["data"]["data"];

        this.problem = {
          ...this.problem,
          order: list[list.length - 1].order + 1,
        };
      }
    }
  },
  methods: {
    async submit() {
      if (!this.problem.content.length) {
        this.$error("내용은 필수입니다.");
        return;
      }
      if (this.mode === "edit") {
        this.problem.problem_step_level_id =
          this.$route.params.problem_step_level_id;
        await api.editProblemStepLevel(this.problem);
      } else {
        await api.createProblemStepLevel(this.problem);
      }

      this.$router.push({
        path: `/admin/problem-steps/${this.$route.params.problem_step_id}`,
      });
    },
    setProblemContentDetail(e) {
      this.problem.content_detail = e;
    },
  },
};
</script>
