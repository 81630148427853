<template>
  <Button type="primary">
    <li @click.stop="handleClick" :class="{ disabled: disabled }">
      <slot></slot>
    </li>
  </Button>
</template>

<script>
import Emitter from "../mixins/emitter";

export default {
  name: "VerticalMenu-item",
  mixins: [Emitter],
  props: {
    route: {
      type: [String, Object],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      if (this.route) {
        this.dispatch("VerticalMenu", "on-click", this.route);
      }
    },
  },
};
</script>

<style scoped lang="less">
.disabled {
  opacity: 1;
  pointer-events: none;
  color: #ccc;
  &:hover {
    border-left: none;
    color: #ccc;
    background: #fff;
  }
}
/*Button:first-child {
  margin: 0px 0px 0px 10px;
}*/

Button {
  display:flex;
  li {
    list-style: none;
    color: #fff;
    text-align: left;
    padding: 5px;
    &:hover {
      opacity: 0.5;
    }
    & > .ivu-icon {
      font-size: 16px;
      margin-right: 8px;
    }
    &:last-child {
      border-bottom: none;
    }
  }
}

@media screen and (max-width: 1000px) {
}
@media screen and (max-width: 600px) {

}
</style>
