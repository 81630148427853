<template>
  <div class="comment">
    
    <VueCkEditor :data="input" @setContent="setInput" toolbar="comment"></VueCkEditor>
    <!-- <el-input
      type="textarea"
      :rows="3"
      autosize
      placeholder="댓글을 입력해주세요"
      v-model="input"
    ></el-input> -->
    <div class="comment-option-wrapper">
      <el-checkbox v-model="checked" class="secret-comment secret-checkbox">
      비밀댓글
    </el-checkbox>
      <el-button @click="postComment">입력</el-button>
    
    </div>
  </div>
</template>

<script>
import BoardHighlight from "@/pages/oj/components/BoardHighlight";
import VueCkEditor from "../../../admin/components/VueCkEditor.vue";
import api from "@oj/api";
import { mapState, mapActions } from "vuex";
export default {
  name: "Comment",
  components: {
    VueCkEditor,
  },
  data() {
    return {
      input: "",
      checked: "",
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  mounted() {},
  methods: {
    ...mapActions(["getBoard"]),
    async postComment() {
      if (!this.input.trim().length) {
        this.$error("댓글을 입력해주세요");
        return;
      }
      if (this.$route.params.contestID) {
          
          try {
            if (!this.isAdminRole) {
              await api.getBoardAuthCheck(this.$route.params.contestID);
            }
          }
          catch(error){
            return;
          }
      }
      // let str = this.input.replace(/ /g, "\u00a0");
      // str = str.replace(/(?:\r\n|\r|\n)/g, "<br/>");
      const parameter = this.$route.params["board_id"];
      await api.postComment(
        this.input,
        parameter,
        this.user.profile.user.id,
        this.checked ? "secret" : ""
      );
      this.getBoard(this.$route.params["board_id"]);
      this.input = "";
    },
    setInput(e) {
      this.input = e;
    },
  },
};
</script>

<style lang="less">
.comment {
  position: relative;
  text-align: center;
  margin-bottom: 40px;

  
  .el-input {
    input {
      border: none;
      border-top: 1px solid #eeeeee;
      min-height: 52px;
    }
  }
  .el-button {
    min-width: 8rem;
    // margin-left: auto;
    // margin-top: 10px;
    margin-left: 10px;
    // display: block;

    // height: 80% !important;
    // position: absolute;
    // right: 0;
    // bottom: 0;
  }
  .el-textarea__inner {
    min-height: 45px !important;
  }
  .secret-comment {
    // position: absolute;
    // right: 0px;
    // bottom: -28px;
  }
}
.el-textarea__inner {
  min-height: 45px !important;
}
.comment-option-wrapper{
  display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
}

.comment .ck > .ck-editor__editable{
  min-height: 150px !important;
}
</style>
