<template>
  <div style="background-color: white">
    <el-container class="create-container">
      <el-header>
        <div class="detail_title">영상 강의</div>
        <div class="detail-header-wrapper">
          <div></div>
          <div
            v-if="elearning.e_learning.flag === 3"
            class="detail_title"
            style="text-align: center;"
          >
            {{ elearning["e_learning"].title }} 🔒
          </div>
          <div
            v-else
            class="detail_title"
            style="text-align: center;"
          >
            {{ elearning["e_learning"].title }}
          </div>
          <div v-if="$store.state.isMobile" class="detail_subtitle">
            <div class="detail_subtitle-name">
              {{ elearning["e_learning"].real_name }}
            </div>
            <div class="detail_subtitle-date">
              {{ simpleToLocal(elearning["e_learning"].created_time) }}
            </div>
          </div>
          <div v-else class="detail_subtitle">
            <!-- <span> 작성자 : {{ elearning["e_learning"].real_name }} </span> -->
            <!-- <span> 작성일 : {{ toLocal(elearning.created_time) }} </span> -->
          </div>
        </div>
      </el-header>
      <hr />
      <el-main>
        <div class="detail_content">
          <div class="detail_optionbtn">
            <el-button
              :size="$store.state.isMobile ? 'mini' : 'medium'"
              @click="list"
              >목록</el-button
            >

            <el-button
              :size="$store.state.isMobile ? 'mini' : 'medium'"
              v-if="isAdminRole"
              @click="updateData"
              >수정</el-button
            >

            <el-button
              v-if="isAdminRole"
              :size="$store.state.isMobile ? 'mini' : 'medium'"
              @click="secretBoard(elearning.e_learning.flag)"
            >
              <span v-if="elearning.e_learning.flag === 3"> 공개 </span>
              <span v-else> 비공개 </span>
            </el-button>

            <el-button
              :size="$store.state.isMobile ? 'mini' : 'medium'"
              @click="deleteELearning"
              v-if="isAdminRole"
              >삭제</el-button
            >
          </div>
          <!-- <ToastViewer v-if="content" :content="content">
          </ToastViewer> -->

          <BoardHighlight
            class="bg-code ck-content"
            :code="content"
          ></BoardHighlight>
          <!-- <div v-html="content">

          </div> -->
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import time from "@/utils/time";
import api from "@oj/api";
// import Comment from "./Comment";
import { mapState, mapActions, mapGetters } from "vuex";
// import { mapState, mapActions, mapGetters } from "vuex";
import BoardHighlight from "@/pages/oj/components/BoardHighlight";
import ChangeCommentText from "@/pages/oj/components/ChangeCommentText";
// import ToastViewer from "@/pages/admin/components/ToastViewer"
import ToastViewerMixins from "@/pages/admin/components/toastViewerMixins.js";
// import toastViewerMixins from "@/pages/admin/components/toastViewerMixins.js";

export default {
  mixins: [ToastViewerMixins],
  name: "detail",
  data() {
    const index = this.$route.params.board_id;
    return {
      data: [],
      index: index,
      flag: false,
      input: "",
      content: "",
    };
  },
  computed: {
    ...mapGetters(["isAdminRole"]),
    ...mapState(["board", "user", "elearning"]),
  },
  props: ["code"],
  // components: { Comment, BoardHighlight },
  components: { BoardHighlight, ChangeCommentText },
  async mounted() {
    const test = await this.getELearning(this.$route.params["board_id"]).then(
      (li) => {
        // console.log(this.e_learning);
        this.content = this.elearning["e_learning"].content;
        // console.assert,
      }
    );


    document.querySelectorAll('oembed[url]').forEach( element => {
        // Create the <a href="..." class="embedly-card"></a> element that Embedly uses
        // to discover the media.
        let url = element.getAttribute("url");
        
        url= url.replace('watch?v=', 'embed/')

        if(url.indexOf("?") > -1){
          url = url +"&autoplay=1"
        }else{
          url = url +"?autoplay=1"
        }
        
  
        const iframe = document.createElement('iframe');
        
        iframe.setAttribute("src", url);
        iframe.setAttribute("autoplay", 1);
        iframe.setAttribute("allow","autoplay;")
        iframe.setAttribute("allowfullscreen",true);

    
        element.replaceWith(iframe);
        
        
        // endpoint = endpoint.replace('url', 'src');
        // endpoint = endpoint.replace('watch?v=', 'embed/');
        // endpoint = endpoint.replace('oembed', 'iframe');
      return element;
    });
  },

  methods: {
    ...mapActions(["getBoard", "getELearning"]),
    toLocal(data) {
      const result = time.utcToLocal(data, "YYYY년 M월 D일 HH시 mm분");
      return result;
    },
    commentToLocal(data) {
      const result = time.utcToLocal(data, "(YY-MM-DD HH:mm)");
      return result;
    },
    simpleToLocal(data) {
      const result = time.utcToLocal(data, "YYYY-MM-DD HH:mm");
      return result;
    },
    secretBoard(flag) {
      let type = "";
      if (flag === 3) type = "normal";
      else type = "secret";
      this.$Modal.confirm({
        content: `해당 게시글을 ${
          type === "normal" ? "공개" : "비공개"
        } 처리 하시겠습니까?`,
        onOk: async () => {
          await api.secretELearning(this.elearning.e_learning.id, type);
          await this.getELearning(this.$route.params["board_id"]);
        },
        onCancel: () => {},
      });
    },

    deleteELearning() {
      this.$Modal.confirm({
        content: "게시글을 삭제하시겠습니까?",
        onOk: async () => {
          await api.deleteELearning(this.$route.params["board_id"]);
          this.$router.push({
            path: `/e_learnings/${this.$route.params.board_name}/${this.$route.params.company}`,
          });
        },
        onCancel: () => {},
      });
    },
    updateData() {
      this.$router.push(
        `/e_learnings/${this.$route.params.board_name}/${this.$route.params.company}/${this.$route.params["board_id"]}/edit`
      );
    },
    list() {
      this.$router.push({
        path: `/e_learnings/${this.$route.params.board_name}/${this.$route.params.company}`,
      });
    },
  },
};
</script>
<style scoped lang="less">
.create-container {
  padding: 0 13% !important;
}

@media (max-width: 618px) {
  .create-container {
    padding: 0 !important;
  }
}
.el-header {
  background: white;
  padding: 40px 40px;
  height: 100% !important;
}
.el-footer {
  height: 100%;
}
.el-main {
  background: white;
  min-height: 30vh;
}
.el-footer {
  height: 100% !important;
  padding: 0 !important;
}

.el-input input {
  border-top: 1px solid #eeeeee;
}
body .el-container {
  margin-bottom: 40px;
  padding: 30px 20px;
  width: 90%;
  margin: 0px auto;
}
.detail-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.detail_title {
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 10px;
  word-break: break-all;
}

.detail_subtitle {
  margin-right: 10px;
  text-align: left;
  display: flex;
}
.detail_subtitle > span {
  margin-left: 30px;
}

// .detail_content {
//   // min-height: 100px;
//   // font-size: 16px;
//   // width: 100%;
// }
.detail_optionbtn {
  text-align: right;
}

.detail_comment {
  border: 1px solid #eeeeee;
  background-color: white;
  padding: 30px 24px;
  margin-bottom: 10px;
}
.detail_comment_button {
  margin-left: auto;
  font-size: 12px;
}
.detail_comment_font {
  font-size: 13px;
  font-weight: 600;
  margin-right: 20px;
}

.board_comment-wrapper {
  display: flex;
  flex-direction: column;
}
.board_comment {
  display: flex;
}
.board_comment button {
  padding: 11px !important;
}
.comment .el-textarea__inner {
  min-height: 45px !important;
}

// .comment-font {
//   // font-size: 14px;
//   // line-height: 20px;
// }

.comment-style {
  word-break: break-all;
}

.textarea {
  margin: 10px 0px;
}

.bg-code {
  margin-top: 20px;
}
@media screen and (max-width: 600px) {
  body .el-container {
    width: 100%;
    padding: 5px !important;
  }
  .create-container .el-header {
    padding: 15px 0 !important;
  }
  .detail_title {
    text-align: center;
    display: none;
    font-size: 16px;
  }
  .detail-header-wrapper {
    flex-direction: column;
  }

  .detail-header-wrapper .detail_title {
    margin-left: 0 !important;
    font-size: 16px;
    display: block;
  }
  .detail_subtitle {
    width: 100%;
    margin-right: 10px;
    justify-content: flex-end;
    align-items: center;
    // flex-direction: column;
  }
  .detail_subtitle-name {
    font-weight: bold;
  }

  .detail_subtitle-date {
    margin-left: 10px;
    font-size: 12px;
  }
  .detail_subtitle > span {
    margin: 0;
    font-size: 12px;
  }

  .el-main {
    padding: 0;
    padding-top: 10px;
  }
  .el-main img {
    max-width: 100vw;
  }

  .detail_comment {
    min-height: 110px;
    padding: 5px 10px;
  }
  .detail_comment_font {
    font-size: 14px;
    font-weight: 14px;
    display: flex;
    align-items: center;
  }
  .detail_comment_font > span {
    margin-left: 5px;
    font-weight: normal;
    font-size: 12px;
  }

  .board_comment {
    flex-wrap: wrap;
  }
  .board_comment button {
    padding: 7px 15px !important;
  }

  .button-wrapper {
    display: flex;
  }

  .el-footer {
    margin-top: 20px;
  }
  .button-wrapper > button {
    margin: 2px 0;
  }
}

.media{
    position: relative;
    padding-top: 50%;
    width: 100%;
    height: 0;
}

.media >iframe{
  border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>
