<template>
  <div class="view">
    <AdminPanel :title="`${problemList.title} - 문제 리스트`">
      <div slot="header">
        <!-- <el-input
          v-model="keyword"
          prefix-icon="el-icon-search"
          placeholder="Keywords"
        >
        </el-input> -->
      </div>
      <el-table
        v-loading="loading"
        element-loading-text="loading"
        row-key="id"
        :data="problemList['problem_step_level']"
        style="width: 100%"
      >
        <el-table-column v-if="!$store.state.isMobile" prop="id" label="id" width="180"> </el-table-column>
        <el-table-column prop="title" label="Tilte" min-width="150">
        </el-table-column>
        <el-table-column prop="content" min-width="200" label="Content"> </el-table-column>
        <el-table-column prop="order" label="Order"> </el-table-column>
        <!-- <el-table-column prop="problem_step_level" label="problem_step_level">
        </el-table-column> -->
        <el-table-column prop="difficulty" label="difficulty">
          <template slot-scope="scope">
            {{ changeDifficulty(scope.row.difficulty) }}
          </template>
        </el-table-column>
        <el-table-column width="100" prop="visible" label="보이기 설정">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.visible"
              active-text=""
              inactive-text=""
              @change="updateProblem(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="옵션" width="250">
          <div slot-scope="scope">
            <icon-btn
              name="레벨 수정"
              icon="edit"
              @click.native="handleEdit(scope.row)"
            ></icon-btn>
            <icon-btn
              icon="delete"
              name="문제 삭제"
              @click.native="handleDelete(scope.row)"
            ></icon-btn>
            <icon-btn
              icon="document-checked"
              name="기존 문제 수정"
              @click.native="handleEditProblem(scope.row)"
            ></icon-btn>
            <icon-btn
              icon="link"
              name="해당 페이지로 이동"
              @click.native="goHompage(scope.row)"
            ></icon-btn>

            <!-- <i class="el-icon-edit">aa</i> -->
            <!-- <el-icon-edit
              icon="list-ol"
              name="문제 목록"
              @click.native="gotDetail(scope.row)"
            ></el-icon-edit> -->
          </div>
        </el-table-column>
      </el-table>
    </AdminPanel>
    <div>
      <el-button plain type="primary" @click="addLevelDetail">추가</el-button>
    </div>
  </div>
</template>

<script>
import AdminPanel from "@admin/components/AdminPanel.vue";
import mixins from "./sortMixin";
import api from "../../api.js";
export default {
  mixins: [mixins],
  components:{
    AdminPanel
  },
  data() {
    return {
      loading: false,
      problemList: []
    };
  },
  mounted() {
    this.getProblemStepLevelDetailList();
  },
  methods: {
    getProblemStepLevelDetailList() {
      api.getStepLevel(this.$route.params.problem_step_level_id).then(res => {
        this.problemList = res.data.data.data;
      });
    },
    addLevelDetail() {
      this.$router.push({
        path: `/admin/problem-steps/${this.$route.params.problem_step_id}/level/${this.$route.params.problem_step_level_id}/detail/create`
      });
    },
    handleDelete(row) {
      let id = row.id;
      this.$confirm(
        "삭제 하시겠습니까? 관련 제출물도 삭제됩니다.",
        "문제 삭제",
        {
          type: "warning"
        }
      ).then(() =>{
        api.deleteProblemStepLevelDetail(id).then(res => {
          this.getProblemStepLevelDetailList();
        });
      })
    },
    handleEdit(row) {
      this.$router.push({
        path: `/admin/problem-steps/${this.$route.params.problem_step_id}/level/${this.$route.params.problem_step_level_id}/detail/${row.id}/edit`
      });
    },
    handleEditProblem(row) {
      this.$router.push(`/admin/problem/edit/${row.problem.id}`);
      // id를 받아서 문제 수정으로 이동시킨다
    },
    updateProblem(row) {
      let data = Object.assign({}, row);
      data = Object.assign({ problem_step_level_detail_id: row.id }, data);

      data.problem_id = row.problem["id"];

      api.editProblemStepLevelDetail(data);
    },
    changeDifficulty(difficulty) {
      if (difficulty === 1) return "Low";
      if (difficulty === 2) return "Mid";
      if (difficulty === 3) return "High";
    },
    goHompage(row) {
      window.open(`${window.location.origin}/problem/${row.problem._id}`);
    }
  }
};
</script>
