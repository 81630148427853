<template>
  <Row type="flex" :gutter="18">
    <Col :span="24">
      <Panel shadow>
        <div slot="title">{{$t('m.Problem_List')}}</div>
        <div slot="extra">
          <ul class="filter">
            <li>
              <Dropdown @on-click="filterByDifficulty">
                <span
                  >{{
                    query.difficulty === ""
                      ? this.$i18n.t("m.Difficulty")
                      : this.$i18n.t("m." + query.difficulty)
                  }}
                  <Icon type="arrow-down-b"></Icon>
                </span>
                <Dropdown-menu slot="list">
                  <Dropdown-item name="">{{ $t("m.All") }}</Dropdown-item>
                  <Dropdown-item name="Low">{{ $t("m.Low") }}</Dropdown-item>
                  <Dropdown-item name="Mid">{{ $t("m.Mid") }}</Dropdown-item>
                  <Dropdown-item name="High">{{ $t("m.High") }}</Dropdown-item>
                </Dropdown-menu>
              </Dropdown>
            </li>
            <li>
              <i-switch size="large" @on-change="handleTagsVisible">
                <span slot="open">{{ $t("m.Tags") }}</span>
                <span slot="close">{{ $t("m.Tags") }}</span>
              </i-switch>
            </li>
            <li>
              <Input
                v-model="query.keyword"
                @on-enter="filterByKeyword"
                @on-click="filterByKeyword"
                placeholder="키워드를 입력하세요"
                icon="ios-search-strong"
              />
            </li>
          </ul>
        </div>
        <Table
          style="width: 100%; font-size: 16px;"
          :columns="problemTableColumns"
          :data="problemList"
          :loading="loadings.table"
          disabled-hover
        ></Table>
      </Panel> 
      <Pagination
        :total="total"
        :page-size="limit"
        @on-change="pushRouter"
        :current.sync="query.page"
      ></Pagination>
    </Col>

  </Row>
</template>

<script>
import { mapGetters,mapState } from "vuex";
import api from "@oj/api";
import utils from "@/utils/utils";
import { ProblemMixin } from "@oj/components/mixins";
import Pagination from "@oj/components/Pagination";

export default {
  name: "ProblemList",
  mixins: [ProblemMixin],
  components: {
    Pagination
  },
  data() {
    return {
      tagList: [],
      problemTableColumns: [
        {
          title: "문제 번호",
          key: "_id",
          width: 100,
          align: 'center',
          render: (h, params) => {
            return h(
              "Button",
              {
                props: {
                  type: "text",
                  size: "small"
                },
                on: {
                  click: () => {
                    this.$router.push({
                      name: "problem-details",
                      params: { problemID: params.row._id }
                    });
                  }
                },
              },
              `${params.row._index + 1} 번`
            );
          }
        },
        {
          title: this.$i18n.t("m.Title"),
          width: 400,
          align: 'left',
          render: (h, params) => {
            return h(
              "Button",
              {
                props: {
                  type: "text",
                  size: "large"
                },
                on: {
                  click: () => {
                    this.$router.push({
                      name: "problem-details",
                      params: { problemID: params.row._id }
                    });
                  }
                },
              },
              params.row.title
            );
          }
        },
        {
          title: this.$i18n.t("m.Level"),
          align: "center",
          render: (h, params) => {
            let t = params.row.difficulty;
            let color = "blue";
            if (t === "Low") color = "green";
            else if (t === "High") color = "yellow";
            return h("tag",
                {
                  props: {
                    color: color
                  },
                },
                this.$i18n.t("m." + params.row.difficulty)
              )
          }
        },
        {
          title: this.$i18n.t("m.Total"),
          key: "submission_number",
          align: "center",
        },
        {
          title: this.$i18n.t("m.AC_Rate"),
          align: "center",
          render: (h, params) => {
            return h(
              "span",
              this.getACRate(
                params.row.accepted_number,
                params.row.submission_number
              )
            );
          }
        }
      ],
      problemList: [],
      limit: 20,
      total: 0,
      loadings: {
        table: true,
        tag: true
      },
      routeName: "",
      query: {
        keyword: "",
        difficulty: "",
        tag: "",
        page: 1
      }
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init(simulate = false) {
      this.routeName = this.$route.name;
      let query = this.$route.query;
      this.query.difficulty = query.difficulty || "";
      this.query.keyword = query.keyword || "";
      this.query.tag = query.tag || "";
      this.query.page = parseInt(query.page) || 1;
      if (this.query.page < 1) {
        this.query.page = 1;
      }
      if (!simulate) {
        this.getTagList();
      }
      this.getProblemList();
    },
    pushRouter() {
      this.$router.push({
        name: "problem-list",
        query: utils.filterEmptyValue(this.query)
      });
    },
    getProblemList() {
      let offset = (this.query.page - 1) * this.limit;
      this.loadings.table = true;
      api.getProblemList(offset, this.limit, this.query).then(
        res => {
          this.loadings.table = false;
          this.total = res.data.data.total;
          this.problemList = res.data.data.results;
          if (this.isAuthenticated) {
            this.addStatusColumn(
              this.problemTableColumns,
              res.data.data.results
            );
          }
        },
        res => {
          this.loadings.table = false;
        }
      );
    },
    getTagList() {
      api.getProblemTagList().then(
        res => {
          this.tagList = res.data.data;
          this.loadings.tag = false;
        },
        res => {
          this.loadings.tag = false;
        }
      );
    },
    filterByTag(tagName) {
      this.query.tag = tagName;
      this.query.page = 1;
      this.pushRouter();
    },
    filterByDifficulty(difficulty) {
      this.query.difficulty = difficulty;
      this.query.page = 1;
      this.pushRouter();
    },
    filterByKeyword() {
      this.query.page = 1;
      this.pushRouter();
    },
    handleTagsVisible(value) {
      if (value) {
        this.problemTableColumns.push({
          title: this.$i18n.t("m.Tags"),
          align: "center",
          render: (h, params) => {
            let tags = [];
            params.row.tags.forEach(tag => {
              tags.push(h("Tag", {}, tag));
            });
            return h(
              "div",
              {
                style: {
                  margin: "8px 0"
                }
              },
              tags
            );
          }
        });
      } else {
        this.problemTableColumns.splice(this.problemTableColumns.length - 1, 1);
      }
    },
    onReset() {
      this.$router.push({ name: "problem-list" });
    },
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    ...mapState(['utils'])
  },
  watch: {
    $route(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.init(true);
      }
    },
    isAuthenticated(newVal) {
      if (newVal === true) {
        this.init();
      }
    }
  }
};
</script>


<style scoped lang="less">
/*
.taglist-title {
  margin-left: -10px;
  margin-bottom: -10px;
}

.tag-btn {
  margin-right: 5px;
  margin-bottom: 10px;
}*/
</style>
