import { render, staticRenderFns } from "./Read.vue?vue&type=template&id=cb58e952&scoped=true"
import script from "./Read.vue?vue&type=script&lang=js"
export * from "./Read.vue?vue&type=script&lang=js"
import style0 from "./Read.vue?vue&type=style&index=0&id=cb58e952&prod&lang=less&scoped=true"
import style1 from "./Read.vue?vue&type=style&index=1&id=cb58e952&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb58e952",
  null
  
)

export default component.exports