import moment from "moment";
import types from "../types";
import api from "@oj/api";
import { CONTEST_STATUS, USER_TYPE, CONTEST_TYPE } from "@/utils/constants";

const state = {
  board: [],
  html: ''
};

const getters = {};

const mutations = {
  [types.POST_COMMENT](state, payload) {
    state.board = payload;
  },
  [types.SET_HTML](state,payload) {
    state.html = payload;
  }
};

const actions = {
  async getBoard({ commit }, payload) {
    const response = await api.getBoardDetail(payload);
    const result = Object.entries(response).find(el => el[0] === "data");

    commit(types.POST_COMMENT, result[1]["data"]);
  }
};

export default {
  state,
  mutations,
  getters,
  actions
};
