<template>
  <div class="view">
    <AdminPanel :title="`${problemList.title} - 레벨 리스트`">
      <!-- <div slot="header">
        <el-input
          v-model="keyword"
          prefix-icon="el-icon-search"
          placeholder="Keywords"
        >
        </el-input>
      </div> -->
      <el-table
        v-loading="loading"
        element-loading-text="loading"
        row-key="id"
        :data="problemList['problem_step']"
        style="width: 100%"
      >
        <el-table-column
          v-if="!$store.state.isMobile"
          prop="id"
          label="id"
          width="180"
        >
        </el-table-column>
        <el-table-column prop="title" label="Tilte" min-width="120">
        </el-table-column>
        <el-table-column prop="content" min-width="140" label="Content">
        </el-table-column>
        <el-table-column prop="order" label="Order"> </el-table-column>
        <!-- <el-table-column
        prop="problem_step"
        label="problem_step_level">
      </el-table-column> -->
        <el-table-column width="100" prop="visible" label="보이기 설정">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.visible"
              active-text=""
              inactive-text=""
              @change="updateProblem(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="옵션" width="250">
          <div slot-scope="scope">
            <icon-btn
              name="레벨 수정"
              icon="edit"
              @click.native="handleEdit(scope.row)"
            ></icon-btn>
            <icon-btn
              icon="s-order"
              name="step_level_detail로 이동"
              @click.native="gotDetail(scope.row)"
            ></icon-btn>
            <icon-btn
              icon="link"
              name="해당 페이지로 이동"
              @click.native="goHompage(scope.row)"
            ></icon-btn>
            <icon-btn
              icon="delete"
              name="레벨 삭제"
              @click.native="handleDelete(scope.row)"
            ></icon-btn>
          </div>
        </el-table-column>
      </el-table>
    </AdminPanel>
    <div>
      <el-button plain type="primary" @click="addStepLevel">추가</el-button>
    </div>
  </div>
</template>

<script>
import AdminPanel from "@admin/components/AdminPanel.vue";
import mixins from "./sortMixin";
import api from "../../api.js";
export default {
  mixins: [mixins],
  data() {
    return {
      loading: false,
      problemList: [],
    };
  },
  components: {
    AdminPanel,
  },
  mounted() {
    this.getProblemStepLevelList();
  },
  methods: {
    getProblemStepLevelList() {
      api.getProblemStep(this.$route.params.problem_step_id).then((res) => {
        this.problemList = res.data.data.data;
      });
    },
    addStepLevel() {
      this.$router.push({
        path: `/admin/problem-steps/${this.$route.params.problem_step_id}/create`,
      });
    },
    handleDelete(row) {
      let id = row.id;
      this.$confirm(
        "삭제 하시겠습니까? 관련 제출물도 삭제됩니다.",
        "레벨 삭제",
        {
          type: "warning",
        }
      ).then(() => {
        api.deleteProblemStepLevel(id).then((res) => {
          this.getProblemStepLevelList();
        });
      });
    },
    handleEdit(row) {
      // let id = row.id
      // row
      this.$router.push({
        path: `/admin/problem-steps/${this.$route.params.problem_step_id}/level/${row.id}/edit`,
      });
    },
    gotDetail(row) {
      this.$router.push({
        path: `/admin/problem-steps/${this.$route.params.problem_step_id}/level/${row.id}/detail`,
      });
    },
    updateProblem(row) {
      let data = Object.assign({}, row);
      data = Object.assign({ problem_step_level_id: row.id }, data);

      api.editProblemStepLevel(data);
    },
    goHompage(row) {
      window.open(
        `${window.location.origin}/problem-step/${row.problem_step}/level/${row.id}/detail`
      );
    },
  },
};
</script>
