<template>
  <el-row
    type="flex"
    :gutter="20"
    :style="
      $store.state.isMobile
        ? 'flex-direction:column-reverse; margin-top:60px;'
        : ''
    "
  >
    <el-col :xs="24" :sm="24" :md="10" :lg="8">
      <el-card
        class="admin-info"
        :style="$store.state.isMobile ? 'margin-top:30px;' : ''"
      >
        <el-row :gutter="20">
          <el-col :span="10">
            <img class="avatar" :src="profile.avatar" />
          </el-col>
          <el-col :span="14">
            <p class="admin-info-name">{{ user.username }}</p>
            <p>{{ user.admin_type }}</p>
          </el-col>
        </el-row>
        <hr />
        <div class="last-info">
          <p class="last-info-title">최근 로그인</p>
          <el-form label-width="80px" class="last-info-body">
            <el-form-item label="Time:">
              <span>{{ session.last_activity | localtime }}</span>
            </el-form-item>
            <el-form-item label="IP:">
              <span>{{ session.ip }}</span>
            </el-form-item>
            <el-form-item label="OS">
              <span>{{ os }}</span>
            </el-form-item>
            <el-form-item label="Browser:">
              <span>{{ browser }}</span>
            </el-form-item>
          </el-form>
        </div>
      </el-card>
      <AdminPanel
        :title="$t('m.System_Overview')"
        v-if="isAdminRole && !$store.state.isMobile"
      >
        <p>채점 서버: {{ infoData.judge_server_count }}</p>
        <p>
          HTTPS 상태:
          <el-tag :type="https ? 'success' : 'danger'" size="small">
            {{ https ? "Enabled" : "Disabled" }}
          </el-tag>
        </p>
        <p>
          강제 HTTPS:
          <el-tag :type="forceHttps ? 'success' : 'danger'" size="small">
            {{ forceHttps ? "Enabled" : "Disabled" }}
          </el-tag>
        </p>
        <p>
          CDN 호스트:
          <el-tag :type="cdn ? 'success' : 'warning'" size="small">
            {{ cdn ? cdn : "Not Use" }}
          </el-tag>
        </p>
      </AdminPanel>
    </el-col>

    <el-col :xs="24" :sm="24" :md="14" :lg="16" v-if="isAdminRole">
      <div class="info-container" v-if="!$store.state.isMobile">
        <info-card
          color="#909399"
          icon="el-icon-fa-users"
          message="Total Users"
          iconSize="30px"
          class="info-item"
          :value="infoData.user_count"
        ></info-card>
        <info-card
          color="#67C23A"
          icon="el-icon-fa-list"
          message="Today Submissions"
          class="info-item"
          :value="infoData.today_submission_count"
        ></info-card>
        <info-card
          color="#409EFF"
          icon="el-icon-fa-trophy"
          message="Recent Contests"
          class="info-item"
          :value="infoData.recent_contest_count"
        ></info-card>
      </div>
      <AdminPanel style="margin-top: 5px" title="릴리즈 노트">
        <!-- 여기다 공지사항 보여주기 -->

        <!-- <el-main> -->
        <el-table
          border
          @cell-click="detail"
          :data="data"
          empty-text="검색한 정보가 없습니다"
          :header-cell-style="cellStyle"
          row-class-name="myrow"
        >
          <el-table-column
            v-if="!$store.state.isMobile"
            prop="id"
            align="center"
            :width="$store.state.isMobile ? '30px' : '100px'"
            label="글 번호"
            :style="{ backgrondColor: 'rgb(252, 230, 190)' }"
          >
            <template slot-scope="scope">
              <div class="board-line">
                {{ scope.row.id }}
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="title"
            label="제목"
            header-align="center"
            :min-width="$store.state.isMobile ? '100px' : '180px'"
          >
            <template slot-scope="scope">
              <div
                class="board-line"
                v-if="
                  scope.row.flag == 3 &&
                  user.profile &&
                  (isAdminRole ||
                    scope.row.created_by.id == user.profile.user.id)
                "
              >
                {{ scope.row.title }}
                <span
                  v-if="
                    scope.row.notice.length && getCommentCount(scope.row.notice)
                  "
                  >[{{ getCommentCount(scope.row.notice) }}]</span
                >🔒
              </div>
              <div class="board-line" v-else-if="scope.row.flag == 3">
                비공개 글입니다. 🔒
              </div>
              <div class="board-line" v-else>
                {{ scope.row.title }}
                <span
                  class="board-line"
                  v-if="
                    scope.row.notice.length && getCommentCount(scope.row.notice)
                  "
                  >[{{ getCommentCount(scope.row.notice) }}]</span
                >
              </div>
            </template>
          </el-table-column>

          <!-- <el-table-column
          prop="real_name"
          label="작성자 (ID)"
          :min-width="$store.state.isMobile ? '100px' :'70px'"
          
          align="center"
        >
          <template slot-scope="scope">
            <div class="board-line">
              {{ scope.row["created_by"]["real_name"] }}
            </div>
            <div class="board-line">
              ({{ scope.row["created_by"]["username"] }})
            </div>
          </template>
        </el-table-column> -->

          <el-table-column
            prop="created_time"
            label="작성 날짜"
            :min-width="$store.state.isMobile ? '80px' : '50px'"
            align="center"
          >
            <template slot-scope="scope">
              <div v-if="$store.state.isMobile" class="board-line">
                {{
                  scope.row.created_time &&
                  toLocalTimeMobile(scope.row.created_time)
                }}
              </div>
              <div v-else>
                <div class="board-line">
                  {{
                    scope.row.created_time && toLocal(scope.row.created_time)
                  }}
                </div>
                <div class="board-line">
                  ({{
                    scope.row.created_time &&
                    toLocalTime(scope.row.created_time)
                  }})
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </AdminPanel>

      <!-- <span slot="title" v-loading="loadingReleases"
          >Release Notes
          <el-popover placement="right" trigger="hover">
            <i
              slot="reference"
              class="el-icon-fa-question-circle import-user-icon"
            ></i>
            <p>
              Please upgrade to the latest version to enjoy the new features.
            </p>
            <p>
              Reference:
              <a
                href="http://docs.onlinejudge.me/#/onlinejudge/guide/upgrade"
                target="_blank"
              >
                http://docs.onlinejudge.me/#/onlinejudge/guide/upgrade</a
              >
            </p>
          </el-popover>


          
        </span> -->
      <!-- 
        <el-collapse
          v-model="activeNames"
          v-for="(release, index) of releases"
          :key="'release' + index"
        >
          <el-collapse-item :name="index + 1">
            <template slot="title">
              <div v-if="release.new_version">
                {{ release.title }}
                <el-tag size="mini" type="success">New Version</el-tag>
              </div>
              <span v-else>{{ release.title }}</span>
            </template>
            <p>Level: {{ release.level }}</p>
            <p>Details:</p>
            <div class="release-body">
              <ul v-for="detail in release.details" :key="detail">
                <li v-html="detail"></li>
              </ul>
            </div>
          </el-collapse-item>
        </el-collapse> -->
    </el-col>
  </el-row>
</template>

<script>
import time from "@/utils/time";
import { mapGetters } from "vuex";
import browserDetector from "browser-detect";
import InfoCard from "@admin/components/infoCard.vue";
import api from "@admin/api";
import AdminPanel from "@admin/components/AdminPanel.vue";
export default {
  name: "dashboard",
  components: {
    InfoCard,
    AdminPanel,
  },
  data() {
    return {
      infoData: {
        user_count: 0,
        recent_contest_count: 0,
        today_submission_count: 0,
        judge_server_count: 0,
        env: {},
      },
      activeNames: [1],
      session: {},
      loadingReleases: true,
      releases: [],
      total: 0,
      data: [],
      currentPage: this.$route.query.page ? +this.$route.query.page : 1,
      keyword: "",
    };
  },
  mounted() {
    this.getBoardList();

    api.getDashboardInfo().then(
      (resp) => {
        this.infoData = resp.data.data;
      },
      () => {}
    );
    api.getSessions().then(
      (resp) => {
        this.parseSession(resp.data.data);
      },
      () => {}
    );
    api.getReleaseNotes().then(
      (resp) => {
        this.loadingReleases = false;
        let data = resp.data.data;
        if (!data) {
          return;
        }
        let currentVersion = data.local_version;
        data.update.forEach((release) => {
          if (release.version > currentVersion) {
            release.new_version = true;
          }
        });
        this.releases = data.update;
      },
      () => {
        this.loadingReleases = false;
      }
    );
  },
  methods: {
    async getBoardList() {
      const response = await api.getNoticeList({
        limit: 6,
        offset: (this.currentPage - 1) * 10,
        keyword: this.keyword,
        company: "MINCODING",
      });

      const data = Object.entries(response).find((el) => el[0] === "data");
      this.total = data[1]["data"]["total"];
      const result = data[1]["data"]["results"];
      this.data = result;

      api.getNoticeList().then((response) => {});
    },
    async chagePage() {
      this.getNoticeList();
      this.$router.push({
        path: `/announcement/${this.$route.params.board_name}`,
        query: { page: this.currentPage },
      });
    },

    detail(id, column, cell, event) {
      if (
        this.isAdminRole || this.isManager ||
        (this.user.profile.user &&
          id.created_by.id === this.user.profile.user.id) ||
        (id.flag !== 3 && column.property === "title")
      ) {
        this.$router.push({
          path: `/announcement/mincoding/${id.id}`,
          query: { page: this.currentPage },
        });
      }
    },
    page() {
      if (this.$route.query.page) {
        this.currentPage = +this.$route.query.page;
      } else {
        this.currentPage = 1;
      }
      this.getBoardList();
    },
    cellStyle() {
      return {
        background: "#409eff",
        color: "white",
      };
    },
    parseSession(sessions) {
      let session = sessions[0];
      if (sessions.length > 1) {
        session = sessions
          .filter((s) => !s.current_session)
          .sort((a, b) => {
            return a.last_activity < b.last_activity;
          })[0];
      }
      this.session = session;
    },
    toLocalTime(data) {
      const result = time.utcToLocal(data, "HH시 mm분");
      return result;
    },
    toLocal(data) {
      const result = time.utcToLocal(data, "YYYY년 M월 D일");

      return result;
    },
    toLocalTimeMobile(data) {
      const result = time.utcToLocal(data, "YYYY-MM-DD HH시 mm분");
      return result;
    },
  },
  computed: {
    ...mapGetters(["profile", "user", "isAdminRole"]),
    cdn() {
      return this.infoData.env.STATIC_CDN_HOST;
    },
    https() {
      return document.URL.slice(0, 5) === "https";
    },
    forceHttps() {
      return this.infoData.env.FORCE_HTTPS;
    },
    browser() {
      let b = browserDetector(this.session.user_agent);
      if (b.name && b.version) {
        return b.name + " " + b.version;
      } else {
        return "Unknown";
      }
    },
    os() {
      let b = browserDetector(this.session.user_agent);
      return b.os ? b.os : "Unknown";
    },
  },
};
</script>

<style lang="less">
.admin-info {
  margin-bottom: 20px;
  &-name {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #409eff;
  }
  .avatar {
    max-width: 100%;
  }
  .last-info {
    &-title {
      font-size: 16px;
    }
    &-body {
      .el-form-item {
        margin-bottom: 5px;
      }
    }
  }
}

.info-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .info-item {
    flex: 1 0 auto;
    min-width: 200px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 900px) {
  .admin-info {
    padding-left: 20px;
  }
}
</style>
