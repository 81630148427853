<template>
  <textarea ref="editor"></textarea>
</template>

<script>
import Simditor from "tar-simditor";
import "tar-simditor/styles/simditor.css";
import "tar-simditor-markdown";
import "tar-simditor-markdown/styles/simditor-markdown.css";
import "./simditor-file-upload";
import $ from 'jquery'
export default {
  name: "Simditor",
  props: {
    toolbar: {
      type: Array,
      default: () => [
        "title",
        "bold",
        "italic",
        "underline",
        "fontScale",
        "color",
        "ol",
        "ul",
        "code",
        "|",
        "link",
        "image",
        "uploadfile",
        "hr",
        "|",
        "indent",
        "outdent",
        "alignment",
        "|",
        "markdown",
      ],
    },
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editor: null,
      currentValue: this.value,
    };
  },
  mounted() {
    Simditor.i18n["zh-CN"] = {
      alignCenter: "가운데 정렬",
      alignLeft: "왼쪽 정렬 ",
      alignRight: "오른쪽 정렬",
      alignment: "정렬",
      blockquote: "인용",
      bold: "굵게",
      code: "코드",
      color: "글자색",
      coloredText: "Colored Text",
      deleteColumn: "Delete Column",
      deleteRow: "Delete Row",
      deleteTable: "Delete Table",
      externalImage: "External Image",
      fontScale: "폰트 크기",
      fontScaleLarge: "크게",
      fontScaleNormal: "기본",
      fontScaleSmall: "작게",
      fontScaleXLarge: "매우 크게",
      fontScaleXSmall: "매우 작게",
      hr: "수평선",
      image: "이미지 넣기",
      imageAlt: "Alt",
      imageSize: "크기",
      imageUrl: "Url",
      indent: "들여쓰기",
      insertColumnLeft: "Insert Column Left",
      insertColumnRight: "Insert Column Right",
      insertRowAbove: "Insert Row Above",
      insertRowBelow: "Insert Row Below",
      italic: "기울임꼴",
      link: "Insert Link",
      linkTarget: "Target",
      linkText: "Text",
      linkUrl: "Url",
      normalText: "Text",
      ol: "리스트(순서)",
      openLinkInCurrentWindow: "현재 창에서 열기 ",
      openLinkInNewWindow: "새창에서 열기",
      outdent: "들여쓰기",
      removeLink: "링크 삭제하기",
      restoreImageSize: "이미지 원래 사이즈로 돌리기",
      selectLanguage: "언어 선택",
      strikethrough: "취소선",
      table: "테이블",
      title: "제목",
      ul: "리스트(순서 X)",
      underline: "밑줄",
      uploadError: "업로드 하는 동안 문제가 발생하였습니다.",
      uploadFailed: "업로드에 실패하였습니다.",
      uploadImage: "이미지 업로드",
      uploading: "업로딩 중 ",
    };
    
Simditor.Toolbar.buttons.image.prototype._initUploader = function($uploadItem) {
    var $input, createInput, uploadProgress;
    if ($uploadItem == null) {
      $uploadItem = this.menuEl.find('.menu-item-upload-image');
    }
    if (this.editor.uploader == null) {
      this.el.find('.btn-upload').remove();
      return;
    }
    $input = null;
    createInput = (function(_this) {
      return function() {
        if ($input) {
          $input.remove();
        }
        return $input = $('<input/>', {
          type: 'file',
          title: _this._t('uploadImage'),
          multiple: true,
          accept: 'image/*'
        }).appendTo($uploadItem);
      };
    })(this);
    createInput();
    $uploadItem.on('click mousedown', 'input[type=file]', function(e) {
      return e.stopPropagation();
    });
    $uploadItem.on('change', 'input[type=file]', (function(_this) {
      return function(e) {
        if (_this.editor.inputManager.focused) {
          _this.editor.uploader.upload($input, {
            inline: true
          });
          createInput();
        } else {
          _this.editor.one('focus', function(e) {
            _this.editor.uploader.upload($input, {
              inline: true
            });
            return createInput();
          });
          _this.editor.focus();
        }
        return _this.wrapper.removeClass('menu-on');
      };
    })(this));
    this.editor.uploader.on('beforeupload', (function(_this) {
      return function(e, file) {
        var $img;
        if (!file.inline) {
          return;
        }
         if (file.size > 5242880) {
            const size = (file.size / (1000 * 1000)).toFixed(1);
            alert(`최대 업로드 사이즈(5 MB)를 초과 하였습니다.\n현재 사이즈 ${size}MB`);
            return false;
        }
        else if (file.img) {
          $img = $(file.img);
        } else {
          $img = _this.createImage(file.name);
          file.img = $img;
        }

        if($img && $img.length){
                  // return;
        $img.addClass('uploading');
        $img.data('file', file);
        return _this.editor.uploader.readImageFile(file.obj, function(img) {
          var src;
          if (!$img.hasClass('uploading')) {
            return;
          }
          src = img ? img.src : _this.defaultImage;
          return _this.loadImage($img, src, function() {
            if (_this.popover.active) {
              _this.popover.refresh();
              return _this.popover.srcEl.val(_this._t('uploading')).prop('disabled', true);
            }
          });
        });
        }

      };
    })(this));
    uploadProgress = $.proxy(this.editor.util.throttle(function(e, file, loaded, total) {
      var $img, $mask, percent;
      if (!file.inline) {
        return;
      }
      $mask = file.img.data('mask');
      if (!$mask) {
        return;
      }
      $img = $mask.data('img');
      if (!($img.hasClass('uploading') && $img.parent().length > 0)) {
        $mask.remove();
        return;
      }
      percent = loaded / total;
      percent = (percent * 100).toFixed(0);
      if (percent > 99) {
        percent = 99;
      }
      return $mask.find('.progress').height((100 - percent) + "%");
    }, 500), this);
    this.editor.uploader.on('uploadprogress', uploadProgress);
    this.editor.uploader.on('uploadsuccess', (function(_this) {
      return function(e, file, result) {
        var $img, img_path, msg;
        if (!file.inline) {
          return;
        }
        $img = file.img;
        if (!($img.hasClass('uploading') && $img.parent().length > 0)) {
          return;
        }
        if (typeof result !== 'object') {
          try {
            result = $.parseJSON(result);
          } catch (_error) {
            e = _error;
            result = {
              success: false
            };
          }
        }
        if (result.success === false) {
          msg = result.msg || _this._t('uploadFailed');
          alert(msg);
          img_path = _this.defaultImage;
        } else {
          img_path = result.file_path;
        }
        _this.loadImage($img, img_path, function() {
          var $mask;
          $img.removeData('file');
          $img.removeClass('uploading').removeClass('loading');
          $mask = $img.data('mask');
          if ($mask) {
            $mask.remove();
          }
          $img.removeData('mask');
          _this.editor.trigger('valuechanged');
          if (_this.editor.body.find('img.uploading').length < 1) {
            return _this.editor.uploader.trigger('uploadready', [file, result]);
          }
        });
        if (_this.popover.active) {
          _this.popover.srcEl.prop('disabled', false);
          return _this.popover.srcEl.val(result.file_path);
        }
      };
    })(this));
    return this.editor.uploader.on('uploaderror', (function(_this) {
      return function(e, file, xhr) {
        var $img, msg, result;
        if (!file.inline) {
          return;
        }
        if (xhr.statusText === 'abort') {
          return;
        }
        if (xhr.responseText) {
          try {
            result = $.parseJSON(xhr.responseText);
            msg = result.msg;
          } catch (_error) {
            e = _error;
            msg = _this._t('uploadError');
          }
          alert(msg);
        }
        $img = file.img;
        if (!($img.hasClass('uploading') && $img.parent().length > 0)) {
          return;
        }
        _this.loadImage($img, _this.defaultImage, function() {
          var $mask;
          $img.removeData('file');
          $img.removeClass('uploading').removeClass('loading');
          $mask = $img.data('mask');
          if ($mask) {
            $mask.remove();
          }
          return $img.removeData('mask');
        });
        if (_this.popover.active) {
          _this.popover.srcEl.prop('disabled', false);
          _this.popover.srcEl.val(_this.defaultImage);
        }
        _this.editor.trigger('valuechanged');
        if (_this.editor.body.find('img.uploading').length < 1) {
          return _this.editor.uploader.trigger('uploadready', [file, result]);
        }
      };
    })(this));
  };


    Simditor.Clipboard.prototype._processPasteByClipboardApi = clip;
     function clip(e) {
      var imageFile, pasteItem, ref, uploadOpt;
      if (this.editor.util.browser.edge) {
        return;
      }
      if (
        e.originalEvent.clipboardData &&
        e.originalEvent.clipboardData.items &&
        e.originalEvent.clipboardData.items.length > 0
      ) {
        pasteItem = e.originalEvent.clipboardData.items[0];
      
        if (/^image\//.test(pasteItem.type)) {
          imageFile = pasteItem.getAsFile();
          
          
          if (!(imageFile != null && this.opts.pasteImage)) {
            return;
          }
          if (!imageFile.name) {
            imageFile.name = "Clipboard Image.png";
          }
          if (this.editor.triggerHandler("pasting", [imageFile]) === false) {
            return;
          }
          uploadOpt = {};
          uploadOpt[this.opts.pasteImage] = true;
          
          if (imageFile.size > 5242880) {
            const size = (imageFile.size / (1000 * 1000)).toFixed(1);
            alert(`최대 업로드 사이즈(5 MB)를 초과 하였습니다.\n현재 사이즈 ${size}MB`);
      
          }
          else if ((ref = this.editor.uploader) != null) {
            ref.upload(imageFile, uploadOpt);
          }
          return true;
        }
      }
    }
  
    this.editor = new Simditor({
      textarea: this.$refs.editor,
      toolbar: this.toolbar,
      pasteImage: true,
      // clipboard 복사 붙여넣기를 허용할 것인가? true
      markdown: false,
      //기본 모드를 markdown 할것인가? false
      upload: {
        url: "/api/admin/upload_image/",
        params: null,
        fileKey: "image",
        connectionCount: 3,
        leaveConfirm: this.$i18n.t("m.Uploading_is_in_progress"),
      },
      allowedStyles: {
        span: ["color"],
      },
      codelanguage: [
        { name: "Bash", value: "bash" },
        { name: "C++", value: "c++" },
        { name: "C#", value: "cs" },
        { name: "CSS", value: "css" },
        { name: "Erlang", value: "erlang" },
        { name: "Less", value: "less" },
      ],
    });

    this.editor.on("valuechanged", (e) => {
      this.currentValue = this.editor.getValue();
    });
    this.editor.on("decorate", (e) => {
      this.currentValue = this.editor.getValue();
    });
    
    this.editor.setValue(this.value);
  },
  watch: {
    value(val) {
      if (this.currentValue !== val) {
        this.currentValue = val;
        this.editor.setValue(val);
      }
    },
    currentValue(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("change", newVal);
        this.$emit("input", newVal);
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
