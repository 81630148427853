<template>
  <div class="view">
    <!-- <AdminPanel :title="'채점 서버 토큰'">
      <code>{{ token }}</code>
    </AdminPanel> -->
    <AdminPanel :title="'채점 서버'">
      <el-table
        empty-text="검색한 정보가 없습니다"
        :data="servers"
        :default-expand-all="true"
        border
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <div class="judge-info-wrapper">
              <p>
                Container ID :
                <el-tag
                  :size="$store.state.isMobile ? 'mini' : ''"
                  type="success"
                  >{{ props.row.hostname }}</el-tag
                >&nbsp;&nbsp; 채점 시스템버전 :
                <el-tag
                  :size="$store.state.isMobile ? 'mini' : ''"
                  type="success"
                  >{{ props.row.judger_version }}</el-tag
                >
              </p>
              <div>
                <p class="time-left">최근 Heartbeat</p>
                <p class="time-right">
                  : {{ props.row.last_heartbeat | utcToLocalJudge }}
                </p>
              </div>
              <div>
                <p class="time-left">생성시간</p>
                <p class="time-right">
                  : {{ props.row.create_time | utcToLocalJudge }}
                </p>
              </div>
              <div>
                <p class="time-left">최근 채점시간</p>
                <p class="time-right">
                  :
                  {{ props.row.recent_create_time | utcToLocalJudge }}
                </p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="상태">
          <template slot-scope="scope">
            <el-tag
              :size="$store.state.isMobile ? 'mini' : ''"
              :type="scope.row.status === 'normal' ? 'success' : 'danger'"
            >
              {{ scope.row.status === "normal" ? "Normal" : "Abnormal" }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          v-if="!$store.state.isMobile"
          prop="service_url"
          label="URL"
          :min-width="150"
        >
          <template slot-scope="scope">
            <el-tag :size="$store.state.isMobile ? 'mini' : ''" type="success">
              {{ scope.row.service_url }}
            </el-tag>
          </template>
        </el-table-column>
        <!-- 추가 -->
        <el-table-column prop="nickname" label="설명" :min-width="200">
        </el-table-column>
        <!-- // -->
        <el-table-column prop="task_number">
          <template slot="header">
            <span>채점 대기수</span><br />
            <span>(Task Number)</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="!$store.state.isMobile"
          prop="cpu_core"
          label="CPU Core 수"
        >
        </el-table-column>
        <el-table-column prop="cpu_usage" label="CPU 사용량">
          <template slot-scope="scope">{{ scope.row.cpu_usage }}%</template>
        </el-table-column>
        <el-table-column prop="memory_usage" label="Memory 사용량">
          <template slot-scope="scope">{{ scope.row.memory_usage }}%</template>
        </el-table-column>
        <!-- <el-table-column label="서버 활성화">
          <template slot-scope="{ row }">
            <el-switch
              v-model="row.is_disabled"
              @change="handleDisabledSwitch(row.id, row.is_disabled)"
            ></el-switch>
          </template>
        </el-table-column> -->
        <el-table-column label="옵션">
          <template slot-scope="scope">
            <icon-btn
              name="설명 변경"
              icon="plus"
              @click.native="setModal(scope.row.id)"
            ></icon-btn>

            <!-- <icon-btn
              name="삭제"
              icon="delete"
              @click.native="deleteJudgeServer(scope.row.hostname)"
            ></icon-btn> -->
          </template>
        </el-table-column>
      </el-table>
    </AdminPanel>

    <el-dialog title="설명 변경" :visible.sync="dialogVisible" width="30%">
      <el-input
        placeholder="서버 설명을 입력해주세요"
        v-model="nickname"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="changeJudgeServernickName"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api.js";
import AdminPanel from "@admin/components/AdminPanel.vue";
export default {
  name: "JudgeServer",
  components: {
    AdminPanel,
  },
  data() {
    return {
      servers: [],
      token: "",
      intervalId: -1,
      dialogVisible: false,
      nickname: "",
      currentId: 0,
    };
  },
  mounted() {
    this.refreshJudgeServerList();
    // this.intervalId = setInterval(() => {
    //   this.refreshJudgeServerList();
    // }, 5000);
  },
  methods: {
    setModal(id) {
      this.currentId = id;
      this.dialogVisible = true;
    },
    async changeJudgeServernickName() {
      //this.currentId = this.
      const data = {
        id: this.currentId,
        nickname: this.nickname,
      };
      const result = await api.changeJudgeServernickName(data);
      this.dialogVisible = false;
      this.refreshJudgeServerList();
    },
    
    refreshJudgeServerList() {
      api.getJudgeServerLast().then((res) => {
        this.servers = res.data.data.servers;
        this.servers = this.servers.map((li) => {
          li.is_disabled = !li.is_disabled;
          return li;
        });
        // this.servers = this.servers.filter(li => li.status === "normal");
        this.servers = this.servers.sort((a, b) => {
          if (a.status === "normal") {
            if (a.id > b.id) {
              return 1;
            } else {
              return -1;
            }
          }
        });
        // console.log(this.servers);
        this.token = res.data.data.token;
      });
    },
    deleteJudgeServer(hostname) {
      this.$confirm(
        "If you delete this judge server, it can't be used until next heartbeat",
        "Warning",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          api
            .deleteJudgeServer(hostname)
            .then((res) => this.refreshJudgeServerList());
        })
        .catch(() => {});
    },
    handleDisabledSwitch(id, value) {
      let data = {
        id,
        is_disabled: !value,
      };
      api.updateJudgeServer(data).catch(() => {});
    },
    dataformat(date) {
      let year = date.substr(2, 4);
      // console.log(year);
      // let month = date.getMonth() + 1;
      // let day  = date.getDate();

      // let hours = date.getHours();
      // let ampm = '';
      // if(hours >= 12){
      //   ampm = '오후'
      //   if(hours === 12){
      //     hours = 12;
      //   }
      //   hours = hours-12;
      // }
      // else {
      //   ampm = '오전'

      // }
      // let minutes = date.getMinutes();
      // return (
      //   year.substring(2,4) +
      //   "/" +
      //   month +
      //   "/" +
      //   day +
      //   " " +
      //   ampm +
      //   " " +
      //   hours +
      //   " : " +
      //   minutes
      // );
    },
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.intervalId);
    next();
  },
};
</script>

<style>
.judge-info-wrapper {
  padding: 15px 10px;
}
.time-left {
  display: inline-block;
  width: 6rem;
}
.time-right {
  display: inline-block;
}
</style>
