import Vue from 'vue'
import store from '@/store'
import axios from 'axios'

Vue.prototype.$http = axios
axios.defaults.baseURL = '/api'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.xsrfCookieName = 'csrftoken'

export default {
  getWebsiteConf (params) {
    return ajax('website', 'get', {
      params
    })
  },
  getAnnouncementList (offset, limit) {
    let params = {
      offset: offset,
      limit: limit
    }
    return ajax('announcement', 'get', {
      params
    })
  },
  login (data) {
    return ajax('login', 'post', {
      data
    })
  },
  checkUsernameOrEmail (username, email) {
    return ajax('check_username_or_email', 'post', {
      data: {
        username,
        email
      }
    })
  },
  // 회원가입
  register (data) {
    return ajax('register', 'post', {
      data
    })
  },
  logout () {
    return ajax('logout', 'get')
  },
  getCaptcha () {
    return ajax('captcha', 'get')
  },
  getUserInfo (username = undefined) {
    return ajax('profile', 'get', {
      params: {
        username
      }
    })
  },
  updateProfile (profile) {
    return ajax('profile', 'put', {
      data: profile
    })
  },
  freshDisplayID (userID) {
    return ajax('profile/fresh_display_id', 'get', {
      params: {
        user_id: userID
      }
    })
  },
  twoFactorAuth (method, data) {
    return ajax('two_factor_auth', method, {
      data
    })
  },
  tfaRequiredCheck (username) {
    return ajax('tfa_required', 'post', {
      data: {
        username
      }
    })
  },
  getSessions () {
    return ajax('sessions', 'get')
  },
  deleteSession (sessionKey) {
    return ajax('sessions', 'delete', {
      params: {
        session_key: sessionKey
      }
    })
  },
  applyResetPassword (data) {
    return ajax('apply_reset_password', 'post', {
      data
    })
  },
  resetPassword (data) {
    return ajax('reset_password', 'post', {
      data
    })
  },
  changePassword (data) {
    return ajax('change_password', 'post', {
      data
    })
  },
  changeEmail (data) {
    return ajax('change_email', 'post', {
      data
    })
  },
  getLanguages () {
    return ajax('languages', 'get')
  },
  getProblemTagList () {
    return ajax('problem/tags', 'get')
  },
  getProblemList (offset, limit, searchParams) {
    let params = {
      paging: true,
      offset,
      limit
    }
    Object.keys(searchParams).forEach((element) => {
      if (searchParams[element]) {
        params[element] = searchParams[element]
      }
    })
    return ajax('problem', 'get', {
      params: params
    })
  },
  pickone () {
    return ajax('pickone', 'get')
  },
  // soruce도 가져오는 코드 추가
  getProblemAnswer (problemID) {
    return ajax('problem/answer', 'get', {
      params: {
        problem_id: problemID
      }
    })
  },
  getProblem (problemID) {
    return ajax('problem', 'get', {
      params: {
        problem_id: problemID
      }
    })
  },
  getProblemID (problemID) {
    return ajax('problemid', 'get', {
      params: {
        problem_id: problemID
      }
    })
  },
  getEnterPriseContestList (offset, limit, searchParams, board_name) {
    let params = {
      offset,
      limit,
      board_name : board_name
    }
    if (searchParams !== undefined) {
      Object.keys(searchParams).forEach((element) => {
        if (searchParams[element]) {
          params[element] = searchParams[element]
        }
      })
    }
    return ajax('enterprise-contests', 'get', {
      params
    })
  },
  getContestList (offset, limit, searchParams) {
    let params = {
      offset,
      limit
    }
    if (searchParams !== undefined) {
      Object.keys(searchParams).forEach((element) => {
        if (searchParams[element]) {
          params[element] = searchParams[element]
        }
      })
    }
    return ajax('contests', 'get', {
      params
    })
  },
  getContest (id = 12) {
    // 220530 어떤이유인지 모르겠지만 getContest가 호출
    // 임시로 디폴트값 삽입
    return ajax('contest', 'get', {
      params: {
        id
      }
    })
  },
  getContestAccess (contestID) {
    return ajax('contest/access', 'get', {
      params: {
        contest_id: contestID
      }
    })
  },
  checkContestPassword (contestID, password) {
    return ajax('contest/password', 'post', {
      data: {
        contest_id: contestID,
        password
      }
    })
  },
  getContestAnnouncementList (contestId) {
    return ajax('contest/announcement', 'get', {
      params: {
        contest_id: contestId
      }
    })
  },
  getContestAnnouncement (contestId, announcementId) {
    return ajax('contest/announcement-detail', 'get', {
      params: {
        contest_id: contestId,
        announcement_id: announcementId
      }
    })
  },
  getContestProblemList (contestId) {
    return ajax('contest/problem', 'get', {
      params: {
        contest_id: contestId
      }
    })
  },
  getContestProblem (problemID, contestID) {
    return ajax('contest/problem', 'get', {
      params: {
        contest_id: contestID,
        problem_id: problemID
      }
    })
  },
  getContestProblemAnswer (problemID, contestID) {
    return ajax('contest/problem/answer', 'get', {
      params: {
        contest_id: contestID,
        problem_id: problemID
      }
    })
  },
  submitCode (data) {
    return ajax('submission', 'post', {
      data
    })
  },
  getSubmissionList (offset, limit, params) {
    params.limit = limit
    params.offset = offset
    return ajax('submissions', 'get', {
      params
    })
  },
  getContestSubmissionList (offset, limit, params) {
    params.limit = limit
    params.offset = offset
    return ajax('contest_submissions', 'get', {
      params
    })
  },
  getSubmission (id) {
    return ajax('submission', 'get', {
      params: {
        id
      }
    })
  },
  submissionExists (problemID) {
    return ajax('submission_exists', 'get', {
      params: {
        problem_id: problemID
      }
    })
  },
  submissionRejudge (id) {
    return ajax('admin/submission/rejudge', 'get', {
      params: {
        id
      }
    })
  },
  updateSubmission (data) {
    return ajax('submission', 'put', {
      data
    })
  },
  getUserRank (offset, limit, rule = 'acm') {
    let params = {
      offset,
      limit,
      rule
    }
    return ajax('user_rank', 'get', {
      params
    })
  },
  getContestRank (params) {
    return ajax('contest_rank', 'get', {
      params
    })
  },
  getContestRankCsv (params) {
    return ajax('contest_rank_csv', 'get', {
      params
    })
  },
  
  getACMACInfo (params) {
    return ajax('admin/contest/acm_helper', 'get', {
      params
    })
  },
  updateACInfoCheckedStatus (data) {
    return ajax('admin/contest/acm_helper', 'put', {
      data
    })
  },
  getBoardList (params) {
    
    return ajax('board-list', 'get', {
      params
    })
  },
  getBoardNoticeList(params){
    return ajax('board/announce', 'get', {
      params
    });
  },
  // 210302추가
  getNoticeList (params) {
    // params.limit = limit
    // params.offset = offset
    return ajax('notice-list', 'get', {
      params
    })
  },
  getNoticeFromNoticeList(params){
    return ajax('notice/announce', 'get', {
      params
    });
  },
  
  getBoardDetail(params){
    return ajax('board', 'get', {
      params: {
        board_id: params
      }
    })
  },
  getBoardAuthCheck(params){
    return ajax('board-auth-check', 'get', {
      params: {
        contest_id: params
      }
    })
  },

  // 230201추가
  getELearningList (params) {
    // params.limit = limit
    // params.offset = offset
    return ajax('e_learnings', 'get', {
      params
    })
  },
  
  // 0302추가
  getNoticeDetail(params){
    return ajax('notice', 'get', {
      params: {
        board_id: params
      }
    })
  },


 // 230131추가
  getELearningDetail(params){
    return ajax(`e_learnings/${params}`, 'get', {
      data: {
        company : "miracom"
      }
    })
  },

  postBoard(data){
    return ajax('board/', 'post',{
      data
    })
  },
  // 0303추가
  postNotice(title, content, created_by_id, type, company, isNotice){
    return ajax('notice', 'post',{
      data:{
        title,
        content,
        created_by_id,
        type,
        company,
        isNotice
      }
    })
  },
  putBoard(title, content, parameter, type, isNotice){
    return ajax('board', 'put', {
      data: {
        title,
        content,
        board_id: parameter,
        type: type,
        isNotice
      }
    })
  },

  // 230131추가
  postELearning(title, content, created_by_id, company, type, content_type){
    return ajax('e_learnings/', 'post',{
      data:{
        title,
        content,
        created_by_id,
        company,
        type,
        content_type
      }
    })
  },

  // 0303추가
  putNotice(title, content, parameter, type,isNotice){
    return ajax('notice', 'put', {
      data: {
        title,
        content,
        board_id: parameter,
        type: type,
        isNotice
      }
    })
  },

  // 230201추가
  putELearning(title, content, type, content_type, id){
    return ajax(`e_learnings/${id}`, 'put', {
      data: {
        title,
        content,
        type,
        content_type
      }
    })
  },

  // 0303추가
  deleteNotice(parameter){
    return ajax('notice', 'delete', {
      params: {
        board_id: parameter
      }
    })
  },
  deleteBoard(parameter){
    return ajax('board', 'delete', {
      params: {
        board_id: parameter
      }
    })
  },
  // 210302추가
  deleteNotice(parameter){
    return ajax('notice/', 'delete', {
      params: {
        board_id: parameter
      }
    })
  },

  // 230201추가
  deleteELearning(parameter){
    return ajax(`e_learnings/${parameter}`, 'delete');
  },

  postComment(comment, board_id, created_by_id, type){
    return ajax('comment/', 'post',{
      data:{
        comment,
        board_id,
        created_by_id,
        type
      }
    })
  },
  // 210302 추가
  noticepostComment(comment, board_id, created_by_id, type){
    return ajax('notice-comment/', 'post',{
      data:{
        comment,
        board_id,
        created_by_id,
        type
      }
    })
  },
  putComment(comment_id,comment){
    return ajax('comment/', 'put', {
      data: {
        comment_id,
        comment
      }
    })
  },
  // 210303추가
  putNoticeComment(comment_id,comment){
    return ajax('notice-comment/', 'put', {
      data: {
        comment_id,
        comment
      }
    })
  },
  deleteComment(comment_id){
    return ajax('comment/', 'delete', {
      params: {
        comment_id
      }
    })
  },
  // 210303 추가
  deleteNoticeComment(comment_id){
    return ajax('notice-comment/', 'delete', {
      params: {
        comment_id
      }
    })
  },
  // 0303 추가
  deleteNoitceComment(comment_id){
    return ajax('notice-comment/', 'delete', {
      params: {
        comment_id
      }
    })
  },

  // 비밀글 처리 
  secretBoard(board_id, type){
    return ajax('board-secret', 'put', {
      data:{
        board_id,
        type
      }
    })
  },

  // 210303 추가
  secretNotice(board_id, type){
    return ajax('notice-secret', 'put', {
      data:{
        board_id,
        type
      }
    })
  },


  // 230201 추가
  secretELearning(id, type){
    return ajax(`e_learnings/${id}/secret/`, 'put', {
      data:{
        type
      }
    })
  },



    
  secretComment(comment_id, type){
    return ajax('comment-secret', 'put', {
      data:{
        comment_id,
        type
      }
    })
  },
  // 210303추가
  secretNoticeComment(comment_id, type){
    return ajax('notice-comment-secret', 'put', {
      data:{
        comment_id,
        type
      }
    })
  },

    // level-problem
    getProblemStepList() {
      // params = utils.filterEmptyValue(params)
      return ajax('problem_step', 'get', {
      })
    },
    getProblemStep(problem_step_id) {
      return ajax('problem_step', 'get', {
        params: {
          problem_step_id
        }
      })
    },

    // problem_step기반
    getProblemStepLevelList(problem_step_id) {
      return ajax('problem_step_level', 'get', {
        params: {
          problem_step_id
        }
      })
    },
    getStepLevel(problem_step_level_id) {
      return ajax('problem_step_level', 'get', {
        params: {
          problem_step_level_id
        }
      })
    },
    // problemStepLevelDetailList
    getProblemStepLevelDetailList(problem_step_level_id){
      return ajax('problem_step_level_detail', 'get', {
        params: {
          problem_step_level_id
        }
      }) 
    },
    getProblemStepLevelDetail(problem_step_level_detail_id){
      return ajax('problem_step_level_detail', 'get', {
        params: {
          problem_step_level_detail_id
        }
      }) 
    },
    putProblemStepLevelDetail(isFavorite){
      const id = isFavorite;
      return ajax('problem_step_level_detail', 'put', {
        data : id
      })
    },
    getFavorite(user_id){
      return ajax('favorite', 'get', {
        params: {
          user_id
        }
      }) 
    },
    postFavorite(data){
      return ajax('favorite', 'post',{
        data
      })
    },
    deleteFavorite (data) {
      return ajax('favorite', 'delete',{
        params: {
          ...data
        }
      })
    },
    changeProcess(board_id, process) {
      return ajax(`board/${board_id}/process/`, 'put', {
        data: {
          process
        }
      })
    },
    getProcess(compnay) {
      return ajax(`board/process/`, 'get', {
        params:{
          company:compnay
        }
      })
    },

  changeAnnounce(board_id, isNotice){
    return ajax('board/change-announce/', 'put', {
      data:{
        board_id,
        isNotice
      }
    })
  },
  changeNoticeAnnounce(board_id, isNotice){
    return ajax('notice/change-announce/', 'put', {
      data:{
        board_id,
        isNotice
      }
    })
  },

}

/**
 * @param url
 * @param method get|post|put|delete...
 * @param params like queryString. if a url is index?a=1&b=2, params = {a: '1', b: '2'}
 * @param data post data, use for method put|post
 * @returns {Promise}
 */
function ajax (url, method, options) {
  if (options !== undefined) {
    var {params = {}, data = {}} = options
  } else {
    params = data = {}
  }
  return new Promise((resolve, reject) => {
    axios({
      url,
      method,
      params,
      data
    }).then(res => {
      // API는 오류 유무에 따라 오류가 있는지 여부를 정상적으로 반환합니다 (상태 = 20x).
      if (res.data.error !== null) {
        Vue.prototype.$error(res.data.data)
        reject(res)
        // 백엔드가 로그인으로 돌아 가면 세션이 유효하지 않으며 현재 로그인 한 사용자는 로그 아웃해야합니다.
        if (res.data.data.startsWith('Please login')) {
          store.dispatch('changeModalStatus', {'mode': 'login', 'visible': true})
        }
      } else {
        resolve(res)
        // if (method !== 'get') {
        //   Vue.prototype.$success('Succeeded')
        // }
      }
    }, res => {
      // API 요청이 비정상이며 일반적으로 서버 오류 또는 네트워크 오류입니다.
      reject(res)
      Vue.prototype.$error(res.data.data)
    })
  })
}