<template>
  <div>
    <div style="display: flex">
      <el-input
        v-model="keyword"
        placeholder="키워드를 입력하세요"
        prefix-icon="el-icon-search"
      >
      </el-input>
      <el-input
        style="margin-left: 20px"
        v-model="tag"
        placeholder="태그를 입력하세요"
        prefix-icon="el-icon-search"
      >
      </el-input>
    </div>
    <el-table
      empty-text="검색한 정보가 없습니다"
      :data="problems"
      v-loading="loading"
    >
      <el-table-column
        v-if="!$store.state.isMobile"
        label="ID"
        width="100"
        prop="id"
      >
      </el-table-column>
      <el-table-column label="DisplayID" min-width="150" prop="_id">
      </el-table-column>
      <el-table-column min-width="150" label="제목" prop="title">
      </el-table-column>

      <el-table-column label="태그" width="150">
        <template slot-scope="{ row }">
          <el-tag
            v-for="tag of row.tags"
            :key="tag.id"
            style="margin: 1.5px"
            type="mini"
          >
            {{ tag.name }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column label="옵션" align="center" width="100">
        <template slot-scope="{ row }">
          <icon-btn
            icon="plus"
            name="문제 추가하기"
            @click.native="handleAddProblem(row)"
          ></icon-btn>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="page"
      layout="prev, pager, next"
      @current-change="getPublicProblem"
      :page-size="limit"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
import api from "@admin/api";
import { mapState, mapMutations } from "vuex";

export default {
  name: "add-problem-from-public",
  props: ["contestID"],
  data() {
    return {
      page: 1,
      limit: 10,
      total: 0,
      loading: false,
      problems: [],
      contest: {},
      keyword: "",
      tag: "",
    };
  },
  mounted() {
    api
      .getContest(this.contestID)
      .then((res) => {
        this.contest = res.data.data;
        this.getPublicProblem();
      })
      .catch(() => {});
  },
  methods: {
    ...mapMutations([
      "CHANGE_PROBLEM_STEP_LEVEL_DETAIL_DIALOG",
      "CHANGE_PROBLEM_STEP_LEVEL_DETAIL_PROBLEM",
      "CHANGE_TITLE",
    ]),
    getPublicProblem(page) {
      this.loading = true;
      let params = {
        keyword: this.keyword,
        tag: this.tag,
        offset: (page - 1) * this.limit,
        limit: this.limit,
        rule_type: this.contest.rule_type,
      };
      api
        .getProblemList(params)
        .then((res) => {
          this.loading = false;
          this.total = res.data.data.total;
          this.problems = res.data.data.results;
        })
        .catch(() => {});
    },
    handleAddProblem(row) {
      this.CHANGE_PROBLEM_STEP_LEVEL_DETAIL_PROBLEM(row);
      this.CHANGE_PROBLEM_STEP_LEVEL_DETAIL_DIALOG(false);
      this.CHANGE_TITLE(row.title);
    },
  },
  watch: {
    keyword() {
      this.getPublicProblem(this.page);
    },

    tag() {
      this.getPublicProblem(this.page);
    },
  },
};
</script>
<style scoped>
.page {
  margin-top: 20px;
  text-align: right;
}
</style>
