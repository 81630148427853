
import types from "../types";

const state = {
  problemTitle:"",
  alertVisual: false

};

const getters = {};

const mutations = {
  [types.CHANGE_PROBLEM_TITLE](state, payload) {
    state.problemTitle = payload;
  },
  [types.CHANGE_ALERT_VISUAL](state, payload) {
    state.alertVisual = payload;
  }
};

const actions = {
};

export default {
  state,
  mutations,
  getters,
  actions
};
