<template>
  <Row>
    <Panel shadow>
      <div slot="title">Level별 문제</div>
      <Table @on-row-click="move" :columns="columns" :data="category"> </Table>
    </Panel>
  </Row>
</template>
<script>
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      category: [],
      g1: [
        { title: "Level 1", level: "G1LV01", content: "프로그래밍 첫 걸음" },
        { title: "Level 1.5", level: "G1LV01_5", content: "+ 추가문제" },
        {
          title: "Level 2",
          level: "G1LV02",
          content: "프로그래밍에서의 조건문"
        },
        { title: "Level 2.5", level: "G1LV02_5", content: "+ 추가문제" },
        {
          title: "Level 3",
          level: "G1LV03",
          content: "소스코드의 반복 처리"
        },
        {
          title: "Level 3.5",
          level: "G1LV03_5",
          content: "+ 추가문제"
        },
        { title: "Level 4", level: "G1LV04", content: "배열 다루기" },
        { title: "Level 4.5", level: "G1LV04_5", content: "+ 추가문제" },
        { title: "Level 5", level: "G1LV05", content: "함수 만들기" },
        { title: "Level 5.5", level: "G1LV05_5", content: "+ 추가문제" },
        { title: "Level 6", level: "G1LV06", content: "다중 반복문의 시작" },
        { title: "Level 6.5", level: "G1LV06_5", content: "+ 추가문제" },
        { title: "Level 7", level: "G1LV07", content: "2차원 배열 다루기" },
        { title: "Level 7.5", level: "G1LV07_5", content: "+ 추가문제" },
        { title: "Level 8", level: "G1LV08", content: "while과 break문" },
        { title: "Level 8.5", level: "G1LV08_5", content: "+ 추가문제" }
      ],

      g2: [
        {
          title: "Level 9",
          level: "G2LV09",
          content: "Debugging과 Struct 활용"
        },
        { title: "Level 9.5", level: "G2LV09_5", content: "+ 추가문제" },
        { title: "Level 10", level: "G2LV10", content: "함수의 return값 활용" },
        { title: "Level 10.5", level: "G2LV10_5", content: "+ 추가문제" },
        {
          title: "Level 11",
          level: "G2LV11",
          content: "포인터, 그리고 flag 와 max"
        },
        { title: "Level 11.5", level: "G2LV11_5", content: "+ 추가문제" },
        { title: "Level 12", level: "G2LV12", content: "문자열 다루기" },
        { title: "Level 12.5", level: "G2LV12_5", content: "+ 추가문제" },
        { title: "Level 13", level: "G2LV13", content: "Call by reference" },
        { title: "Level 13.5", level: "G2LV13_5", content: "+ 추가문제" },
        { title: "Level 14", level: "G2LV14", content: "반복문 Training" },
        {
          title: "Level 14.5",
          level: "G2LV14_5",
          content: "다중 문자열 다루기"
        },
        { title: "Level 15", level: "G2LV15", content: "다중 문자열 다루기" },
        {
          title: "Level 훈련반준비",
          level: "G2훈련반",
          content: "훈련반 준비 복습문제"
        }
      ],

      g3: [
        {
          title: "Level 16 ",
          level: "G3LV16",
          content: "프로그래밍 설계 방법"
        },
        { title: "Level 16.5", level: "G3LV16_5", content: "+ 추가문제" },
        {
          title: "Level 17",
          level: "G3LV17",
          content: "신속한 Debugging 노하우"
        },
        { title: "Level 17.5", level: "G3LV17_5", content: "+ 추가문제" },
        { title: "Level 18", level: "G3LV18", content: "Direct Address Table" },
        { title: "Level 18.5", level: "G3LV18_5", content: "+ 추가문제" },
        {
          title: "Level 19",
          level: "G3LV19",
          content: "방향 배열과 Struct 배열 다루기"
        },
        { title: "Level 19.5", level: "G3LV19_5", content: "+ 추가문제" },
        {
          title: "Level 20",
          level: "G3LV20",
          content: "재귀호출 1 : 무한 호출 막기"
        },
        { title: "Level 20.5", level: "G3LV20_5", content: "+ 추가문제" },
        { title: "Level 21", level: "G3LV21", content: "재귀호출 2 : Path" },
        { title: "Level 21.5", level: "G3LV21_5", content: "+ 추가문제" },
        {
          title: "Level 22",
          level: "G3LV22",
          content: "재귀호출 3 : Path + Sum 응용"
        },
        { title: "Level 22.5", level: "G3LV22_5", content: "+ 추가문제" },
        {
          title: "Level 23",
          level: "G3LV23",
          content: "재귀호출 4 : used + 가지치기"
        },
        { title: "Level 23.5", level: "G3LV23_5", content: "+ 추가문제" }
      ],

      g4: [
        {
          title: "Level 24",
          level: "G4LV24",
          content: "Parsing 1 : String Class"
        },
        {
          title: "Level 25",
          level: "G4LV25",
          content: "Parsing 2 : Valid Check"
        },
        { title: "Level 25.5", level: "G4LV25_5", content: "+ 추가문제" },
        {
          title: "Level 26",
          level: "G4LV26",
          content: "Linked List, STL 소개"
        },
        { title: "Level 27", level: "G4LV27", content: "Vector와 그래프" },
        {
          title: "Level 28",
          level: "G4LV28",
          content: "Tree의 이해, DFS 시작"
        },
        { title: "Level 28.5", level: "G4LV28_5", content: "+ 추가문제" },
        { title: "Level 29", level: "G4LV29", content: "DFS 응용" },
        { title: "Level 29.5", level: "G4LV29_5", content: "+ 추가문제" },
        { title: "Level 30", level: "G4LV30", content: "BFS 시작" },
        { title: "Level 30.5", level: "G4LV30_5", content: "+ 추가문제" },
        {
          title: "Level 심화반준비",
          level: "G4심화반",
          content: "심화반 준비 복습문제"
        }
      ],
      g5: [
        {
          title: "Level 31",
          level: "G5LV31",
          content: "실용적인 Problem Solving 설계"
        },
        { title: "Level 32", level: "G5LV32", content: "STL과 Sort 다루기" },
        { title: "Level 32.5", level: "G5LV32_5", content: "+ 추가문제" },
        {
          title: "Level 33",
          level: "G5LV33",
          content: "Union-Find와 BST 자료구조"
        },
        {
          title: "Level 34",
          level: "G5LV34",
          content: "Binary Search와 Heap 자료구조"
        },
        { title: "Level 35", level: "G5LV35", content: "Heap 응용과 Dijkstra" },
        {
          title: "Level 36",
          level: "G5LV36",
          content: "Backtracking 1 : used와 sum"
        },
        { title: "Level 36.5", level: "G5LV36_5", content: "+ 추가문제" },
        {
          title: "Level 37",
          level: "G5LV37",
          content: "Backtracking 2 : 순열과 조합"
        },
        {
          title: "Level 38",
          level: "G5LV38",
          content: "Backtracking 3 : 원상복구"
        },
        { title: "Level 38.5", level: "G5LV38_5", content: "+ 추가문제" }
      ],
      g6: [
        { title: "Level 39", level: "G6LV39", content: "Greedy" },
        { title: "Level 40", level: "G6LV40", content: "Dynamic Programming" },
        { title: "Level 40.5", level: "G6LV40_5", content: "+ 추가문제" },
        {
          title: "Level 41",
          level: "G6LV41",
          content: "BFS Flood Fill 1 / Trie"
        },
        {
          title: "Level 42",
          level: "G6LV42",
          content: "BFS Flood Fill 2 / QuickSort"
        },
        {
          title: "Level 43",
          level: "G6LV43",
          content: "BFS Flood Fill 3 / MergeSort"
        },
        { title: "Level 44", level: "G6LV44", content: "STL Hash 1 / Trie" },
        { title: "Level 45", level: "G6LV45", content: "STL Hash 2" }
      ],
      "algorithm-tower": [
        { title: "DP의 탑(Top-Down)", level: "NOT", content: "Top-Down 훈련" },
        { title: "DFS 탑", level: "DFS", content: "DFS 훈련" },
        { title: "BFS 탑", level: "BFS", content: "BFS 훈련" }
      ],
      columns: [
        {
          title: "문제번호",
          align: "center",
          minWidth: 100,
          render: (h, params) => {
            return h("span", params.index + 1);
          }
        },
        {
          title: "Level",
          align: "center",
          minWidth: 200,
          render: (h, params) => {
            return h(
              "span",
              {
                style: {
                  "padding-left": "15px"
                }
              },
              params.row.title
            );
          }
        },
        {
          title: "내용",
          align: "center",
          minWidth: 300,
          render: (h, params) => {
            return h(
              "span",
              {
                style: {
                  "text-align": "left !important"
                }
              },
              params.row.content
            );
          }
        }
      ]
    };
  },
  mounted(){
    // api로 list호출하기
  },
  computed: {
    ...mapState(["problemTitle", "utils"])
  },
  created() {
    this.category = this[this.$route.query.type];
    this.changeTableView();
  },
  watch: {
    "$route.query.type"() {
      this.category = this[this.$route.query.type];
      this.changeTableView();
    }
  },
  methods: {
    ...mapMutations(["CHANGE_PROBLEM_TITLE"]),
    changeTableView() {
      this.category = this[this.$route.query.type];
      if (this.$route.query.type === "algorithm-tower") {
        this.columns = [
          {
            title: "Num",
            align: "center",
            width: 150,
            render: (h, params) => {
              return h("span", params.index + 1);
            }
          },
          {
            title: "Title",
            align: "center",
            width: 800,
            render: (h, params) => {
              return h(
                "span",
                {
                  style: {
                    "padding-left": "15px"
                  }
                },
                params.row.title
              );
            }
          }
        ];
      } else {
        this.columns = [
          {
            title: "Num",
            align: "center",
            render: (h, params) => {
              return h(
                "span",
                params.index + 1
              );
            }
          },
          {
            title: "Level",
            align: "center",
            minWidth: 100,
            render: (h, params) => {
              return h(
                "span",
                {
                  style: {
                    "padding-left": "15px"
                  }
                },
                params.row.title
              );
            }
          },
          {
            title: "내용",
            align: "center",
            minWidth: 200,
            render: (h, params) => {
              return h(
                "span",
                {
                  style: {
                    "text-align": "left !important"
                  }
                },
                params.row.content
              );
            }
          }
        ];
      }
    },
    move(row, index) {
      this.CHANGE_PROBLEM_TITLE(row.title);
      this.$router.push(`/problem?tag=${row.level}&page=1`);
    }
  }
};
</script>

<style>
.ivu-table-header th {
  text-align: center !important;
}
</style>
