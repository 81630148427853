import ToastViewer from "./ToastViewer.vue"
  
const mixins = {
  components: {
    ToastViewer
  },

  data() {
    return {
      content:""
    }


  },
  
    // methods:{

    //     // onViewerLoad(editor) {
    //     //   editor.setMarkdown("another value", true)
    //     // } // addYoutubeIframe(v.preview.el, this.$vuetify.breakpoint)

    // }


}


export default mixins;