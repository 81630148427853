import moment from "moment";
import types from "../types";
import api from "@oj/api";
import { CONTEST_STATUS, USER_TYPE, CONTEST_TYPE } from "@/utils/constants";

const state = {
  e_learning: []
};

const getters = {};

const mutations = {
  [types.POST_ELEARNING_COMMENT](state, payload) {
    state.e_learning = payload;
  }
};

const actions = {
  async getELearning({ commit }, payload) {
    const response = await api.getELearningDetail(payload);
    const result = Object.entries(response).find(el => el[0] === "data");

    commit(types.POST_ELEARNING_COMMENT, result[1]["data"]);
  }
};

export default {
  state,
  mutations,
  getters,
  actions
};
