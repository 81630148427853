import ProblemList from './problem/ProblemList.vue'
import Logout from './user/Logout.vue'
import UserHome from './user/UserHome.vue'
import About from './help/About.vue'
import FAQ from './help/FAQ.vue'
import Java from './help/Java.vue'
import NotFound from './general/404.vue'
import Home from './general/Home.vue'
import Announcements from './general/Announcements.vue'
import Category from './problem/Category.vue'

// Grouping Components in the Same Chunk
const SubmissionList = () => import(/* webpackChunkName: "submission" */ '@oj/views/submission/SubmissionList.vue')
const SubmissionDetails = () => import(/* webpackChunkName: "submission" */ '@oj/views/submission/SubmissionDetails.vue')

const ACMRank = () => import(/* webpackChunkName: "userRank" */ '@oj/views/rank/ACMRank.vue')
const OIRank = () => import(/* webpackChunkName: "userRank" */ '@oj/views/rank/OIRank.vue')

const ApplyResetPassword = () => import(/* webpackChunkName: "password" */ '@oj/views/user/ApplyResetPassword.vue')
const ResetPassword = () => import(/* webpackChunkName: "password" */ '@oj/views/user/ResetPassword.vue')

const Problem = () => import(/* webpackChunkName: "Problem" */ '@oj/views/problem/EnterpriseProblem.vue')
// const Problem = () => import(/* webpackChunkName: "Problem" */ '@oj/views/problem/Problem.vue')
const EnterpriseProblem = () => import(/* webpackChunkName: "Problem" */ '@oj/views/problem/EnterpriseProblem.vue')

export {
  Home, NotFound, Announcements,
  Logout, UserHome, About, FAQ, Java,
  ProblemList, Problem,EnterpriseProblem,
  ACMRank, OIRank,
  SubmissionList, SubmissionDetails,
  ApplyResetPassword, ResetPassword,
  Category
}
/* 구성 요소 내보내기는 두 가지 범주로 나뉩니다. 하나는 일반적으로 직접 내보내기에 사용되며 다른 하나는 로그인, 로그 아웃 등과 같은 지연로드이며 여기에서는 지연로드가 내보내지지 않습니다.
 *   해당 경로에로드
 *   https://router.vuejs.org/en/advanced/lazy-loading.html 참조
 */
