import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import storage from "@/utils/storage";
import { STORAGE_KEY } from "@/utils/constants";
import { sync } from "vuex-router-sync";
import { types, default as store } from "../../../store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes
});

// 글로벌 신원 확인
router.beforeEach((to, from, next) => {
  Vue.prototype.$Loading.start();

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!storage.get(STORAGE_KEY.AUTHED)) {
      Vue.prototype.$error("로그인부터 해주세요");
      store.commit(types.CHANGE_MODAL_STATUS, { mode: "login", visible: true });
      next({
        name: "home"
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to, from, next) => {
  Vue.prototype.$Loading.finish();
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== "NavigationDuplicated") throw err;
  });
};

sync(store, router);
export default router;
