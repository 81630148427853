import moment from "moment";
import types from "../types";
import api from "@oj/api";


const state = {
  problemStepLevelDetailDialogVisible: false,
  problemStepLevelDetailProblem :[],
  title: "",
};

const getters = {};

const mutations = {
  [types.CHANGE_PROBLEM_STEP_LEVEL_DETAIL_DIALOG](state, payload) {
    state.problemStepLevelDetailDialogVisible = payload;
  },
  [types.CHANGE_PROBLEM_STEP_LEVEL_DETAIL_PROBLEM](state, payload) {
    state.problemStepLevelDetailProblem = payload;
  },
  [types.CHANGE_TITLE](state,payload) {
    state.title = payload;
  }
};

const actions = {
//   async getBoard({ commit }, payload) {
//     const response = await api.getBoardDetail(payload);
//     const result = Object.entries(response).find(el => el[0] === "data");

//     commit(types.POST_COMMENT, result[1]["data"]);
//   }
};

export default {
  state,
  mutations,
  getters,
  actions
};
