<template>
  <div>
    <AdminPanel title="태그">
      <div
        slot="header"
        style="display: flex"
        :style="$store.state.isMobile ? 'margin-top:60px;' : ''"
      >
        <el-input
          :size="$store.state.isMobile ? 'mini' : ''"
          v-model="keyword"
          prefix-icon="el-icon-search"
          placeholder="태그 이름을 입력하세요"
        >
        </el-input>
      </div>

      <el-table
        v-loading="loading"
        :data="tagList"
        element-loading-text="loading"
        ref="table"
        style="width: 100%"
      >
      <el-table-column min-width="20" label="id" prop="id" >
          
        </el-table-column>

        <el-table-column min-width="100" label="제목">
          <template slot-scope="scope">
            <el-tag style="cursor: pointer;" type="primary" @click.native="scope.row.problem_count ?moveProblemList(scope.row) : null">
              {{ scope.row.name }} ({{ scope.row.problem_count }})
            </el-tag>
          </template>
          problem_count
        </el-table-column>
        <el-table-column label="옵션" width="180">
          <template slot-scope="scope">
            <!-- button1 수정 -->
            <icon-btn
              name="수정"
              icon="edit"
              @click.native="handleInlineEdit(scope.row)"
            ></icon-btn>

            <icon-btn
              name="삭제"
              icon="delete"
              @click.native="deleteTag(scope.row)"
            ></icon-btn>

            <icon-btn v-if="scope.row.problem_count" name="해당 태그 문제 검색" icon="search"  @click.native="moveProblemList(scope.row)"></icon-btn>
            <icon-btn v-else  name="해당 태그 문제 검색" icon="search" :disabled=true></icon-btn>

            <!-- button2 관련 문제 조회 -->

            <!-- button3 -->
            <!-- button4 -->
          </template>
        </el-table-column>
      </el-table>

      <div class="panel-options">
        <el-pagination
          class="page"
          layout="prev, pager, next"
          @current-change="currentChange"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :total="total"
        >
        </el-pagination>
        <!-- 버튼 하나 추가하기  -->
        <el-button :size="$store.state.isMobile ?'mini':'medium'" type="primary" 
        @click.native="handleInlineCreate()"
        class="write-btn"
          >태그 추가하기</el-button
        >
      </div>

    </AdminPanel>

    <el-dialog
      :title="tagQuestion"
      width="30%"
      :visible.sync="InlineEditDialogVisible"
      @close-on-click-modal="false"
    >
      <div>
        <p>
          name:
          <el-input
            v-model="changeName"
            
          ></el-input>
        </p>
      </div>
      <span slot="footer">
        <cancel
          @click.native="
            InlineEditDialogVisible = false;
            getTagList(currentPage);
          "
        ></cancel>
        <save v-if="type === 'edit' " @click.native="updateTag(currentRow, $event)"></save>
        <save v-else @click.native="createTag()"></save>
      </span>
    </el-dialog>


  </div>
</template>

<script>
import api from "../../api";
import AdminPanel from "@admin/components/AdminPanel.vue";
import AddTag from "./AddTag.vue"

/**
 * ADD TAG
 * 기능 2가지
 * 일반 태그 중복 생성
 * 
 * 문제 만들기에서 해당 ADD Tag를 만들경우 -> 태그 리스트들을 전달 받는다.
 * emit으로하자
 * 해당 리스트들은 나열가능 
 * 
 * 일반 태그만들기에서는 태그를 만들고 emit으로 전달시 다시 요청 가능 
 */


export default {
  components: {
    AdminPanel,
    AddTag
  },
  data() {
    return {
      addProblemDialogVisible:false,
      loading: true,
      tagList: [],
      keyword: "",
      pageSize: 10,
      currentPage: this.$route.query.page ? +this.$route.query.page : 1,
      total: 0,
      InlineEditDialogVisible: false,
      currentRow: {},
      changeName: "",
      addTagModal:false,
      tagQuestion:"",
      type:""
    };
  },

  created() {
    this.getTagList();
  },
  methods: {
    page() {
      if (this.$route.query.page) {
        this.currentPage = +this.$route.query.page;
      } else {
        this.currentPage = 1;
      }
      this.getTagList(this.currentPage);
    },

    getTagList(page = 1) {
      let params = {
        offset: (page - 1) * this.pageSize,
        limit: this.pageSize,
        keyword: this.keyword,
      };

      api.getTagList(params).then((li) => {
        this.loading = false;
        // console.log(li);
        this.tagList = li.data.data.results;
        this.total = li.data.data.total;
      });
    },
    createTag(){
      if(!this.changeName){
      this.InlineEditDialogVisible = false;
      return;

      }

      const data = {
        name: this.changeName.trim()
      }
      
      const pattern = /\s/;
      if(pattern.test(data.name)){
        
        this.$error("태그에 공백을 넣을수 없습니다.");
        return;
      }

      api.createTag(data).then(li => {
        this.getTagList(this.currentPage);
        this.InlineEditDialogVisible = false;
      })

    },

    updateTag(row, event) {
 
    if(row.name === this.changeName){
      this.InlineEditDialogVisible = false;
      return;
    }

      const data = {
        name: this.changeName,
        tag_id: row.id,
      };

      api.updateTag(data).then((li) => {
        this.getTagList(this.currentPage);
        this.InlineEditDialogVisible = false;
      });

      
    },
    deleteTag(row) {
      const result = confirm("해당 테그를 삭제하시겠습니까?");
      
      if (result) {
        const params = {
          tag_id: row.id,
        };

        api.deleteTag(params).then((li) => {
          this.getTagList(this.currentPage);
          this.InlineEditDialogVisible = false;
        });
      }
    },
    currentChange(page) {
      this.currentPage = page;
      this.getTagList(page);
      this.$router.push({
        path: `tag`,
        query: { page: this.currentPage, keyword: this.keyword },
      });
    },
    handleInlineEdit(row) {
      this.currentRow = row;
      this.changeName = row.name;
      this.InlineEditDialogVisible = true;
      this.tagQuestion = "태그 수정";
      this.type = "edit";
    },
    handleInlineCreate(){
      this.type = "create";
      this.changeName = "";
      this.tagQuestion = "태그 작성";
      this.InlineEditDialogVisible = true;
      
    },

    moveProblemList(row){
      
      this.$router.push({
        path: '/admin/problems',
        query: {
          tag: row.name
        }
      })

    }
  },

  watch: {
    keyword() {
      this.currentChange(1);
    },
    "$route.query": function () {
      this.page();
    },
  },
};
</script>

<style>
</style>